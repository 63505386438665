import CustomModal from '@components/Modal'
import { Box, Divider, TextField, Typography, useTheme, AlertColor, Grid, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Formik, Form as FormikForm } from 'formik';
import FormikInput from '@ui/formik/Input';
import CustomButton from '@ui/Button';
import { useTranslation } from 'react-i18next';
import { addAntiPlagiarismAttemptsValidationSchema } from './validation';
import { serviceIntegration } from '@services/integrations';
import { useParams } from 'react-router-dom';
import Message from '@components/Message';
import { IntagrationCode } from '@interfaces/index';
import { serviceAntiPlagiarism } from '@services/antiPlagiarism';
import { useQuery } from '@tanstack/react-query';
import { formatDateWithHourMinute } from '@utils/functions';

interface Props {
  openModal: boolean;
  handleToggleModal: () => void;
  integrationsInfo: any
}


interface IMessage {
  open: boolean;
  text: string;
  type: AlertColor
}


export const DistributionAntiPlagiarismAttempts = ({ openModal, handleToggleModal, integrationsInfo }: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(['common', 'antiPlagiarism']);
  const { id } = useParams();
  const [attempts, setAttempts] = useState<number>(943)
  const [numberDistribution, setNumberDistribution] = useState<string>("ADD")
  const [message, setMessage] = useState<IMessage>({ open: false, text: '', type: 'success' })
  const antiPlagiarism = integrationsInfo.filter((node) => node.integration ? node.integration.code === IntagrationCode.ANTI_PLAGIARISM : node.code === IntagrationCode.ANTI_PLAGIARISM)[0]
  const { data: infoAntiPlagiarism } =
    useQuery({
      queryKey: ['infoAntiPlagiarism'],
      queryFn: () => serviceIntegration.getInfoAntiPlagiarism(Number(id)),
    });
  return (
    <CustomModal
      open={openModal}
      handleToggleModal={handleToggleModal}
      borderColor={common.primaryColor}
      width={515}
      showCloseIcon
    >
      <Formik
        initialValues={{ attempts: 0 }}
        enableReinitialize
        validationSchema={addAntiPlagiarismAttemptsValidationSchema(t, 943)}
        onSubmit={(values) => {
          const checkAttemptCount = numberDistribution === "ADD" ? Number(infoAntiPlagiarism?.data.checksCount) + Number(values.attempts) : Number(infoAntiPlagiarism?.data.checksCount) - Number(values.attempts)
          const selectedIntegration = [{
            isActive: false,
            orgId: Number(id),
            integration: antiPlagiarism,
            checkAttemptCount: checkAttemptCount
          }]
          serviceAntiPlagiarism.savePurchaseOrg({ orgId: Number(id), checkAttemptCount: Number(checkAttemptCount) }).then((res) => {
            if (res.status === 200) {
              serviceIntegration.updateIntegrationByOrgId(selectedIntegration).then((res) => {
                if (res.status === 200) {
                  setMessage({ open: true, text: t("enum:SERVICE_ADDED"), type: 'success' })
                  setTimeout(() => {
                    setMessage({ ...message, open: false })
                    handleToggleModal()
                  }, 1000)
                }
              })
            }
          })
            .catch((e) => {
              setMessage({ open: true, text: t("common:messages.ERROR"), type: 'error' })
              setTimeout(() => {
                setMessage({ ...message, open: false })
              }, 3000);
            })
        }}>
        {({ isValid, submitForm, dirty }) => (
          <FormikForm>
            <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>{t("antiPlagiarism:DISTRIBUTION_ANTI_PLAGIARISM")}</Typography>
            <Divider sx={{ margin: "16px 0px" }} />
            {
              message.open &&
              <Box sx={{ mb: 2 }}>
                <Message type={message.type} message={message.text} setMessageOpen={message.open} />
              </Box>
            }
            <Grid container gap={1} mb={1}>
              <Grid item xs={3.9} display="block" p="12px 16px" sx={{ background: "#F9FAFB", border: `1px solid ${common.borderSecondary}`, borderRadius: "4px" }}>
                <Typography sx={{ color: "#6D737A", fontSize: "16px" }}>{t("antiPlagiarism:ACQUIRED")}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{infoAntiPlagiarism?.data.checksCount || 0}</Typography>
              </Grid>
              <Grid item xs={3.8} display="block" p="12px 16px" sx={{ background: "#F9FAFB", border: `1px solid ${common.borderSecondary}`, borderRadius: "4px" }}>
                <Typography sx={{ color: "#6D737A", fontSize: "16px" }}>{t("antiPlagiarism:SPENT")}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{(infoAntiPlagiarism?.data.checksCount - infoAntiPlagiarism?.data.remainedChecksCount) || 0}</Typography>
              </Grid>
              <Grid item xs={3.8} display="block" p="12px 16px" sx={{ background: "#F9FAFB", border: `1px solid ${common.borderSecondary}`, borderRadius: "4px" }}>
                <Typography sx={{ color: "#6D737A", fontSize: "16px" }}>{t("antiPlagiarism:LEFT")}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{infoAntiPlagiarism?.data.remainedChecksCount || 0}</Typography>
              </Grid>
            </Grid>
            <Grid container gap={1}>
              <Grid item xs={5.9} display="block" p="12px 16px" sx={{ background: "#F9FAFB", border: `1px solid ${common.borderSecondary}`, borderRadius: "4px" }}>
                <Typography fontSize="14px" sx={{ color: "#6D737A" }}>{t("antiPlagiarism:AVAILABLE")}</Typography>
                <Typography fontWeight={500} fontSize="16px">943</Typography>
              </Grid>
              <Grid item xs={5.8} display="block" p="12px 16px" sx={{ background: "#F9FAFB", border: `1px solid ${common.borderSecondary}`, borderRadius: "4px" }}>
                <Typography fontSize="14px" sx={{ color: "#6D737A" }}>{t("antiPlagiarism:VALIDITY_PERIOD")}</Typography>
                <Typography fontSize="16px" fontWeight={500}>{formatDateWithHourMinute(infoAntiPlagiarism?.data.expiryDate)}</Typography>
              </Grid>
            </Grid>
            <Box display="flex" gap={1} mt={2.5}>
              <FormikInput name="attempts" placeholder={t("antiPlagiarism:ENTER_NUMBER_ATTEMPTS")} type="number" min={0} />
              <CustomButton
                width="146px"
                height="40px"
                borderRadius="8px"
                backgroundColor={common.primaryColor}
                fontColor="#FFFFFF"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                disabled={!(isValid && dirty)}
                onClick={submitForm}
              >
                {t(`common:actions.${numberDistribution}`)}
              </CustomButton>
            </Box>
            <RadioGroup defaultValue={numberDistribution} row onChange={(value) => setNumberDistribution(value.target.value)}>
              <FormControlLabel value="ADD" control={<Radio />} label={t("common:actions.ADD")} />
              <FormControlLabel value="REDUCE" control={<Radio />} label={t("common:actions.REDUCE")} />
            </RadioGroup>
          </FormikForm>
        )}
      </Formik>
    </CustomModal>
  )
}