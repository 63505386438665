import { MouseEvent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form as FormikForm, Formik } from "formik";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Event } from "react-big-calendar";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { eventCalendarControllerApi } from "@services/index";
import { serviceCourse } from "@services/course";
import { StateSchema } from "@store/StateSchema";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { CustomCalendar } from "@components/common/Calendar";
import { EventType, IEventCalendar } from "@interfaces/eventCalendar";
import CustomInput from "@ui/CustomInput";
import CustomButton from "@ui/Button";
import CustomDateTimePicker from "@ui/CustomDateTimePicker";
import FormikCheckbox from "@ui/formik/Checkbox";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import { eventInitialDto } from "./models";
import { validationSchema } from "./validation";
import { Role } from "@interfaces/index";
import { format } from "date-fns";

const EventCalendar = () => {
  // const location = useLocation();
  const { t } = useTranslation(['common', 'enum']);
  const { pltaId, authorityInfo: { role } } = useSelector((store: StateSchema) => store.account.profileInfo);
  const { common } = useTheme().palette;
  const [initialData, setInitialData] = useState<IEventCalendar>(eventInitialDto);
  const [currentRange, setCurrentRange] = useState({
    startDate: "",
    endDate: ""
  });

  const { data: eventCalendarList } = useQuery({
    queryKey: ["eventCalendarList", currentRange],
    queryFn: () =>
      eventCalendarControllerApi.getEventCalendarList({
        pageNumber: 0,
        size: 100,
        filter: {
          startDate: new Date(currentRange.startDate) as unknown as string,
          endDate: new Date(currentRange.endDate) as unknown as string,
        },
      }),
    select: (data) => {
      let eventArray = [] as Event[];
      data.dtoList.forEach((eventCalendar: IEventCalendar) => {
        const { title, startDate, endDate, id, type, allDay, description, userId, authorId, courseId } = eventCalendar;
        let data = {
          id,
          title,
          start: new Date(startDate || ""),
          end: new Date(endDate || ""),
          allDay,
          type,
          description,
          userId,
          authorId,
          courseId
        };
        eventArray.push(data);
      });
      return eventArray;
    }
  });

  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (values: IEventCalendar) =>
      eventCalendarControllerApi.createEventCalendar(values),
    onSuccess: (data) => {
      console.log("Event created:", data);
      handleClose();
      // Invalidate the query to refetch the updated list of events
      queryClient.invalidateQueries({ queryKey: ["eventCalendarList"] });
    },
    onError: (error) => {
      console.error("Error creating event:", error);
    }
  });

  const updateMutation = useMutation({
    mutationFn: (values: IEventCalendar) =>
      eventCalendarControllerApi.updateEventCalendar(values),
    onSuccess: (data) => {
      console.log("Event updated:", data);
      handleClose();
      // Invalidate the query to refetch the updated list of events
      queryClient.invalidateQueries({ queryKey: ["eventCalendarList"] });
    },
    onError: (error) => {
      console.error("Error updatting event:", error);
    }
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [courseListOptions, setCourseListOptions] = useState<{ id: string, name: string }[]>([]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setInitialData(role === Role.EDUCATOR ? eventInitialDto : { ...eventInitialDto, type: EventType.PRIVATE });
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (anchorEl && initialData.type === EventType.GENERAL) {
      serviceCourse.getCourseListForDropdown({}).then((res) => {
        if (res.status === 200) {
          setCourseListOptions(res.data);
        }
      });
    }
  }, [anchorEl, initialData.type]);

  useEffect(() => {
    if (role === Role.LISTENER) {
      setInitialData(prev => ({ ...prev, type: EventType.PRIVATE }));
    }
  }, [role]);

  const deleteEvent = async () => {
    const res = await eventCalendarControllerApi.deleteEventCalendar(initialData.id || "");
    if (res.type === "SUCCESS") {
      handleClose();
      queryClient.invalidateQueries({ queryKey: ["eventCalendarList"] });
    }
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteEvent,
    modalTitle: t(`common:DELETE_EVENT_CONFIRMATION_TEXT`),
  })

  const handleSelectEvent = (event: any, target: any) => {
    const selectedEvent: any = eventCalendarList?.find((eventCalendar: any) => eventCalendar.id === event.id);
    if (selectedEvent) {
      const { id, title, start, end, type, description, allDay, userId, authorId, courseId } = selectedEvent;
      setInitialData({ ...initialData, id, title, startDate: start, endDate: end, type, allDay, description, userId, authorId, courseId });
    }
    handleClick(target);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: { sm: "36px", xs: "28px" },
            height: "50px",
            alignItems: "center",
          }}
        >
          Календарь событий
        </Typography>
        <CustomButton
          backgroundColor={common.primaryColor}
          fontColor={common.fontWhite}
          borderRadius="6px"
          width="fit-content"
          onClick={handleClick}
        >
          Создать событие
        </CustomButton>
      </Box>
      <CustomCalendar list={eventCalendarList} setCurrentRange={setCurrentRange} selecEvent={handleSelectEvent} />
      <Popover
        id="event-calendar-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableScrollLock
        PaperProps={{ sx: { borderRadius: "12px" } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "450px"
          }}
        >
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema(t)}
            onSubmit={(values: IEventCalendar) => {
              // console.log("Form Submitted:", values);
              if (!values.id) createMutation.mutate({ ...values, userId: pltaId, authorId: pltaId, courseId: values.type === EventType.PRIVATE ? null : values.courseId });
              else updateMutation.mutate({ ...values, courseId: values.type === EventType.PRIVATE ? null : values.courseId });
            }}
          >
            {({ values, submitForm, isValid, dirty, setFieldValue }) => {
              return (
                <FormikForm
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      m: 2
                    }}
                  >
                    <CustomInput
                      name="title"
                      type="text"
                      variant="standard"
                      placeholder="Название событии"
                      disabled={Boolean(values.id) && pltaId !== values.authorId}
                    />
                    <CloseIcon
                      sx={{
                        cursor: "pointer",
                        color: common.strokePrimary
                      }}
                      onClick={handleClose}
                    />
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      m: 2
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center"
                      }}
                    >
                      <PlaceOutlinedIcon sx={{ color: '#6D737A' }} />
                      {((values.id && (values.type === EventType.GENERAL || (values.type === EventType.PRIVATE && role === Role.EDUCATOR))) || (!values.id && role === Role.EDUCATOR)) &&
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            width: "fit-content",
                            border: `1px solid ${values.type === EventType.GENERAL ? common.primaryColor : '#E8E8E8'}`,
                            borderRadius: 2,
                            p: 1,
                            cursor: Boolean(values.id) && pltaId !== values.authorId ? "default" : "pointer",
                            "&:hover": Boolean(values.id) && pltaId !== values.authorId ?
                              undefined : {
                                borderColor: common.primaryColor
                              }
                          }}
                          onClick={Boolean(values.id) && pltaId !== values.authorId ? undefined : () => setFieldValue("type", EventType.GENERAL)}
                        >
                          <GroupsOutlinedIcon />
                          <Typography sx={{ fontWeight: 500 }}>{t("enum:GENERAL")}</Typography>
                        </Box>
                      }
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          width: "fit-content",
                          border: `1px solid ${values.type === EventType.PRIVATE ? common.primaryColor : '#E8E8E8'}`,
                          borderRadius: 2,
                          p: 1,
                          cursor: Boolean(values.id) && pltaId !== values.authorId ? "default" : "pointer",
                          "&:hover": Boolean(values.id) && pltaId !== values.authorId ?
                            undefined : {
                              borderColor: common.primaryColor
                            }
                        }}
                        onClick={Boolean(values.id) && pltaId !== values.authorId ? undefined : () => setFieldValue("type", EventType.PRIVATE)}
                      >
                        <AccountCircleOutlinedIcon />
                        <Typography sx={{ fontWeight: 500 }}>{t("enum:PRIVATE")}</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center"
                      }}
                    >
                      <CalendarMonthOutlinedIcon sx={{ color: '#6D737A' }} />
                      <CustomDateTimePicker name="startDate" disabled={(Boolean(values.id) && pltaId !== values.authorId) || values.allDay} />
                      <HorizontalRuleOutlinedIcon sx={{ color: '#D9D9D9' }} />
                      <CustomDateTimePicker name="endDate" disabled={(Boolean(values.id) && pltaId !== values.authorId) || values.allDay} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        pl: 5
                      }}
                    >
                      <FormikCheckbox
                        name="allDay"
                        label="Весь день"
                        labelPlacement="end"
                        disabled={Boolean(values.id) && pltaId !== values.authorId}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setFieldValue("startDate", format(values.startDate ? new Date(values.startDate) : new Date(), "yyyy-MM-dd'T'00:00"))
                            setFieldValue("endDate", format(values.startDate ? new Date(values.startDate) : new Date(), "yyyy-MM-dd'T'23:59"))
                          }
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center"
                      }}
                    >
                      <SortOutlinedIcon sx={{ color: '#6D737A' }} />
                      <CustomInput name="description" variant="standard" placeholder="Добавить описание" disabled={Boolean(values.id) && pltaId !== values.authorId} />
                    </Box>
                    {values.type === EventType.GENERAL &&
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "center"
                        }}
                      >
                        <SchoolOutlinedIcon sx={{ color: '#6D737A' }} />
                        <FormikAutocomplete name="courseId" options={courseListOptions} disabled={Boolean(values.id) && pltaId !== values.authorId} />
                      </Box>
                    }
                  </Box>
                  {(!values.id || (values.id && pltaId === values.authorId)) &&
                    <>
                      <Divider />
                      <Box
                        sx={{
                          display: "flex",
                          m: 2,
                          alignItems: "center"
                        }}
                      >
                        {values.id &&
                          <DeleteOutlinedIcon
                            sx={{ color: common.errorColor, cursor: "pointer" }}
                            onClick={() => values.id ? openDeleteConfirmModal(values.id) : undefined}
                          />
                        }
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end"
                          }}
                        >
                          <CustomButton
                            backgroundColor={common.primaryColor}
                            fontColor={common.fontWhite}
                            borderRadius="4px"
                            width='fit-content'
                            height="40px"
                            onClick={submitForm}
                            disabled={!(isValid && dirty)}
                          >
                            {t('actions.SAVE')}
                          </CustomButton>
                        </Box>
                      </Box>
                    </>
                  }
                </FormikForm>
              )
            }}
          </Formik>
        </Box>
      </Popover >
      {DeleteModal}
    </Box >
  )
}

export default EventCalendar;