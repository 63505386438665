import React, { useContext } from 'react'
import { ContentType, DurationType, Role } from '@interfaces/index';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import { SectionEstimatedTime } from '.';
import { format, isBefore } from "date-fns";
import { CourseStatusEnum } from '@models/course';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CourseContext } from '@context/CourseContext';

const formatTime = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > 0) {
    if (minutes > 0) {
      return `${hours} ч ${minutes} мин`;
    } else {
      return `${hours} ч`;
    }
  } else {
    return `${minutes} мин`;
  }
};
export const StudyPlanList = ({ contentList }) => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const { classId } = useParams();
  const { t } = useTranslation(["enum", "course", "common"]);
  const location = useLocation();
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const { courseData } = useContext(CourseContext);
  const isCourseIndefinite = courseData?.durationType === DurationType.INDEFINITE;
  return (
    <>
      {contentList.map((content, index) => {
        return (
          <Accordion
            key={index}
            defaultExpanded={true}
            disableGutters={true}
            sx={{
              backgroundColor: common.secondaryColor,
              border: "1px solid #CACACA",
              borderRadius: 1,
              mt: 3,
            }}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {content.type === ContentType.SECTION && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: "16px", sm: "20px" },
                          lineHeight: "18px",
                        }}
                      >
                        {t("course:section.SECTION")} {" "}
                        {content.name}
                      </Typography>
                      <ExpandMoreIcon />
                    </Box>
                    {isCourseIndefinite && (
                      <SectionEstimatedTime sectionId={content.id} />
                    )}
                  </Box>
                )}
                {content.type === ContentType.TASK ? (
                  content.dateOfAccess &&
                    isBefore(new Date(), new Date(content.dateOfAccess)) ? (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        display="flex"
                        marginLeft="10px"
                        alignItems="center"
                        gap={1}
                      >
                        <NoteAltOutlinedIcon />
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "20px",
                            lineHeight: "18px",
                          }}
                        >
                          {content.name}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        sx={{
                          borderRadius: "10px",
                          background: "#C1C1C1",
                          width: "200px",
                        }}
                        padding="3px 5px"
                        gap={1}
                      >
                        <LockOutlinedIcon />
                        <Typography>
                          {format(
                            new Date(content.dateOfAccess),
                            "dd.MM.yyyy HH:mm"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        width: "100%",
                        ":hover": {
                          color: common.primaryColor,
                          cursor: "pointer",
                        },
                      }}
                      onClick={() =>
                        // the theacher doesn`t have studyClassId
                        role === Role.LISTENER && navigate(
                          `/myCourses/current/${classId}/tasks/${content.id}`
                        )
                      }
                    >
                      <NoteAltOutlinedIcon />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "20px",
                          lineHeight: "18px",
                        }}
                      >
                        {content.name}
                      </Typography>
                    </Box>
                  )
                ) : null}

                {content.type === ContentType.THEME && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box display="flex" gap={1}>
                      <DescriptionOutlinedIcon />
                      <Typography
                        onClick={() => {
                          // the theacher doesn`t have studyClassId
                          if (location.pathname.includes("courseCatalogue")) {
                            role === Role.LISTENER && navigate(
                              `/courseCatalogue/${classId}/theme/${content.id}`
                            );
                          } else {
                            role === Role.LISTENER && navigate(
                              `/myCourses/${classId}/theme/${content.id}`
                            );
                          }
                        }}
                        sx={{
                          fontWeight: 600,
                          fontSize: "20px",
                          lineHeight: "18px",
                        }}
                      >
                        {content.name}
                      </Typography>
                      <ExpandMoreIcon />
                    </Box>
                    {isCourseIndefinite && (
                      <Typography>
                        {formatTime(content.estimatedTime)}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </AccordionSummary>

            {(content.type === ContentType.SECTION ||
              content.type === ContentType.THEME) && (
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column", p: 0 }}
                >
                  {content?.shortDescription && (
                    <Box
                      sx={{
                        pl: 2,
                        pr: 2,
                        py: 1,
                        borderTop: `1px solid #CACACA`,
                        backgroundColor: common.fontWhite,
                        fontSize: "14px",
                      }}
                    >
                      <Typography color={common.primaryColor}>
                        {t("course:section.SHORT_DESCRIPTION")}:
                      </Typography>
                      <Typography>{content.shortDescription}</Typography>
                    </Box>
                  )}
                  {content?.children
                    .filter((node) => node.status === CourseStatusEnum.PUBLISHED)
                    .map((child, index) =>
                      child.children.length > 0 ? (
                        <Accordion
                          key={index}
                          defaultExpanded={true}
                          disableGutters={true}
                          sx={{
                            borderRadius: 1,
                          }}
                          TransitionProps={{ unmountOnExit: true }}
                        >
                          <AccordionSummary
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              display="flex"
                              width="100%"
                              gap={2}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box display="flex" gap={1}>
                                <DescriptionOutlinedIcon />
                                <Typography
                                  sx={{
                                    ":hover": {
                                      color:
                                        child.dateOfAccess &&
                                          isBefore(
                                            new Date(),
                                            new Date(child.dateOfAccess)
                                          )
                                          ? null
                                          : common.primaryColor,
                                    },
                                  }}
                                  onClick={() => {
                                    if (
                                      child.dateOfAccess &&
                                      isBefore(
                                        new Date(),
                                        new Date(child.dateOfAccess)
                                      )
                                    ) {
                                      return null;
                                    } else {
                                      // the theacher doesn`t have studyClassId
                                      if (
                                        location.pathname.includes(
                                          "courseCatalogue"
                                        )
                                      ) {
                                        role === Role.LISTENER && navigate(
                                          `/courseCatalogue/${classId}/theme/${child.id}`
                                        );
                                      } else {
                                        role === Role.LISTENER && navigate(
                                          `/myCourses/${classId}/theme/${child.id}`
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {child.name}
                                </Typography>
                                <ExpandMoreIcon />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                  gap: 1,
                                }}
                              >
                                {isCourseIndefinite && (
                                  <Box
                                    sx={{
                                      maxWidth: "110px",
                                      borderRadius: "120px",
                                      backgroundColor: "rgba(228, 212, 255, 1)",
                                      p: "2px 12px",
                                      color: "rgba(148, 105, 227, 1)",
                                    }}
                                  >
                                    {formatTime(child.estimatedTime)}
                                  </Box>
                                )}
                                {child.dateOfAccess &&
                                  isBefore(
                                    new Date(),
                                    new Date(child.dateOfAccess)
                                  ) && (
                                    <Box
                                      display="flex"
                                      sx={{
                                        borderRadius: "10px",
                                        background: "#C1C1C1",
                                        width: "200px",
                                      }}
                                      padding="3px 5px"
                                      gap={1}
                                    >
                                      <LockOutlinedIcon />
                                      <Typography>
                                        {format(
                                          new Date(child.dateOfAccess),
                                          "dd.MM.yyyy HH:mm"
                                        )}
                                      </Typography>
                                    </Box>
                                  )}
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            {isBefore(new Date(), new Date(child.dateOfAccess))
                              ? child.children
                                .filter((node) => node.status === "PUBLISHED")
                                .map((node, index) => (
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    key={index}
                                  >
                                    <Box
                                      display="flex"
                                      gap={2}
                                      marginLeft="10px"
                                      marginTop="10px"
                                    >
                                      <NoteAltOutlinedIcon />
                                      <Typography>{node.name}</Typography>
                                    </Box>
                                    {isBefore(
                                      new Date(),
                                      new Date(node.dateOfAccess)
                                    ) && (
                                        <Box
                                          display="flex"
                                          sx={{
                                            borderRadius: "10px",
                                            background: "#C1C1C1",
                                            width: "200px",
                                          }}
                                          padding="3px 5px"
                                          gap={1}
                                        >
                                          <LockOutlinedIcon />
                                          <Typography>
                                            {format(
                                              new Date(node.dateOfAccess),
                                              "dd.MM.yyyy HH:mm"
                                            )}
                                          </Typography>
                                        </Box>
                                      )}
                                  </Box>
                                ))
                              : child.children
                                .filter((node) => node.status === "PUBLISHED")
                                .map((node, index) =>
                                  node.dateOfAccess &&
                                    isBefore(
                                      new Date(),
                                      new Date(node.dateOfAccess)
                                    ) ? (
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      key={index}
                                    >
                                      <Box
                                        display="flex"
                                        gap={2}
                                        marginLeft="10px"
                                        marginTop="10px"
                                      >
                                        <NoteAltOutlinedIcon />
                                        <Typography>{node.name}</Typography>
                                      </Box>
                                      <Box
                                        display="flex"
                                        sx={{
                                          borderRadius: "10px",
                                          background: "#C1C1C1",
                                          width: "200px",
                                        }}
                                        padding="3px 5px"
                                        gap={1}
                                      >
                                        <LockOutlinedIcon />
                                        <Typography>
                                          {format(
                                            new Date(node.dateOfAccess),
                                            "dd.MM.yyyy HH:mm"
                                          )}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box
                                      display="flex"
                                      gap={2}
                                      marginLeft="10px"
                                      marginTop="10px"
                                      key={index}
                                      justifyContent="space-between"
                                    >
                                      <Box display="flex" gap={1}>
                                        <NoteAltOutlinedIcon />
                                        <Typography
                                          sx={{
                                            ":hover": {
                                              color: common.primaryColor,
                                              cursor: "pointer",
                                            },
                                          }}
                                          onClick={() =>
                                            // the theacher doesn`t have studyClassId
                                            role === Role.LISTENER && navigate(
                                              `/myCourses/current/${classId}/tasks/${node.id}`
                                            )
                                          }
                                        >
                                          {node.name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )
                                )}
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            pl: 2,
                            pr: 2,
                            py: 1,
                            borderTop: `1px solid #CACACA`,
                            backgroundColor: common.fontWhite,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {child.type === ContentType.THEME && (
                            <>
                              {!child.dateOfAccess ? (
                                <Box
                                  display="flex"
                                  gap={2}
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <Box sx={{ display: "flex", gap: 1 }}>
                                    <DescriptionOutlinedIcon />
                                    <Typography
                                      sx={{
                                        ":hover": {
                                          color: common.primaryColor,
                                          cursor: "pointer",
                                        },
                                      }}
                                      onClick={() =>
                                        // the theacher doesn`t have studyClassId
                                        role === Role.LISTENER && navigate(
                                          `/myCourses/${classId}/theme/${child.id}`
                                        )
                                      }
                                    >
                                      {child.name}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : child.dateOfAccess &&
                                isBefore(
                                  new Date(),
                                  new Date(child.dateOfAccess)
                                ) ? (
                                <Box display="flex" gap={2}>
                                  <DescriptionOutlinedIcon />
                                  <Typography>{child.name}</Typography>
                                </Box>
                              ) : (
                                <Box
                                  display="flex"
                                  gap={2}
                                  onClick={() => {
                                    // the theacher doesn`t have studyClassId
                                    role === Role.LISTENER && navigate(
                                      `/myCourses/${classId}/theme/${child.id}`
                                    );
                                  }}
                                >
                                  <DescriptionOutlinedIcon />
                                  <Typography
                                    sx={{
                                      ":hover": {
                                        color: common.primaryColor,
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    {child.name}
                                  </Typography>
                                </Box>
                              )}
                            </>
                          )}
                          {child.type === ContentType.TASK && (
                            <>
                              {!child.dateOfAccess ? (
                                <Box
                                  display="flex"
                                  gap={2}
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <Box sx={{ display: "flex", gap: 1 }}>
                                    <NoteAltOutlinedIcon />
                                    <Typography
                                      sx={{
                                        ":hover": {
                                          color: common.primaryColor,
                                          cursor: "pointer",
                                        },
                                      }}
                                      onClick={() =>
                                        // the theacher doesn`t have studyClassId
                                        role === Role.LISTENER && navigate(
                                          `/myCourses/current/${classId}/tasks/${child.id}`
                                        )
                                      }
                                    >
                                      {child.name}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : child.dateOfAccess &&
                                isBefore(
                                  new Date(),
                                  new Date(child.dateOfAccess)
                                ) ? (
                                <Box display="flex" gap={2}>
                                  <NoteAltOutlinedIcon />
                                  <Typography>{child.name}</Typography>
                                </Box>
                              ) : (
                                <Box
                                  display="flex"
                                  gap={2}
                                  onClick={() => {
                                    // the theacher doesn`t have studyClassId
                                    role === Role.LISTENER && navigate(
                                      `/myCourses/current/${classId}/tasks/${child.id}`
                                    );
                                  }}
                                >
                                  <NoteAltOutlinedIcon />
                                  <Typography
                                    sx={{
                                      ":hover": {
                                        color: common.primaryColor,
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    {child.name}
                                  </Typography>
                                </Box>
                              )}
                            </>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              alignItems: "flex-end",
                            }}
                          >
                            {isBefore(
                              new Date(),
                              new Date(child.dateOfAccess || "")
                            ) && (
                                <Box
                                  display="flex"
                                  sx={{
                                    borderRadius: "10px",
                                    background: "#C1C1C1",
                                    width: "200px",
                                  }}
                                  padding="3px 5px"
                                  gap={1}
                                >
                                  <LockOutlinedIcon />
                                  <Typography>
                                    {format(
                                      new Date(child.dateOfAccess),
                                      "dd.MM.yyyy HH:mm"
                                    )}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        </Box>
                      )
                    )}
                </AccordionDetails>
              )}
          </Accordion>
        );
      })}
    </>
  )
}
