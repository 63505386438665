import { useState, useEffect } from 'react';
import AvatarViaUserId from '@components/AvatarViaUserId';
import UserFullName from '@components/UserFullName';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AttachmentIcon from '@mui/icons-material/Attachment';

import { serviceTask } from '@services/task';
import { downloadFile, formatDateWithHourMinute } from '@utils/functions';
import TextReader from '@ui/TextReader';
import { IComment, IFile } from '@interfaces/index';
import { useTranslation } from 'react-i18next';
import AntiPlagiarsmCheckResults from './AntiPlagiarismCheckResults';

interface IProps {
  progressId: string;
  userId: string | null;
  refresh?: boolean;
}

const CommentList = ({ progressId, userId, refresh }: IProps) => {
  const [commentList, setCommentList] = useState<Array<IComment>>([]);

  useEffect(() => {
    if (progressId) {
      serviceTask.getAllTaskComments(progressId).then(res => {
        setCommentList(res.data.reverse());
      })
    }
  }, [progressId, refresh]);

  if (commentList.length === 0)
    return (
      <Box sx={{ mt: 3 }}>
        Пока ответов нет
      </Box>
    )

  return (
    <Box sx={{ mt: 3 }}>
      {commentList.map((item: IComment) => (
        <SingleCommentComponent 
          key={item.id} 
          item={item} 
          userId={userId} 
        />
      ))}
    </Box>
  )
}

export const SingleCommentComponent = ({ item, userId }) => {
  const currentUserId = item.commentatorType === "USER" ? item.commentatorMember?.userId : userId;
  const { t } = useTranslation(["notification"]);

  return (
    <Box sx={{ display: "block", mb: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mt: '12px', justifyContent: 'space-between' }}>
        <Box sx={{ display: "flex", alignItems: "center", color: "#323C47", lineHeight: "20px" }}>
          <AvatarViaUserId userId={currentUserId} sxProps={null} />
          <Typography sx={{ ml: '8px' }}>
            <UserFullName id={currentUserId || userId} />
          </Typography>
        </Box>
        <Typography>{item.createdAt ? formatDateWithHourMinute(item.createdAt) : t('notification:JUST_NOW')}</Typography>
      </Box>
      {item.comment &&
        <TextReader htmlContent={item.comment} />
      }
      {item?.replyFiles && item.replyFiles.length > 0 && item.replyFiles.map((file: IFile) => {
        return (
          <Box
            key={file.fileId}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
              mt: item.comment ? 0 : 2
            }}
          >
            <Typography sx={{ display: "flex", gap: 2 }}>
              <AttachmentIcon />
              <span style={{ color: '#3232F1', cursor: "pointer" }} onClick={() => downloadFile(file.fileId)}>
                {file.fileName}
              </span>
            </Typography>
          </Box>
        )
      })}
      <AntiPlagiarsmCheckResults 
        userId={userId} 
        fileId={item?.replyFiles[0]?.fileId}
      />
    </Box>
  )
}

export default CommentList;