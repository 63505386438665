import { useTranslation } from "react-i18next";
import { Box, FormControlLabel, Radio } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Clear from "@mui/icons-material/Clear";
import { FieldArrayRenderProps, useFormikContext } from "formik";

import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { TextEditor } from '@ui/formik/TextEditor';
import { IQuestionsFormikContext } from "../Questions";

interface IOneCorrectAnswersProps {
  arrayHelpers: FieldArrayRenderProps,
  index: number;
  quizIndex: number;
}

export const OneCorrectAnswers = ({ arrayHelpers, quizIndex, index }: IOneCorrectAnswersProps) => {
  const { t } = useTranslation(["course"]);
  const { values: formikValues, setValues } = useFormikContext<IQuestionsFormikContext>();
  const values = formikValues.data[quizIndex];

  const onChange = (checked: boolean) => {
    if (checked) updateChechedValues();
  }

  const updateChechedValues = () => {
    let newAnswers = values.answers.map((el, i) => i !== index ? ({ ...el, correct: false }) : ({ ...el, correct: true }))
    let newValues = { ...formikValues }
    newValues.data[quizIndex] = {
      ...values,
      answers: newAnswers
    }
    setValues(newValues, true)
  }

  const deleteAnswer = () => {
    return new Promise((resolve) => {
      arrayHelpers.remove(index)
      return resolve({})
    })
  }

  const { DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteAnswer,
    modalTitle: t('course:messages.ARE_YOU_SURE_TO_DELETE_ANSWER_OPTION')
  })

  return (
    <>
      {DeleteModal}
      <Box sx={{ mb: '24px', width: '70%', display: 'flex', alignItems: 'center', ml: '-11px' }}>
        <FormControlLabel
          control={<Radio checked={values.answers[index].correct} />}
          label=""
          name={`data.${quizIndex}.answers.${index}.correct`}
          onChange={(_, checked) => onChange(checked)}
        />
        <Box sx={{ ml: '1px' }}><TextEditor name={`data.${quizIndex}.answers.${index}.answer`} label='' shortToolbar /></Box>
        <IconButton onClick={deleteAnswer} >
          <Clear sx={{ width: '25px', height: '25px', ml: '15px' }} />
        </IconButton>
      </Box>
    </>
  )
}