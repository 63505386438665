import { MouseEvent, useState, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Accordion, AccordionSummary, Box, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { DragDropContext, Draggable, Droppable, DropResult, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

import { CourseContext } from "@context/CourseContext";
import List from "./AdditionalResource/List";
import TextReader from "@ui/TextReader";
import { AuthorRole, ContentType, CourseStatus, IContent } from "@interfaces/index";
import { CourseAdditionalResource } from "@models/course-additional-resource";

type onDragEndFunctionType = (result: DropResult) => void;
type handleToggleActionMenuFunctionType = (event: MouseEvent<HTMLElement> | null, currentContent?: IContent) => void;
type setContentFunctionType = (content: IContent) => void;

interface IDragDropContextComponentProps {
  list: Array<IContent>;
  type: string;
  onDragEnd: onDragEndFunctionType;
  handleToggleActionMenu: handleToggleActionMenuFunctionType;
  setContent: setContentFunctionType;
  courseStatus: string;
  handleToggleResourceActionMenu: (event: MouseEvent<HTMLElement> | null, currentResource?: CourseAdditionalResource) => void;
}

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined, contentType: string) => ({
  // TODO: for now styles does not change anything
  backgroundColor: isDragging ? "#4a2975" : "#FFFFFF",
  color: isDragging ? "#FFFFFF" : "#000000",
  width: contentType === ContentType.SECTION ? "95%" : "100%",

  ...draggableStyle
});

const DragDropContent = ({ list, type, onDragEnd, handleToggleActionMenu, setContent, courseStatus, handleToggleResourceActionMenu }: IDragDropContextComponentProps) => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const [itemDragDisabled, setItemDragDisabled] = useState<boolean>(true);
  const [contentAccordionData, setContentAccordionData] = useState<{ expanded: boolean, list: Array<string | null> }>(() => {
    const storedData = localStorage.getItem('contentAccordionData');
    return storedData ? JSON.parse(storedData) : { expanded: true, list: [] };
  });
  const { courseData } = useContext(CourseContext);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)
  const isActionDisabled = courseStatus === CourseStatus.PUBLISHED || courseStatus === CourseStatus.CLOSED || !(isAuthor || isCoAuthor);

  const handleChange = (content: IContent) => (_, isExpanded: boolean) => {
    let { list } = { ...contentAccordionData };
    if (isExpanded) {
      if (content.type === ContentType.THEME && !list.includes(content.parentId)) {
        list = [...list, content.parentId];
      }
      list = [...list, content.id];
    } else {
      list = [...contentAccordionData.list].filter((x: any) => x !== content.id);
    }
    setContentAccordionData({ expanded: false, list });
    localStorage.setItem('contentAccordionData', JSON.stringify({ expanded: false, list }));
  };

  const goToThemePage = (event: MouseEvent<HTMLElement> | null, sectionId: string | null, themeId: string | null) => {
    if (event) event.stopPropagation();
    navigate(`/myCourses/${courseId}/${sectionId ? sectionId : 'new'}/theme/${themeId}`);
  }

  const goToTaskPage = (event: MouseEvent<HTMLElement> | null, parentType: string | null, parentId: string | null, taskId: string | null, taskFormat: string | null) => {
    if (event) event.stopPropagation();
    const sectionId = parentType === ContentType.SECTION ? parentId : "new";
    const themeId = parentType === ContentType.THEME ? parentId : "new";
    navigate(`/myCourses/${courseId}/tasks/${taskId}/${taskFormat}/${sectionId}/${themeId}`);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={type === ContentType.COURSE ? "content-list" : "item-list"}>
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: type === ContentType.COURSE ? 3 : type === ContentType.SECTION ? 2 : 0
            }}
          >
            {
              list.map((content: IContent, index: number) => {
                const isExpanded = contentAccordionData.list.includes(content?.id) || (contentAccordionData.expanded && content.type === ContentType.SECTION);
                const showExpandIcon = (content?.children?.length > 0 && content.type !== ContentType.TASK) || Boolean(content?.additionalResources?.length);
                return (
                  <Draggable
                    key={content?.id}
                    draggableId={content?.id || ""}
                    index={index}
                    isDragDisabled={itemDragDisabled}
                  >
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, type)}
                        sx={{ ":last-child": { mb: type === ContentType.SECTION ? 2 : 0 } }}
                      >
                        <Accordion
                          expanded={isExpanded}
                          onChange={handleChange(content)}
                          disableGutters={true}
                          sx={{
                            backgroundColor: type === ContentType.COURSE ? common.rootBgSecondary : '#FDFDFD',
                            border: type === ContentType.COURSE ? `1px solid ${common.borderSecondary}` : null,
                            '& .MuiPaper-root': {
                              borderRadius: 0,
                            }
                          }}
                          TransitionProps={{ unmountOnExit: true }}
                        >
                          <AccordionSummary
                            sx={{
                              display: 'flex',
                              justifyContent: "space-between"
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                  {type !== ContentType.THEME &&
                                    <OpenWithIcon
                                      sx={{ color: `${common.primaryColor}`, cursor: "grab" }}
                                      onMouseEnter={() => {
                                        setItemDragDisabled(false);
                                        setContent(content);
                                      }}
                                      onMouseLeave={() => {
                                        setItemDragDisabled(true);
                                      }}
                                    />
                                  }
                                  {content?.type === ContentType.THEME && <DescriptionOutlinedIcon sx={{ ml: content?.parentType === ContentType.THEME ? 5 : 0 }} />}
                                  {content?.type === ContentType.TASK && <NoteAltOutlinedIcon />}
                                  <Typography
                                    onClick={(event) => content?.type === ContentType.THEME ?
                                      goToThemePage(event, content?.parentId, content?.id) :
                                      content?.type === ContentType.TASK ? goToTaskPage(event, content?.parentType, content?.parentId, content?.id, content?.format) : null
                                    }
                                    sx={content?.parentType === ContentType.COURSE ? {
                                      fontWeight: 600,
                                      fontSize: "20px",
                                      lineHeight: "18px"
                                    } : null}
                                  >
                                    {content?.name}
                                  </Typography>
                                  {isExpanded ? (showExpandIcon && <ExpandMoreIcon />) : (showExpandIcon && <KeyboardArrowRightIcon />)}
                                </Box>
                                <Box
                                  sx={{ marginLeft: 'auto', display: "flex", gap: 0.5 }}
                                >
                                  <Box>
                                    {(content?.type === ContentType.THEME || content?.type === ContentType.TASK) && content?.status === CourseStatus.SAVED &&
                                      <RemoveCircleOutlineIcon sx={{ cursor: "pointer" }} />
                                    }
                                    {(content?.type === ContentType.THEME || content?.type === ContentType.TASK) && content?.status !== CourseStatus.SAVED &&
                                      <CheckCircleIcon sx={{ color: `${common.primaryColor}`, cursor: "pointer" }} />
                                    }
                                  </Box>
                                  <Box
                                    onClick={isActionDisabled ? undefined : (event) => handleToggleActionMenu(event, content)}
                                  >
                                    <MoreVertIcon
                                      sx={{ color: isActionDisabled ? '#0000001F' : `${common.primaryColor}`, cursor: 'pointer' }}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                              {content?.type === ContentType.SECTION && 
                                <Box sx={{ color: 'rgba(109, 115, 122, 1)', mt: 1 }}>
                                  <TextReader htmlContent={content.shortDescription} hideBorder />
                                </Box>
                              }
                            </Box>
                          </AccordionSummary>
                          <DragDropContent
                            list={content.children}
                            type={content.type || "notNull"}
                            onDragEnd={onDragEnd}
                            handleToggleActionMenu={handleToggleActionMenu}
                            setContent={setContent}
                            courseStatus={courseStatus}
                            handleToggleResourceActionMenu={handleToggleResourceActionMenu}
                          />
                          {!!content.additionalResources?.length &&
                            <Box sx={{
                              display: "flex",
                              flexDirection: "column",
                              pl: '2.5%',
                              pr: '2.5%',
                              mb: 3
                            }}
                            >
                              <List
                                resourceList={content.additionalResources}
                                handleToggleActionMenu={handleToggleResourceActionMenu}
                              />
                            </Box>
                          }
                        </Accordion>
                      </Box>
                    )}
                  </Draggable>
                )
              })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragDropContent;