import { IStudyClass } from "@interfaces/index";

export const initialDto: IStudyClass = {
  id: "",
  courseId: null,
  course: null,
  scheduleId: null,
  name: null,
  status: "CREATED",
  capacity: null,
  canSelfRegistration: false,
  regStartDate: null,
  regEndDate: null,
  startDate: null,
  endDate: null,
  sendEmailMessage: true,
  message: null,
  tutorName: null,
  allStudentCount: null,
  activeStudentCount: null,
  useTimetable: false,
  sequentialLearning: true
}