import { ReactElement, FC, useState, useEffect } from "react";
import { AlertColor, Box, CardHeader, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TextEditor } from "@ui/formik/TextEditor";
import { Formik, Form as FormikForm } from "formik";
import FormikInput from "@ui/formik/Input";
import { initialDto, DTO } from "./models";
import CustomButton from "@ui/Button";
import { useSelector } from "react-redux";
import account from "@services/account";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { remove } from "lodash";
import { serviceUser } from "@services/user";
import CustomFileUpload from "@ui/FileUpload";
import { socialMediaValidationSchema } from "./validation";

interface Props {
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void
}
const PublicProfile: FC<any> = ({ setMessageOpen, setMessageType, setMessage }: Props): ReactElement => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["profile", "common", 'course']);
  const [initialData, setInitialData] = useState<DTO>(initialDto);
  const [files, setFiles] = useState<{ fileId: string; name: string }[]>(
    initialData.achievements
  );
  const profile = useSelector((store: any) => store.account.profileInfo);
  const [enableBtnAboutMe, setEnableBtnAboutMe] = useState<boolean>(true);

  const handleDeleteFile = (fileId: string) => {
    remove(files, {
      fileId: fileId,
    });
    setFiles([...files]);
    serviceUser
      .updateAchievments(profile.pltaId, files)
      .then((res) => {
        if (res.status === 200) {
          setFiles([...res.data]);
          setMessageOpen(true);
          setMessage(t('course:messages.FILE_DELETED'));
          setTimeout(() => {
            setMessageOpen(false);
          }, 5000);
        }
      });
  };

  const handleFileAfterUpload = (fileId: string, file: any) => {
    serviceUser
      .updateAchievments(profile.pltaId, [
        ...files,
        { fileId: fileId, name: file.name },
      ])
      .then((res) => {
        if (res.status === 200) {
          setFiles([...res.data]);
          setMessageOpen(true);
          setMessage(t('course:messages.FILE_UPLOADED_SUCCESSFULLY'));
          setTimeout(() => {
            setMessageOpen(false);
          }, 5000);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
  };

  useEffect(() => {
    if (profile.pltaId) {
      account.getProfileFullInfo(profile.pltaId).then(res => {
        if (res.status === 200) {
          setInitialData(res.data);
          setFiles(res.data?.achievements);
        }
      })
    }
  }, [profile.pltaId]);

  return (
    <Box>
      <CardHeader
        title={t("PUBLIC_INFO")}
        sx={{
          pt: 0,
          pr: 0,
          pl: 0,
          width: "100%",
          borderBottom: `1px solid ${common.strokeSecondary}`,
        }}
        titleTypographyProps={{
          fontWeight: 600,
          fontSize: { xl: 22, lg: 22, md: 20, sm: 18, xs: 16 },
        }}
      />
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={socialMediaValidationSchema(t)}
        onSubmit={(values) => {
          account
            .updateProfileInfo({ ...values, achievements: files })
            .then((res) => {
              if (res.status === 200) {
                setInitialData({ ...values, achievements: files });
                setMessageOpen(true);
                setMessage(t('common:messages.CHANGES_SAVED'));
                setMessageType('success')
                setTimeout(() => {
                  setMessageOpen(false);
                }, 5000);
              }
            }).finally(() => {
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }, 20)
            });
        }}
      >
        {({ submitForm, dirty, isValid }) => {
          return (
            <FormikForm>
              <Box
                sx={{
                  width: {
                    xl: "75%",
                    lg: "85%",
                    md: "85%",
                    sm: "90%",
                    xs: "95%",
                  },
                  marginTop: "20px",
                }}
              >
                <Typography
                  sx={{ mb: 1, mt: 2 }}
                  fontSize="22px"
                  fontWeight={600}
                >
                  {t('common:label.ABOUT_ME')}
                </Typography>
                <Typography fontSize={"16px"}>
                  {t('common:hints.ABOUT_ME_SECTION_HINT')}
                </Typography>
                <TextEditor name="detailInfo" minChar={100} maxChar={700} showSymbolCount={true} setIsValidSubmitBtn={setEnableBtnAboutMe} shortToolbar isRequired />
                <Typography
                  sx={{ mb: 1, mt: 2 }}
                  fontSize="22px"
                  fontWeight={600}
                >
                  {t('profile:ACHIEVMENTS')}
                </Typography>
                <CustomFileUpload
                  label={t('common:hints.ACHIEVEMENTS_SECTION_HINT')}
                  title={t('common:hints.ACCEPTABLE_FORMATS', { acceptedExtensions: '.jpg, .jpeg, .png, .gif, .bmp, .pdf' })}
                  fileTypeCode="008"
                  acceptedExtensions=".jpg, .jpeg, .png, .gif, .bmp, .pdf"
                  handleFileAfterUpload={handleFileAfterUpload}
                  hideIcon
                />
                {files?.length > 0 &&
                  files.map((file) => (
                    <Box
                      key={file.fileId}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography>{file.name}</Typography>
                      <DeleteOutlineIcon
                        sx={{ cursor: "pointer", color: common.errorColor }}
                        onClick={() => handleDeleteFile(file.fileId)}
                      />
                    </Box>
                  ))
                }
                {
                  files?.length === 0 &&
                  <Typography sx={{ color: common.errorColor }}>{t('common:validation.MANDATORY_FIELD')}</Typography>
                }
                <Typography
                  sx={{ mb: 1, mt: 2 }}
                  fontSize="22px"
                  fontWeight={600}
                >
                  {t('profile:SOCIAL_NETWORKS')}
                </Typography>
                <Box
                  display="flex"
                  sx={{ gap: { xl: 6, lg: 6, md: 5, sm: 4, xs: 3 } }}
                >
                  <FormikInput
                    name="facebook"
                    label="Facebook"
                    placeholder="https://www.facebook.com"
                  />
                  <FormikInput
                    name="youtube"
                    label="YouTube"
                    placeholder="https://www.youtube.com"
                  />
                  <FormikInput
                    name="instagram"
                    label="Instagram"
                    placeholder="https://www.instagram.com"
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  borderRadius="6px"
                  width="98px"
                  height="38px"
                  disabled={!(isValid && dirty && enableBtnAboutMe && files.length > 0)}
                  onClick={submitForm}
                >
                  {t("common:actions.SAVE")}
                </CustomButton>
              </Box>
            </FormikForm>
          );
        }}
      </Formik>
    </Box>
  );
};

export default PublicProfile;
