import { QuizQuestion } from '@models/quiz-question';
import { Box, FormControl, Grid, Typography } from '@mui/material';

interface ISequenceViewProps {
	data: QuizQuestion;
	index: number
}

export const SequenceView = ({ data }: ISequenceViewProps) => {
	return (
		<FormControl sx={{ width: "100%" }} >
			{data.answers?.map((el, ind) => (
				<Box key={ind} sx={{ px: '48px', display: 'flex', alignItems: 'center', width: "100%" }}>
					<Grid sx={{ width: "100%" }} alignItems="center" container spacing={2}>
						<Grid item xs={.5}>
							<Typography sx={{ fontWeight: 500, fontSize: '20px' }}>{el.order}</Typography>
						</Grid>
						<Grid item xs={10.8}>
							<div
								dangerouslySetInnerHTML={{
									__html: el.answer || "",
								}}
							></div>
						</Grid>
					</Grid>
				</Box >
			))}
		</FormControl>
	)
}