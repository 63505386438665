import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { AlertColor, TablePagination } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import dayjs from "dayjs";
import { Formik, Form as FormikForm, useFormikContext } from "formik";

import { serviceCourse } from "@services/course";
import { serviceTask } from "@services/task";
import { CourseContext } from "@context/CourseContext";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import useLocalPagination from "@hooks/useLocalPagination";
import {
  CourseTaskRecipient,
  CourseTaskRecipientTypeEnum,
} from "@models/course-task-recipient";
import DateTimePicker from "@ui/formik/DateTimePicker";
import CustomButton from "@ui/Button";
import CustomSearch from "@ui/Search";
import useAddRecipientModal from "./AddRecipientModal";
import {
  ICourseSetting,
  IMember,
  CourseStatus,
  TypeCode,
  TaskType,
  CourseRole,
  AuthorRole,
} from "@interfaces/index";
import {
  IRecipients,
  IStream,
  initialDTO,
  initialStreamData,
} from "../../Theme/models";
import FormikCheckbox from "@ui/formik/Checkbox";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import { serviceMember } from "@services/member";
import { DeleteIcon, EditIcon } from "@assets/svg_files/Icons";
import { taskRecipientParameters } from "./validation";
import { useAppSelector } from "@store/hooks";

interface IProps {
  taskStatus: string;
  taskType?: string;
  setMessageOpen: (modalState: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
  taskName: string;
  answerType: string;
}

interface IPropsRevalidate {
  responsibleMemberOptions: CourseRole[];
  currentStream: IStream | null;
  setTutorOptions: (value: React.SetStateAction<IMember[]>) => void;
  setTutorsLoaded: (value: React.SetStateAction<boolean>) => void;
  taskId: string | undefined;
}

const RevalidateValueRecipient = (props: IPropsRevalidate) => {
  const {
    responsibleMemberOptions,
    currentStream,
    setTutorOptions,
    setTutorsLoaded,
    taskId,
  } = props;
  // Grab values and submitForm from context
  const { setValues, values } = useFormikContext<IRecipients>();

  useEffect(() => {
    if (currentStream?.id) {
      setValues({ ...values, responsibleMembers: [] });
      serviceMember
        .getMembersByStream(responsibleMemberOptions, currentStream?.id)
        .then((res) => {
          if (res.status === 200) {
            setTutorOptions(res.data);
            setTutorsLoaded(true);
          }
        });
    }
    // eslint-disable-next-line
  }, [currentStream?.id, taskId]);

  return null;
};

const RecipientList = ({
  taskType,
  taskStatus,
  setMessage,
  setMessageType,
  setMessageOpen,
  taskName,
  answerType,
}: IProps) => {
  const { t } = useTranslation(["common", "course", "enum"]);
  const { common } = useTheme().palette;
  const { taskId } = useParams();
  const [recipientsData, setRecipientsData] = useState<CourseTaskRecipient[]>(
    []
  );
  const [settingsData, setSettingsData] = useState<ICourseSetting>();
  const { courseId } = useParams();
  const { courseData } = useContext(CourseContext);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)
  const isClosed = courseData?.status === CourseStatus.CLOSED;
  const [currentRecipient, setCurrentRecipient] =
    useState<CourseTaskRecipient | null>(null);
  const [currentStream, setCurrentStream] = useState<IStream | null>(null);
  const [searchText, setSearchText] = useState("");
  const [streams, setStreams] = useState<IStream[]>(initialStreamData);
  const [tutorOptions, setTutorOptions] = useState<IMember[]>([]);
  const [tutorsLoaded, setTutorsLoaded] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<IRecipients>(initialDTO);
  const profile = useAppSelector((store) => store.account.profileInfo.pltaId);
  const responsibleMemberOptions =
    taskType === TaskType.TASK
      ? [CourseRole.TUTOR, CourseRole.ASSISTANT]
      : [CourseRole.TUTOR, CourseRole.EXAMINER];
  const [hoverStates, setHoverStates] = useState({});
  const handleHoverChange = (recipientId, isHovered, type) => {
    setHoverStates((prevHoverStates) => ({
      ...prevHoverStates,
      [`${type}-${recipientId}`]: isHovered,
    }));
  };

  const { handleOpenModal: handleOpenAddModal, AddRecipientsModal } =
    useAddRecipientModal({
      onCreated: () => getRecipients(taskId || "", currentStream?.id || ""),
      taskStatus: taskStatus,
      settingsData: settingsData,
      dateOfAccess: initialData.dateOfAccess || "",
      taskType,
      classId: currentStream?.id || "",
      currentRecipient,
      taskName,
      answerType,
      currentStream: currentStream ?? {} as IStream
    });

  const {
    totalElements,
    rowsPerPage,
    page,
    data,
    handleChangePage,
    handleChangeRowsPerPage,
    setInitData,
  } = useLocalPagination<CourseTaskRecipient>();

  const deleteRecipient = (id: string) => {
    return serviceTask
      .deleteTaskRecipient(id)
      .then((res) => {
        if (res.status === 200) {
          getRecipients(taskId || "", currentStream?.id || "");
          if (courseData?.status === "SAVED") {
            if (currentRecipient?.group) {
              setMessage(
                t("course:messages.GROUP_OF_LISTENERS_REMOVED_STATUS_SAVED")
              );
            } else {
              setMessage(t("course:messages.LISTENER_REMOVED_STATUS_SAVED"));
            }
          } else {
            if (currentRecipient?.group) {
              setMessage(
                t("course:messages.GROUP_OF_LISTENERS_REMOVED_STATUS_PUBLISHED")
              );
            } else {
              setMessage(
                t("course:messages.LISTENER_REMOVED_STATUS_PUBLISHED")
              );
            }
          }
          setMessageType("success");
          setMessageOpen(true);
          setTimeout(() => {
            setMessageOpen(false);
          }, 5000);
          return res;
        }
      })
      .catch((err) => {
        if (err.response.data.code === "TASK_ALREADY_STARTED") {
          setMessageType("error");
          // уточнить насчет сообщения
          setMessage(
            currentRecipient?.group
              ? t("course:messages.GROUP_OF_LISTENERS_CANNOT_BE_DELETED")
              : t("course:messages.LISTENER_CANNOT_BE_DELETED")
          );
          setMessageOpen(true);
          setTimeout(() => {
            setMessageOpen(false);
          }, 5000);
        }
      });
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteRecipient,
    modalTitle: t(
      "course:messages.ARE_YOU_SURE_WANT_TO_REMOVE_RECIPIENT_FROM_FLOW",
      {
        currentStream: `${t("course:EDU_STREAM")} #${streams.findIndex((stream) => stream.id === currentStream?.id) + 1
          }`,
        currentRecipient: currentRecipient?.member?.userFullName
          ? currentRecipient?.member?.userFullName
          : currentRecipient?.group?.name,
      }
    ),
  });

  useEffect(() => {
    if (courseId) {
      serviceCourse.getAllStudyClassesByCourseId(courseId).then((res) => {
        if (res.status === 200) {
          setStreams(res.data);
        }
      });
    }
  }, [courseId]);

  // Параметры выбранного потока
  useEffect(() => {
    if (taskId && currentStream?.id) {
      serviceCourse
        .getTaskRecipientsParameters(currentStream.id, taskId)
        .then((res) => {
          if (res.status === 200) {
            setInitialData((prev) => ({
              ...prev,
              ...res.data,
              id: res.data ? res.data.id : null,
              isCloseAccess: taskType === TaskType.MIDTERM_1 || taskType === TaskType.MIDTERM_2 || taskType === TaskType.FINALEXAM ? true : res.data ? !!res.data.dateOfAccess : false,
              responsibleMembers: res.data ? res.data.responsibleMembers : [],
              responsibleMemberIds: res.data
                ? res.data.responsibleMemberIds
                : [],
            }));
          }
        });
    }
  }, [taskId, currentStream?.id, taskType]);

  // Начальное значение для потока
  useEffect(() => {
    serviceCourse.getCurrentStudyClass(courseId).then(res => {
      if (res.status === 200) {
        setCurrentStream(streams.filter(stream => stream.id === res.data)[0] || streams[0] || null)
      } else {
        setCurrentStream(streams[0]);
      }
    })
  }, [courseId, streams]);

  const getRecipients = useCallback(
    (taskId, classId) => {
      serviceTask
        .getAllRecipientsByClassId(taskId, classId)
        .then((res) => {
          if (res.status === 200) {
            setRecipientsData(res.data);
            setInitData([...res.data]);
          }
        })
        .catch(() => { });
    },
    [setInitData]
  );
  // NOTE: work here
  // useEffect(() => {
  //   if (currentStream?.id) {
  //     serviceMember
  //       .getMembersByStream(responsibleMemberOptions, currentStream?.id)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setTutorOptions(res.data);
  //           setTutorsLoaded(true);
  //         }
  //       });
  //   }
  //   // eslint-disable-next-line
  // }, [currentStream?.id, taskId]);

  useEffect(() => {
    if (currentStream?.id) {
      serviceCourse.getStudyClassById(currentStream.id).then((res) => {
        if (res.status === 200) setSettingsData(res.data);
      });
    }
  }, [currentStream?.id]);

  useEffect(() => {
    if (currentStream?.id) {
      if (taskId) getRecipients(taskId, currentStream.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStream?.id, taskId]);

  useEffect(() => {
    filterBySearchText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const filterBySearchText = () => {
    const filteredData = recipientsData.filter((el) => {
      if (el.type === "ALL") {
        return true;
      }
      if (
        el.member?.userFullName
          ?.toUpperCase()
          .includes(searchText.toUpperCase()) ||
        el.group?.name?.toUpperCase().includes(searchText.toUpperCase())
      ) {
        return true;
      }
      return false;
    });
    setInitData(filteredData);
  };

  const isCreateAccess = useMemo(() => {
    let isAccess = true;
    recipientsData.forEach((el: CourseTaskRecipient) => {
      if (el.type === CourseTaskRecipientTypeEnum.ALL) {
        isAccess = false;
      }
    });
    return isAccess;
  }, [recipientsData]);

  const baseTableRowCellStyle: SxProps<Theme> = {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#7D7D7D",
    mr: "16px",
  };

  const getDefaultDateOfAccess = useCallback(() => {
    const currentDate = new Date();
    if (currentStream?.startDate) return currentStream?.startDate;
    return currentDate;
  }, [currentStream?.startDate]);


  if (streams.length > 0)
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {AddRecipientsModal}
        {DeleteModal}
        {tutorOptions.length <= 0 && tutorsLoaded && (
          <Box
            sx={{
              border: `1px solid ${common.borderSecondary}`,
              borderLeft: `6px solid rgba(255, 149, 0,1)`,
              borderRadius: "4px",
              p: 1,
              display: "flex",
              gap: 1,
              alignItems: "center",
              width: "70%",
            }}
          >
            <Box
              sx={{
                width: "28px",
                height: "28px",
                borderRadius: "50%",
                backgroundColor: "rgba(255, 149, 0, 0.4)",
                display: "flex",
                flexShrink: 0,
              }}
            >
              <WarningOutlinedIcon
                sx={{
                  color: "rgba(255, 149, 0,1)",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  margin: "auto",
                }}
              />
            </Box>
            {t("course:hints.THERE_IS_NO_ANY_PARTICIPANT_IN_THE_FLOW")}
          </Box>
        )}
        <Box sx={{ width: "100%", display: "flex", gap: 1, flexWrap: "wrap" }}>
          {streams.map((stream, index) => {
            return (
              <Box
                key={stream.id}
                onClick={() => setCurrentStream(stream)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "115px",
                  borderRadius: "6px",
                  height: "40px",
                  p: "12px 16px",
                  cursor: "pointer",
                  background:
                    currentStream?.id === stream.id
                      ? common.primaryColor
                      : "none",
                  color: currentStream?.id === stream.id ? "#fff" : "#000",
                  "&:hover": {
                    background: common.primaryColor,
                    color: "#fff",
                  },
                }}
              >
                {t("course:EDU_STREAM")} #{index + 1}
              </Box>
            );
          })}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CustomSearch
            borderRadius="4px"
            placeholder={t("placeholders.SEARCH")}
            onChange={(value: string) => setSearchText(value)}
            width="63%"
          />
          <CustomButton
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            borderRadius="6px"
            height="38px"
            width="100px"
            disabled={
              !isCreateAccess ||
              isClosed ||
              !(
                isAuthor || isCoAuthor ||
                initialData.responsibleMembers.some(
                  (member) => member.userId === profile ?? ""
                )
              ) || tutorOptions.length <= 0
            }
            onClick={() => {
              setCurrentRecipient(null);
              handleOpenAddModal();
            }}
          >
            {t("common:actions.ADD")}
          </CustomButton>
        </Box>
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={taskRecipientParameters(t)}
          onSubmit={(values) => {
            const classId = streams.find(
              (stream) => stream.id === currentStream?.id
            )?.id;
            const responsibleMemberIds =
              values.responsibleMembers?.length > 0
                ? values.responsibleMembers.map((member) => member.id)
                : [];
            const createRecipientParameters = {
              taskId,
              classId,
              dateOfAccess: values.dateOfAccess,
              responsibleMemberIds,
            };
            serviceCourse
              .changeTaskRecipientsParameters(
                values.id === null
                  ? createRecipientParameters
                  : { ...values, responsibleMemberIds }
              )
              .then((res) => {
                if (res.status === 200) {
                  setMessage(t("common:messages.CHANGES_SAVED"));
                  setMessageType("success");
                  setMessageOpen(true);
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 3000);
                  setInitialData({ ...values, id: res.data.id });
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              });
          }}
        >
          {({ values, setFieldValue, submitForm, dirty, isValid }) => {
            return (
              <FormikForm>
                <FormikAutocomplete
                  sx={{ width: "40%" }}
                  name="responsibleMembers"
                  label={t("course:theme.RESPONSIBLE_TEACHER")}
                  multiple
                  valueAsObject
                  options={tutorOptions}
                  clearable={false}
                  hintText={t("course:hints.RESPONSIBLE_MEMBER_HINT")}
                  disabled={
                    !(
                      isAuthor || isCoAuthor ||
                      initialData.responsibleMembers.some(
                        (member) => member.userId === profile ?? ""
                      )
                    ) || isClosed
                  }
                />
                <RevalidateValueRecipient
                  currentStream={currentStream}
                  responsibleMemberOptions={responsibleMemberOptions}
                  setTutorOptions={setTutorOptions}
                  setTutorsLoaded={setTutorsLoaded}
                  taskId={taskId}
                />
                <Box sx={{ mt: 1 }}>
                  <FormikCheckbox
                    label={t("course:theme.RESTRICT_ACCESS_TO_TASK")}
                    name="isCloseAccess"
                    labelPlacement="end"
                    onChange={(_, value) =>
                      setFieldValue(
                        "dateOfAccess",
                        !value ? null : getDefaultDateOfAccess()
                      )
                    }
                    disabled={isClosed}
                  />
                  {values.isCloseAccess && (
                    <Box sx={{ width: "200px" }}>
                      <DateTimePicker
                        label={t("course:theme.ACCESS_OPENING_DATE")}
                        name="dateOfAccess"
                        isRequired
                        pickerType="dateTime"
                        minDate={currentStream?.startDate}
                        maxDate={currentStream?.endDate}
                        disabled={isClosed}
                      />
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: { sm: "flex-end", xs: "flex-start" },
                    mt: "12px",
                  }}
                >
                  <Box
                    sx={{
                      border: `1px solid ${common.primaryColor}`,
                      borderRadius: "9px",
                    }}
                  >
                    <CustomButton
                      width="98px"
                      height="38px"
                      backgroundColor={common.fontWhite}
                      borderRadius="8px"
                      fontColor={common.primaryColor}
                      sx={{
                        fontWeight: "500",
                        lineHeight: "20px",
                        alignItems: "center",
                      }}
                      onClick={submitForm}
                      disabled={!(isValid && dirty)}
                    >
                      {t("actions.SAVE")}
                    </CustomButton>
                  </Box>
                </Box>
              </FormikForm>
            );
          }}
        </Formik>
        {data?.length > 0 ? (
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <TableContainer
              component={Paper}
              sx={{ overflowX: "none", overflow: "none" }}
            >
              <Table sx={{ overflow: "none", overflowX: "none" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        ...baseTableRowCellStyle,
                        ml: "8px",
                      }}
                    >
                      {t("course:task.RECIPIENTS")}
                    </TableCell>
                    {courseData?.typeCode === TypeCode.EDU && (
                      <TableCell sx={baseTableRowCellStyle}>
                        {t("course:task.RANGE_OF_ALLOWED_IP_ADDRESSES")}
                      </TableCell>
                    )}
                    <TableCell sx={baseTableRowCellStyle}>
                      {t("course:task.COMPLETE_UNTIL")}
                    </TableCell>
                    <TableCell sx={baseTableRowCellStyle}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((recipient) => {
                    return (
                      <TableRow key={`${recipient.id}`}>
                        <TableCell
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            mr: "16px",
                            ml: "8px",
                            width: "235",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              color: "#323C47",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            {recipient.type ===
                              CourseTaskRecipientTypeEnum.GROUP
                              ? recipient.group?.name
                              : recipient.type ===
                                CourseTaskRecipientTypeEnum.ALL
                                ? "Всем"
                                : recipient.type ===
                                  CourseTaskRecipientTypeEnum.LISTENER
                                  ? recipient.member?.userFullName
                                  : ""}
                          </Box>
                        </TableCell>
                        {courseData?.typeCode === TypeCode.EDU && (
                          <TableCell sx={baseTableRowCellStyle}>
                            {recipient.ipRangeStart} - {recipient.ipRangeEnd}
                          </TableCell>
                        )}
                        <TableCell sx={baseTableRowCellStyle}>
                          {recipient.dateOfDeadline
                            ? dayjs(recipient.dateOfDeadline).format(
                              "DD-MM-YYYY HH:mm"
                            )
                            : ""}
                        </TableCell>
                        <TableCell sx={baseTableRowCellStyle}>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            {((courseData?.status !== CourseStatus.CLOSED) ||
                              (courseData?.status === CourseStatus.PUBLISHED &&
                                initialData?.dateOfAccess &&
                                new Date(initialData?.dateOfAccess) <
                                new Date())) && (
                                <Box
                                  onMouseEnter={() =>
                                    handleHoverChange(
                                      recipient.id || "",
                                      true,
                                      "edit"
                                    )
                                  }
                                  onMouseLeave={() =>
                                    handleHoverChange(
                                      recipient.id || "",
                                      false,
                                      "edit"
                                    )
                                  }
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setCurrentRecipient(recipient);
                                    handleOpenAddModal();
                                  }}
                                >
                                  <EditIcon
                                    color={
                                      hoverStates[`edit-${recipient.id || ""}`]
                                        ? common.primaryColor
                                        : "#ABA7AF"
                                    }
                                  />
                                </Box>
                              )}
                            {courseData?.status !== CourseStatus.CLOSED &&
                              recipient.type !==
                              CourseTaskRecipientTypeEnum.ALL && (
                                <Box
                                  onMouseEnter={() =>
                                    handleHoverChange(
                                      recipient.id || "",
                                      true,
                                      "delete"
                                    )
                                  }
                                  onMouseLeave={() =>
                                    handleHoverChange(
                                      recipient.id || "",
                                      false,
                                      "delete"
                                    )
                                  }
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setCurrentRecipient(recipient);
                                    openDeleteConfirmModal(recipient.id || "");
                                  }}
                                >
                                  <DeleteIcon
                                    color={
                                      hoverStates[
                                        `delete-${recipient.id || ""}`
                                      ]
                                        ? common.errorColor
                                        : "#ABA7AF"
                                    }
                                  />
                                </Box>
                              )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("enum:LINES_PER_PAGE")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("enum:TABLE_PAGES", { from, to, count })
                  }
                />
              </Box>
            </TableContainer>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: "100px" }}
            >
              <Avatar
                alt="Content-default-bg-img"
                src={require(`@assets/images/IllustrationRecipients.png`)}
                variant="square"
                sx={{
                  height: "auto",
                  width: "40%",
                  mb: "8px",
                }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  mt: "32px",
                  mb: "169px",
                }}
              >
                {t("course:hints.INFO_ABOUT_RECIPIENTS_OF_TASK")}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );

  return (
    <Box
      sx={{
        border: `1px solid ${common.borderSecondary}`,
        borderLeft: `6px solid rgba(255, 149, 0,1)`,
        borderRadius: "4px",
        p: 1,
        display: "flex",
        gap: 1,
        alignItems: "center",
        width: "70%",
      }}
    >
      <Box
        sx={{
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          backgroundColor: "rgba(255, 149, 0, 0.4)",
          display: "flex",
          flexShrink: 0,
        }}
      >
        <WarningOutlinedIcon
          sx={{
            color: "rgba(255, 149, 0,1)",
            width: "20px",
            height: "20px",
            display: "flex",
            margin: "auto",
          }}
        />
      </Box>
      {t("course:hints.THERE_IS_NO_ANY_FLOW")}
    </Box>
  );
};

export default RecipientList;
