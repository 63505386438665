import axios from "@utils/axios";

export const serviceAntiPlagiarism = {
  savePurchaseOrg: (bodyData) =>
    axios.post(
      "/api/external-api/integration/anti-plagiarism/savePurchaseOrg",
      bodyData
    ),
  getSettingAntiPlagiarism: () =>
    axios
      .get("/api/course/anti-plagiarism/common-settings/get")
      .then((res) => res.data),
  saveSettingAntiPlagiarism: (bodyData) =>
    axios.post("/api/course/anti-plagiarism/common-settings/save", bodyData),
  getCourseListToADD: (bodyData) =>
    axios.post("/api/course/anti-plagiarism/coursesToAdd", bodyData),
  AddCourseAntiPlagiarism: (bodyData) =>
    axios.post("/api/course/anti-plagiarism/courses/create", bodyData),
  getCourseListAntiPlagiarism: (filter) =>
    axios.post("/api/course/anti-plagiarism/courses", filter),
  updateSwitchCoursePlagiarism: (bodyData) =>
    axios.put(
      `/api/course/anti-plagiarism/course/${bodyData.courseId}/updateEnabled?enabled=${bodyData.enabled}`
    ),
  getIsActiceCourse: (courseId, orgId) =>
    axios.get(
      `/api/course/anti-plagiarism/course/${courseId}/${orgId}/isEnabled`
    ),
  getListenerListByCourseId: (bodyData) =>
    axios.post("/api/course/anti-plagiarism/users", bodyData),
  getDefaultTaskSettingAntiPlagiarism: () =>
    axios.get("/api/course/anti-plagiarism/task-settings/getDefault"),
  getCheckIsActiveAntiPlagiarismOrg: (orgId) =>
    axios.get(
      `/api/external-api/integration/anti-plagiarism/checkIsActive/${orgId}`
    ),
  getCheckIsActiveAntiPlagiarismCourse: (courseId, orgId) =>
    axios.get(
      `/api/course/anti-plagiarism/course/${courseId}/${orgId}/isEnabled`
    ),
  getUserTaskResultAntiPlagiarism: (courseId, userId, taskId, fileId = '') => 
    axios.get(
      `/api/course/anti-plagiarism/getUserTaskResults?userId=${userId}&&courseId=${courseId}&&taskId=${taskId}&&fileId=${fileId}`,
    ),
};
