import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "@components/Modal";
import { IStudyClass } from "@interfaces/index";
import { serviceCourse } from "@services/course";
import CustomButton from "@ui/Button";
import CustomDatePicker from "@ui/DatePicker";
import { formatDateWithTimezone } from "@utils/functions";
import { AlertColor, Box, Typography, useTheme } from "@mui/material";


interface Props {
    flowId: string | null;
    flowList: IStudyClass[];
    setFlowList: (list: Array<IStudyClass>) => void;
    modalOpen: boolean;
    handleToggleModal: (modalState: boolean, message?: string, messageType?: AlertColor) => void;
}
  

const CompleteRegistrationModal = ({
    modalOpen,
    handleToggleModal,
    flowId,
    flowList,
    setFlowList
}: Props) => {
    const { t } = useTranslation(['common', "user", "course"]);
    const { common } = useTheme().palette;
    const [regEndDate, setRegEndDate] = useState<Date | null>(new Date())

    const currentFlow = useMemo(() => {
        return flowList.find(flow => flow.id === flowId)
    }, [flowList, flowId])

    const completeRegistration = () => {
        if(regEndDate && flowId) {
            serviceCourse.completeStudyClassRegistration(flowId, formatDateWithTimezone(regEndDate)).then(res => {
                if (res.status === 200) {
                    handleToggleModal(false, t('course:messages.THE_REGISTRATION_END_DATE_HAS_BEEN_SET'), 'success')
                    const updatedFlowList = flowList.map(flow => {
                        return flow.id === flowId ? {...flow, regEndDate: formatDateWithTimezone(regEndDate)} : flow
                    })
                    setFlowList(updatedFlowList)
                }
            }).catch(e => handleToggleModal(false, t('course:messages.FAILED_TO_SET_REGISTRATION_END_DATE'), 'error'))
        }
    }

    return (
        <div id='datepicker-portal' style={{zIndex: 10000}}>
            <CustomModal open={modalOpen} handleToggleModal={handleToggleModal} showCloseIcon height={'auto'}>
                <>
                    <Typography 
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: "21px",
                            color: "#000000",
                            display: "flex",
                            alignItems: "center",
                            mb: "24px",
                            width: '100%'
                        }}
                    >
                        {t('course:FINISHING_FLOW_REGISTRATION')}
                    </Typography>
                    <Box sx={{mb: 2, position: 'relative', height: 'auto'}}>
                        <CustomDatePicker
                            name="regEndDate"
                            date={regEndDate}
                            handleDateChange={(date) => setRegEndDate(date)}
                            label={t('course:REGISTRATION_COMPLETION_DATE')}
                            portalId='datepicker-portal'
                            minDate={currentFlow?.regStartDate ? new Date(currentFlow.regStartDate) : undefined}
                            maxDate={currentFlow?.endDate ? new Date(currentFlow.endDate) : undefined}
                            isRequired
                            showTime
                        />
                    </Box>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', gap: 2}}>
                    <CustomButton
                            backgroundColor={common.btnCancel}
                            fontColor={common.primaryColor}
                            borderRadius="6px"
                            width='146px'
                            height="40px"
                            disabled={!regEndDate}
                            onClick={() => handleToggleModal(false)}
                            sx={{
                                alignItems: "center",
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            {t('actions.CANCEL')}
                        </CustomButton>
                        <CustomButton
                            backgroundColor={common.primaryColor}
                            fontColor={common.fontWhite}
                            borderRadius="6px"
                            width='146px'
                            height="40px"
                            disabled={!regEndDate}
                            onClick={completeRegistration}
                            sx={{
                                alignItems: "center",
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            {t('actions.CONFIRM')}
                        </CustomButton>
                    </Box>
                </>
            </CustomModal>
        </div>
    )
}

export default CompleteRegistrationModal