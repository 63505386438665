import { Box, FormControlLabel, Radio } from '@mui/material';
import { TextEditor } from '@ui/formik/TextEditor';
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { SyntheticEvent } from "react";
import { IQuestionsFormikContext } from "../Questions";

interface ITrueOrFalseProps {
	arrayHelpers: FieldArrayRenderProps,
	index: number
	quizIndex: number;
}

export const TrueOrFalse = ({ quizIndex, index }: ITrueOrFalseProps) => {
	const { values: formikValues, setValues } = useFormikContext<IQuestionsFormikContext>();
	const values = formikValues.data[quizIndex]

	const onChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
		if (checked) {
			updateChechedValues()
		}
	}

	const updateChechedValues = () => {
		let newAnswers = values.answers.map((el, i) => i !== index ? ({ ...el, correct: false }) : ({ ...el, correct: true }))
		let newValues = { ...formikValues }
		newValues.data[quizIndex] = {
			...values,
			answers: newAnswers
		}
		setValues(newValues, true)
	}

	return (
		<>
			<Box sx={{ mb: '24px', width: '70%', display: 'flex', alignItems: 'center', ml: '-11px' }}>
				<FormControlLabel
					control={<Radio checked={values.answers[index].correct} />}
					label=""
					name={`data.${quizIndex}.answers.${index}.correct`}
					onChange={onChange}
				/>
				<Box sx={{ ml: '1px' }}><TextEditor name={`data.${quizIndex}.answers.${index}.answer`} label='' shortToolbar /></Box>
			</Box>
		</>
	)
}