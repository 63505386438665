import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { serviceCourse } from "@services/course";
import { serviceDict } from "@services/dictionary";
import { CourseContext } from "@context/CourseContext";
import { StateSchema } from "@store/StateSchema";
// import { Role } from "@interfaces/index";
import { useQuery } from "@tanstack/react-query";
import {
  dictionaryControllerApi,
  infoCourseControllerApi,
  studyClassControllerApi,
} from "@services/index";
import { OrdinaryConfig } from "@interfaces/commonConfig";
import { EducatorCourseInfo, StudentCourseInfo } from "@interfaces/common";

const CourseProvider = ({ children }) => {
  const { courseId, classId } = useParams();
  const profile = useSelector(
    (store: StateSchema) => store.account.profileInfo
  );
  const {
    authorityInfo: { role },
    pltaId,
  } = profile;
  const [courseData, setCourseData] = useState();

  useEffect(() => {
    if (courseId) {
      serviceCourse.getCourseInfo(courseId).then((result) => {
        if (result.status === 200) {
          serviceDict.getTypeOptionById(result.data.typeId).then(res => {
            if (res.status === 200) {
              setCourseData((prev: any) => ({
                ...prev,
                ...result.data.courseSetting,
                courseId: result.data.id,
                courseName: result.data.name,
                status: result.data.status,
                createdBy: result.data.createdBy,
                userId: pltaId,
                roleType: role,
                maxFileSizeCanTutorUploadPerTask: res.data.maxFileSizeCanTutorUploadPerTask,
                maxFileSizeCanUploadPerTheme: res.data.maxFileSizeCanUploadPerTheme,
                maxPromoVideoSize: res.data.maxPromoVideoSize,
                maxThemeVideoSize: res.data.maxThemeVideoSize,
                provideExpertiseBeforePublish: res.data.provideExpertiseBeforePublish,
                useTimetable: res.data.useTimetable,
                typeCode: result.data.typeCode,
                authors: result.data.authors,
                externalId: result.data.externalId
              }));
            }
          })
        }
      });
    }
    if (classId && pltaId) {
      serviceCourse.getStudyClassById(classId).then((result) => {
        if (result.status === 200) {
          serviceDict.getTypeOptionById(result.data.course.typeId).then(res => {
            if (res.status === 200) {
              setCourseData((prev: any) => ({
                ...prev,
                ...result.data.course.courseSetting,
                courseId: result.data.course.id,
                courseName: result.data.course.name,
                status: result.data.status,
                createdBy: result.data.course.createdBy,
                userId: pltaId,
                roleType: role,
                maxFileSizeCanTutorUploadPerTask: res.data.maxFileSizeCanTutorUploadPerTask,
                maxFileSizeCanUploadPerTheme: res.data.maxFileSizeCanUploadPerTheme,
                maxPromoVideoSize: res.data.maxPromoVideoSize,
                maxThemeVideoSize: res.data.maxThemeVideoSize,
                provideExpertiseBeforePublish: res.data.provideExpertiseBeforePublish,
                useTimetable: res.data.useTimetable,
                typeCode: result.data.course.typeCode,
                authors: result.data.course.authors,
                externalId: result.data.course.externalId,
                difficultyLevelId: result.data.course.difficultyLevelId,
                credits: result.data.course.credits,
                coverFileId: result.data.course.coverFileId,
                description: result.data.course.description,
                promoVideoId: result.data.course.promoVideoId,
                promoVideoUrl: result.data.course.promoVideoUrl,
                targetAudience: result.data.course.targetAudience,
                coverColor: result.data.course.coverColor,
                startDate: result.data.startDate,
                endDate: result.data.endDate,
                sequentialLearning: result.data.sequentialLearning
              }));
            }
          })
        }
      })
    }
  }, [courseId, role, pltaId, classId]);

  const { data: courseInfo } = useQuery({
    queryKey: ["getCourseDataByClassId", courseId],
    queryFn: () => infoCourseControllerApi.getCourseInfo(courseId ?? ""),
    enabled: !!courseId,
  });

  const { data: commonConfig } = useQuery({
    queryKey: ["getTypeOptionById", courseInfo?.typeId],
    queryFn: courseInfo
      ? () => dictionaryControllerApi.getTypeOptionById(courseInfo.typeId)
      : () => ({} as OrdinaryConfig),
    enabled: !!courseInfo?.typeId,
    select: (result) => {
      const { id, ...rest } = result;
      return rest;
    },
  });

  const { data: studyClass } = useQuery({
    queryKey: ["getStudyClassById", classId],
    queryFn: () => studyClassControllerApi.getStudyClassById(classId ?? ""),
    enabled: !!(classId && pltaId),
  });

  const { data: commonConfigStudent } = useQuery({
    queryKey: ["getTypeOptionById", studyClass?.course?.typeId],
    queryFn: studyClass?.course?.typeId
      ? () =>
        dictionaryControllerApi.getTypeOptionById(studyClass?.course?.typeId)
      : () => ({} as OrdinaryConfig),
    enabled: !!studyClass?.course?.typeId,
    select: (result) => {
      const { id, ...rest } = result;
      return rest;
    },
  });

  const EducatorCourseInfo: Partial<EducatorCourseInfo> = useMemo(
    () => ({
      ...(courseInfo && courseInfo),
      ...(commonConfig && commonConfig),
    }),
    [commonConfig, courseInfo]
  );

  const StudentCourseInfo: Partial<StudentCourseInfo> = useMemo(
    () => ({
      ...(studyClass && studyClass),
      ...(commonConfigStudent && commonConfigStudent),
      userId: pltaId ?? "",
      roleType: role ?? "",
    }),
    [commonConfigStudent, pltaId, role, studyClass]
  );

  return (
    <CourseContext.Provider
      value={{
        courseData,
        setCourseData,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export default CourseProvider;
