import { QuizQuestion } from '@models/quiz-question';
import { Box, FormControl, Grid, Typography } from '@mui/material';

interface IMappingViewProps {
	data: QuizQuestion;
	index: number
}

export const MappingView = ({ data }: IMappingViewProps) => {
	return (
		<FormControl sx={{ width: "100%" }} >
			{data.children?.map((child, ind) => (
				<Box key={ind} sx={{ px: '48px', display: 'flex', alignItems: 'center', width: "100%" }}>
					<Grid sx={{ width: "100%" }} alignItems="center" container spacing={2}>
						<Grid item xs={.5}>
							<Typography sx={{ fontWeight: 500, fontSize: '20px' }}>{ind + 1}</Typography>
						</Grid>
						<Grid item xs={5.35} >
							<div
								dangerouslySetInnerHTML={{
									__html: child.content || "",
								}}
							></div>
						</Grid>
						<Grid item xs={5.35}>
							<div
								dangerouslySetInnerHTML={{
									__html: child.answers ? child.answers[0].answer || "" : ""
								}}
							></div>
						</Grid>
					</Grid>
				</Box >
			))}
		</FormControl>
	)
}