import { useState } from 'react';
import { Box, useTheme, Popover } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import StyledText from './StyledText';

const IconHints = ({ text }: { text: undefined | string }) => {
  const { common } = useTheme().palette;
  const [hint, setHint] = useState<HTMLElement | null>(null);
  const hintPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };

  const hintPopoverClose = () => {
    setHint(null);
  };

  const openHint = Boolean(hint);

  return (
    <Box>
      <Box
        onMouseEnter={hintPopoverOpen}
        onMouseLeave={hintPopoverClose}
        display='flex'
        alignItems='center'
      >
        <HelpOutlineIcon
          sx={{ width: '20px', color: '#B6B6B6', cursor: 'pointer' }}
        />
      </Box>
      {openHint &&
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none'
          }
          }
          open={openHint}
          anchorEl={hint}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={hintPopoverClose}
          disableRestoreFocus
          disableScrollLock
        >
          <Box sx={{
            p: 2,
            color: common.fontWhite,
            backgroundColor: '#1A1F36',
            width: { sm: '318px', xs: '232px' },
            fontFamily: 'Golos'
          }}>
            <StyledText text={text} />
          </Box>
        </ Popover>
      }
    </Box >
  )
}

export default IconHints