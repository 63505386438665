import { ITask, IComment } from "@interfaces/index";
import { FlashOffRounded } from "@mui/icons-material";

export interface IFilter {
  name: string;
  isNoMark: boolean;
  isExcludeTotalMark: boolean;
  enabledAntiPlagiarism: boolean;
  format?: string | null;
  status?: string | null;
  type?: string | null;
}

export const initialFilter: IFilter = {
  name: "",
  isNoMark: false,
  isExcludeTotalMark: false,
  enabledAntiPlagiarism: false,
};

export interface ITaskAnswerList {
  id: string | null;
  themeId?: string | null;
  taskId?: string | null;
  name: string | null;
  url: string | null;
  undownloadable: boolean;
  fileId: string | null;
}

export const materialInitialDto: ITaskAnswerList = {
  id: null,
  themeId: null,
  name: null,
  url: null,
  undownloadable: false,
  fileId: null,
};

export const taskInitialDto: ITask = {
  id: null,
  name: null,
  description: null,
  type: null,
  parentType: null,
  course: null,
  section: null,
  theme: null,
  point: null,
  isCloseAccess: true,
  dateOfAccess: null,
  format: null,
  answerType: null,
  hasAnswerTypeFileFormats: false,
  answerTypeFileFormats: [],
  dateOfDeadline: null,
  isNoMark: false,
  isExcludeTotalMark: false,
  isNotifyStudent: false,
  status: null,
  responsibleMembers: [],
  createdBy: null,
  createdAt: null,
  materials: [],
  quiz: {
    duration: 0,
    numberOfAttempts: null,
    showNumberOfQuestions: false,
  },
  responsibleMember: null,
  group: null,
  progress: {
    id: null,
    status: null,
    hasDeadlineDate: false,
    dateOfDeadline: null,
    point: null,
  },
  prevId: null,
  nextId: null,
  userMark: null,
  allowed: false,
  isSelfTest: false,
};

export const taskCommentInitialDto: IComment = {
  id: null,
  progressId: null,
  commentatorType: "LISTENER",
  comment: null,
  replyFiles: [],
};
