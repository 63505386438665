import { useState, useEffect } from 'react';
import Viewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useTranslation } from 'react-i18next';
import { serviceFile } from '@services/file';
import { Box } from '@mui/material';
import "@cyntler/react-doc-viewer/dist/index.css";

const target = process.env.REACT_APP_PRODUCTION_TARGET

const DocViewer = ({ fileId, width = 'auto', height = '800px' }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [publicUrl, setPublicUrl] = useState<string | null>(null)
  const { t } = useTranslation(["course"]);

  useEffect(() => {
    if (!fileId) return;

    serviceFile.getFileMimeTypePublic(fileId)
      .then((res) => {
        if (res.status === 200) {
          if(res.data?.type.includes('document') || res.data?.type.includes('msword')) {
            setPublicUrl(`${target}/file-storage/public/v1/get-file-with-mime-type/${fileId}`)
          } else {
            const url = window.URL.createObjectURL(res.data);
            setFileUrl(url);
          }
        }
      })
      .catch((error) => console.error('Error fetching document:', error));

    return () => {
      // Clean up the URL object when the component unmounts
      URL.revokeObjectURL("");
    };
  }, [fileId]);
  // Supported file types: 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'odt', 'odp', 'ods', 'txt'
  if (fileUrl || publicUrl)
    return (
      <Box
        sx={{
          width: width,
          height: height,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {
          fileUrl && 
          <Viewer
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: fileUrl }]}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
              }
            }}
          />
        }
        {
          publicUrl && 
          <iframe 
            style={{height: height}} 
            title='document' 
            src={`https://view.officeapps.live.com/op/view.aspx?src=${publicUrl}`} 
          />
        }
      </Box>
    );
  return (
    <div>{t('course:NO_FILE_SELECTED')}</div>
  )
};

export default DocViewer;