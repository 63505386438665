import { IComment, IProgress } from "@interfaces/index";

export interface IFilter {
  name: string;
  status?: string | null;
  type?: string | null;
  format?: string | null;
  responsibleMemberId?: string | null;
  showMyTasks: boolean;
  isNoMark: boolean;
  isExcludeTotalMark: boolean;
  enabledAntiPlagiarism: boolean;
}

export const initialFilter: IFilter = {
  name: "",
  showMyTasks: false,
  isNoMark: false,
  isExcludeTotalMark: false,
  enabledAntiPlagiarism: false
};

export const initialProgress: IProgress = {
  id: null,
  status: null,
  hasDeadlineDate: false,
  dateOfDeadline: null,
  point: null
};

export const taskCommentInitialDto: IComment = {
  id: null,
  progressId: null,
  commentatorType: "USER",
  commentatorMember: { id: null },
  comment: null
}