import { useField, useFormikContext } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch, SxProps } from '@mui/material'
import FormControl from "@mui/material/FormControl";

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  sx?: SxProps
}

const FormikSwitch = ({ name, label = '', disabled = false,
  sx = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "column",
    gap: 1,
    width: "100%"
  }, ...rest }: Props) => {
  const [field] = useField(name);
  const formikProps = useFormikContext();

  return (
    <FormControl
      sx={sx}>
      <FormControlLabel
        control={
          <Switch
            {...field}
            {...rest}
            name={name}
            checked={field.value || false}
            onChange={(event) => {
              formikProps.setFieldValue(name, event.target.checked);
            }
            }
            disabled={disabled}
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export default FormikSwitch;