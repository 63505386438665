import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import { Box, useTheme } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";

import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import { ICourse, CourseStatus, DictionaryCode, CourseFormatType } from "@interfaces/index";
import { serviceCourse } from "@services/course";
import { serviceDict } from "@services/dictionary";
import { StateSchema } from "@store/StateSchema";
import CustomButton from "@ui/Button";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import FormikInput from "@ui/formik/Input";
import { TextEditor } from "@ui/formik/TextEditor";
import { freeCourseValidationSchema } from "./validation";

interface Props {
  courseInfo: ICourse;
  setCourseInfo: (courseInfo: any) => void;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
}

const ParamsPageFree = ({
  courseInfo,
  setCourseInfo,
  setMessageOpen,
  setMessageType,
  setMessage,
}: Props) => {
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["common", "course"]);
  const profileId = useSelector((store: StateSchema) => store.account.profileInfo.pltaId);
  const isSaved = courseInfo.status === CourseStatus.SAVED;
  const isWithdrawn = courseInfo.status === CourseStatus.WITHDRAWN;
  const isClosed = courseInfo.status === CourseStatus.CLOSED;
  const isPublished = courseInfo.status === CourseStatus.PUBLISHED;
  const disabledByRole = profileId === courseInfo?.createdBy;
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const [enableBtnDiscription, setEnableBtnDiscription] = useState<boolean>(true);
  const [enableBtnShortDiscription, setEnableBtnShortDiscription] = useState<boolean>(true);
  const [targetAudienceIsValid, setTargetAudienceIsValid] = useState<boolean>(true);
  const [categories, setСategories] = useState<any>(null);
  const [categoryId, setCategoryId] = useState<any>(courseInfo.categoryId || null);
  const [subCategories, setSubCategories] = useState<any>(null);
  const [courseDifficultyLevel, setCourseDifficultyLevel] = useState<any>(null);

  useEffect(() => {
    serviceDict
      .getDictionaryList(DictionaryCode.CATEGORY, {
        pageNumber: 0,
        size: 1000,
        filter: { parentId: 0, isDisabled: false },
      })
      .then((res) => {
        if (res.status === 200) {
          setСategories(res.data.dtoList);
        }
      });
  }, []);

  useEffect(() => {
    if (categoryId) {
      serviceDict.getDictionaryChildList(DictionaryCode.CATEGORY, categoryId).then((res) => {
        if (res.status === 200) {
          setSubCategories(res.data);
        }
      });
    }
  }, [categoryId]);

  useEffect(() => {
    serviceDict
      .getDictionaryList(DictionaryCode.COURSE_DIFFICULTY_LEVEL, {
        pageNumber: 0,
        size: 1000,
        filter: {
          isActive: true
        }
      })
      .then((res) => {
        if (res.status === 200) {
          setCourseDifficultyLevel(res.data.dtoList);
        }
      });
  }, []);

  const handleSubmit = (values: ICourse) => {
    values.courseSetting.formatType = !values.courseSetting.formatType
      ? CourseFormatType.SYNC
      : values.courseSetting.formatType;
    if (courseId) {
      serviceCourse
        .updateCourse(courseId, {
          ...values,
        })
        .then((res) => {
          if (res.status === 200) {
            setCourseInfo((prev: ICourse) => ({
              ...prev,
              ...values,
            }));
            setMessageOpen(true);
            setMessageType("success");
            setMessage(t("common:messages.DATA_CHANGED_SUCCESSFULLY"));
            setTimeout(() => {
              setMessageOpen(false);
            }, 5000);
            const newCrumbs = [...getBreadcrumbs(courseId)].map((crumb) => {
              // If the crumb to update is found, replace the crumb with updated fields
              if (crumb.level === 1) {
                return { ...crumb, label: values.name };
              }
              // Otherwise, return the original crumb
              return crumb;
            });
            setBreadcrumbs(courseId, newCrumbs);
          }
        })
        .finally(() => {
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }, 25);
        });
    }
  };

  return (
    <Formik
      initialValues={courseInfo}
      enableReinitialize
      validationSchema={freeCourseValidationSchema(t)}
      onSubmit={handleSubmit}
    >
      {({ submitForm, dirty, isValid, setFieldValue }) => (
        <FormikForm>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormikInput
              name="name"
              label={t("course:COURSE_NAME")}
              isRequired
              disabled={
                !(isSaved || isWithdrawn) ||
                !disabledByRole ||
                isClosed ||
                isPublished
              }
              width="60%"
              maxChar={60}
              showSymbolCount
            />
            <FormikAutocomplete
              name="categoryId"
              label={t("course:CATEGORY")}
              options={categories}
              sx={{ width: "60%" }}
              onChange={(value) => {
                setCategoryId(value.id)
                setFieldValue("subCategoryId", null)
              }}
              isRequired
              disabled={
                (isSaved && isWithdrawn) ||
                !disabledByRole ||
                isClosed ||
                isPublished
              }
            />
            <FormikAutocomplete
              name="subCategoryId"
              label={t("course:SUBCATEGORY")}
              options={subCategories || []}
              sx={{ width: "60%" }}
              disabled={
                (isSaved && isWithdrawn) ||
                !disabledByRole ||
                isClosed ||
                isPublished
              }
            />
            <FormikAutocomplete
              name="difficultyLevelId"
              label={t("course:DIFFICULTY_LEVEL")}
              options={courseDifficultyLevel}
              sx={{ width: "60%" }}
              disabled={
                (isSaved && isWithdrawn) ||
                !disabledByRole ||
                isClosed ||
                isPublished
              }
              isRequired
            />
            <TextEditor
              name="shortDescription"
              label={t("course:SHORT_DESCRIPTION_OF_COURSE")}
              readOnly={
                !(isSaved || isWithdrawn) ||
                !(disabledByRole || !!courseInfo.externalId) ||
                isClosed ||
                isPublished
              }
              shortToolbar
              showInline
              isRequired
              showSymbolCount
              minChar={32}
              maxChar={1024}
              setIsValidSubmitBtn={setEnableBtnShortDiscription}
            />
            <TextEditor
              name="description"
              label={t("course:COURSE_DESCRIPTION")}
              readOnly={
                !(isSaved || isWithdrawn) ||
                !disabledByRole ||
                isClosed ||
                isPublished
              }
              showInline
              isRequired
              showSymbolCount
              minChar={64}
              maxChar={5120}
              setIsValidSubmitBtn={setEnableBtnDiscription}
            />
            <TextEditor
              name="targetAudience"
              label={t("course:WHO_IS_THIS_COURSE_FOR")}
              readOnly={
                (isSaved && isWithdrawn) ||
                !disabledByRole ||
                isClosed ||
                isPublished
              }
              shortToolbar
              showInline
              isRequired
              showSymbolCount
              minChar={10}
              maxChar={512}
              setIsValidSubmitBtn={setTargetAudienceIsValid}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <CustomButton
              backgroundColor={common.primaryColor}
              fontColor={common.fontWhite}
              width="98px"
              borderRadius="6px"
              height="38px"
              disabled={
                !(
                  isValid &&
                  dirty &&
                  enableBtnDiscription &&
                  enableBtnShortDiscription &&
                  targetAudienceIsValid
                )
              }
              onClick={submitForm}
            >
              {t("common:actions.SAVE")}
            </CustomButton>
          </Box>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ParamsPageFree;
