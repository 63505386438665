
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar, dateFnsLocalizer, View, Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import { enGB, ru, kk } from "date-fns/locale";
import { Theme, useTheme } from '@mui/material/styles';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

import { getTimeFromDate } from "@utils/functions";
import { EventType } from "@interfaces/eventCalendar";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.styles.scss";

const locales = {
  'en': enGB,
  'ru': ru,
  'kz': kk
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const messages = {
  today: "Сегодня",
  previous: "<",
  next: ">",
  month: "Месяц",
  week: "Неделя",
  day: "День",
  agenda: "Повестка дня",
  date: "Дата",
  time: "Время",
  event: "Событие",
};

const getCircleStyle = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // backgroundColor: '#BE123C', // You can use theme.palette for dynamic colors
  borderRadius: '100%',
  height: '19px',
  width: '19px',
  marginRight: '5px',
});

const getIconStyle = (theme: Theme) => ({
  color: '#FFFFFF',
  width: '13px',
  height: '13px'
});

const CustomCalendar = ({ list, setCurrentRange, selecEvent }) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const circleStyle = getCircleStyle(theme);
  const iconStyle = getIconStyle(theme);

  const { views } = useMemo(() => ({
    views: [Views.MONTH, Views.WEEK, Views.DAY],
  }), []);

  function CustomEvent({ event }) {
    return (
      <div className={`rbc-event-${event.type}`.toLocaleLowerCase()}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {event.type === EventType.TASK &&
            <span
              style={{ ...circleStyle, backgroundColor: '#BE123C' }}
            >
              <WarningAmberRoundedIcon sx={iconStyle} />
            </span>
          }
          {event.type === EventType.CONFERENCE &&
            <span
              style={{ ...circleStyle, backgroundColor: '#6D28D9' }}
            >
              <VideocamOutlinedIcon sx={iconStyle} />
            </span>
          }
          <span style={{ fontSize: '12px' }}>{`${getTimeFromDate(event.start)} - ${getTimeFromDate(event.end)}`}</span>
        </div>
        <div style={{ fontSize: '14px' }}>{event.title}</div>
      </div>
    );
  }

  const [view, setView] = useState<View>(() => {
    // Retrieve the stored view type
    const storedView = localStorage.getItem('calendarView');
    // Validate the view type or default to 'month'
    return (storedView === Views.MONTH ||
      storedView === Views.WEEK ||
      storedView === Views.DAY)
      ? storedView
      : Views.MONTH;
  });
  const [date, setDate] = useState<Date>(() => {
    const storedDate = localStorage.getItem('calendarDate');
    return storedDate ? new Date(storedDate) : new Date(); // Default to today
  });

  const handleViewChange = (newView: View) => {
    localStorage.setItem('calendarView', newView);
    setView(newView);
  };

  // Update the date range on navigation
  const handleNavigate = (newDate: Date, view: View) => {
    const start = getRangeStart(newDate, view);
    const end = getRangeEnd(newDate, view);

    localStorage.setItem('calendarDate', start.toISOString());
    setDate(start);
    setCurrentRange({ startDate: start, endDate: end });
  };

  // Utility functions to calculate start and end dates for the view
  const getRangeStart = (date: Date, view: View) => {
    if (view === "month") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      return firstDay;
    } else if (view === "week") {
      const firstDayOfWeek = startOfWeek(date, { weekStartsOn: 1 }); // Monday start
      return firstDayOfWeek;
    } else if (view === "day") {
      return date;
    }
    return date;
  };

  const getRangeEnd = (date: Date, view: View) => {
    if (view === "month") {
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return lastDay;
    } else if (view === "week") {
      const lastDayOfWeek = new Date(
        startOfWeek(date, { weekStartsOn: 1 }).getTime() + 6 * 24 * 60 * 60 * 1000
      );
      return lastDayOfWeek;
    } else if (view === "day") {
      return date;
    }
    return date;
  };

  const handleDrilDown = (newDate: Date) => {
    localStorage.setItem('calendarDate', newDate.toISOString());
    setDate(newDate);
    localStorage.setItem('calendarView', Views.DAY);
    setView(Views.DAY);
  };

  return (
    <Calendar
      localizer={localizer}
      culture={i18n.language}
      events={list}
      messages={{
        ...messages,
        showMore: (count) => `+${count} больше`
      }}
      startAccessor="start"
      endAccessor="end"
      style={{ minHeight: "500pt" }}
      defaultView="month"
      views={views}
      view={view}
      onView={handleViewChange}
      date={date}
      onNavigate={handleNavigate}
      onDrillDown={handleDrilDown}
      step={15}
      timeslots={4}
      onSelectEvent={selecEvent}
      components={{ event: CustomEvent }}
    />
  )
}

export { CustomCalendar };