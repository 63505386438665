import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material'
import East from '@mui/icons-material/East';

import { serviceTask } from '@services/task';
import CustomAutocomplete from '@ui/Autocomplete';
import { ITask } from '@interfaces/index';
import { serviceListener } from '@services/listener';
import { CourseContext } from '@context/CourseContext';

export const SelectTask = () => {
  const { taskId, status, classId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(["course"])
  const { common } = useTheme().palette;
  const { courseData } = useContext(CourseContext);
  const [taskList, setTaskList] = useState<Array<any>>([]);
  const [currentTask, setCurrentTask] = useState<ITask | null>(null);

  useEffect(() => {
    if (classId && taskId) {
      serviceTask.getOrderedTaskList(classId).then((res) => {
        if (res.status === 200) {
          const updatedTaskList = res.data.map((taskInfo) => {
            return {
              ...taskInfo.task,
              id: taskInfo.id,
              taskId: taskInfo.task.id,
              prevId: taskInfo.prevId,
              nextId: taskInfo.nextId,
              open: taskInfo.open
            };
          });
          setTaskList(updatedTaskList);
          setCurrentTask(updatedTaskList.find((taskInfo) => taskInfo.taskId === taskId))
        }
      })
    }
  }, [classId, taskId]);

  const navigateToTaskPage = (progressId: string) => {
    serviceListener.getTaskInfo(progressId).then((res) => {
      if (res.status === 200) navigate(`/myCourses/${status}/${classId}/tasks/${res.data.task.id}`)
    })
  }

  const isTaskOpen = (taskId) => {
    if (!courseData?.sequentialLearning) {
      return true
    }
    
    return taskList.find(task => task.id === taskId)?.open 
  }

  const disabledOptionsTask = courseData?.sequentialLearning ? taskList.filter(task => !task.open).map(task => task.id) : []

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "10px", mb: 2 }}>
      <Tooltip
        title={t('course:YOU_MUST_COMPLETE_ALL_TASKS_IN_CURRENT_TOPIC')}
        disableHoverListener={isTaskOpen(currentTask?.prevId)}
        componentsProps={{
          tooltip: { sx: { background: "#1A1F36", color: '#F9F9F9', p: '10px', fontSize: '12px' } }
        }}
        placement="bottom"
      >
        <span>
          <IconButton sx={{ backgroundColor: 'white' }} disabled={!currentTask?.prevId || !isTaskOpen(currentTask?.prevId)}
            onClick={() => navigateToTaskPage(currentTask?.prevId || '')}
          >
            <East sx={{ color: (!currentTask?.prevId || !isTaskOpen(currentTask?.prevId)) ? "#C1C1C1" : common.primaryColor, fontSize: '28px', rotate: "180deg" }} />
          </IconButton>
        </span>
      </Tooltip>
      
      <CustomAutocomplete
        name="taskId"
        valueAsObject
        value={currentTask}
        options={taskList}
        clearable={false}
        customIconDisabled={false}
        disabledOptions={disabledOptionsTask}
        onChange={(_: any, task) => navigate(`/myCourses/${status}/${classId}/tasks/${task.taskId}`)}
      />

      <Tooltip
        title={t('course:YOU_MUST_COMPLETE_ALL_TASKS_IN_CURRENT_TOPIC')}
        disableHoverListener={isTaskOpen(currentTask?.nextId)}
        componentsProps={{
          tooltip: { sx: { background: "#1A1F36", color: '#F9F9F9', p: '10px', fontSize: '12px' } }
        }}
        placement="bottom"
      >
        <span>
          <IconButton sx={{ backgroundColor: 'white' }} disabled={!currentTask?.nextId || !isTaskOpen(currentTask?.nextId)}
            onClick={() => navigateToTaskPage(currentTask?.nextId || '')}
          >
            <East sx={{ color: (!currentTask?.nextId || !isTaskOpen(currentTask?.nextId)) ? "#C1C1C1" : common.primaryColor, fontSize: '28px' }} />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  )
}
