import axios from "@utils/axios";
import { CourseAdditionalResource } from "@models/course-additional-resource";
import {
  IMaterial,
  ICourse,
  ISection,
  ITheme,
  ICourseSetting,
  IStudyClass,
} from "@interfaces/index";
import { rtkApi } from "@store/rtkApi";
import { conferenceForm } from "@pages/myCourses/Educator/TabList/Conferences/model";
import { QuizDraft } from "@pages/bankOfTests/models";

export const serviceCourse = {
  // Course services
  getCourseList: (reqBody: any, type: string) =>
    axios.post(`/api/course/info/educator/my-courses/${type}/findAll`, reqBody),
  getCourseListForDropdown: (queryParams = {}) =>
    axios.get(`/api/course/info/findAll`, { params: queryParams }),
  getCourseInfo: (id: string) => axios.get(`/api/course/info/${id}`),
  createCourse: (reqBody: ICourse) =>
    axios.post("/api/course/info/create", reqBody),
  updateCourse: (id: string, reqBody: ICourse) =>
    axios.post(`/api/course/info/update?courseId=${id}`, reqBody),
  deleteCourse: (id: string) =>
    axios.delete(`/api/course/info/delete?courseId=${id}`),
  getGradingSystemId: (courseId: string) =>
    axios.get(`/api/course/info/grading-system-id/${courseId}`),
  putCoverImage: (courseId: string, coverFileId?: string) =>
    axios.put(
      `/api/course/info/update/cover-file?courseId=${courseId}${
        coverFileId ? `&coverFileId=${coverFileId}` : ""
      }`
    ),
  changeCourseStatus: (id: string, status: string) =>
    axios.put(`/api/course/info/change-status?courseId=${id}&status=${status}`),
  closeCourseRegistration: (id: string) =>
    axios.get(`/api/course/info/close-registration?courseId=${id}`),
  closeCourse: (id: string, reason?: string) =>
    axios.put(
      `/api/course/info/close?courseId=${id}${
        reason ? `&reason=${reason}` : ""
      }`
    ),
  checkUnpublishCourseAction: (courseId: string) =>
    axios.get(
      `/api/course/info/check-action/withdrawn-course?courseId=${courseId}`
    ),
  checkCloseCourseAction: (courseId: string) =>
    axios.get(
      `/api/course/info/check-action/close-course?courseId=${courseId}`
    ),
  getCourseSetting: (id: string) =>
    axios.get(`/api/course/course-setting/${id}`),
  updateCourseSetting: (id: string, reqBody: ICourseSetting) =>
    axios.put(`/api/course/course-setting/update/${id}`, reqBody),
  getCourseDataByClassId: (classId: string) =>
    axios.get(`/api/course/student/my-course/${classId}`),

  //Courses admin page
  getCourses: (reqBody: any, courseType: string) =>
    axios.post(
      `/api/course/info/observer/all-courses/${courseType}/findAll`,
      reqBody
    ),
  getSumOfCourses: () => axios.get(`/api/course/info/sum`),
  // Courses Catalog
  getAllCoursesInCatalog: (reqBody: any) =>
    axios.post(`/api/course/student/catalog/findAll`, reqBody),
  getCourseInfoInCatalogById: (id: string) =>
    axios.get(`/api/course/student/catalog/${id}`),
  registerToCourse: (courseId: string) =>
    axios.post(
      `/api/course/student/catalog/member/create?courseId=${courseId}`
    ),
  signUpToCourse: (classId: string) =>
    axios.post(`/api/course/student/member/signup/${classId}`),
  cancelCourseRegistration: (courseId: string) =>
    axios.get(
      `/api/course/student/my-course/planned/cancel-course/${courseId}`
    ),
  cancelClassRegistration: (classId: string) =>
    axios.get(`/api/course/student/member/cancel-class/${classId}`),
  // Content services
  getContentList: (filter: { search: string }, id: string) =>
    axios.post(
      `/api/course/info/content-structure/findAll?courseId=${id}`,
      filter
    ),
  getListenerContentList: (reqBody: {
    name: string;
    courseId: string;
    classId: string;
  }) =>
    axios.post(
      `/api/course/student/my-course/content-structure/findAll`,
      reqBody
    ),
  getContentSectionInfo: (sectionId: string) =>
    axios.get(`/api/course/content-section/info/${sectionId}`),
  findCoursesByAuthor: (bodyData: any) =>
    axios.post(`/api/course/info/courses/author`, bodyData),

  updateContentOrder: (reqBody: {
    selectedItemId: string;
    afterIndex: number;
  }) => axios.put(`/api/course/info/content-structure/change-index`, reqBody),
  // Section services
  createSection: (reqBody: ISection, courseId: string) =>
    axios.post(
      `/api/course/content-section/create?courseId=${courseId}`,
      reqBody
    ),
  getSectionInfo: (sectionId: string) =>
    axios.get(`/api/course/content-section/${sectionId}`),
  updateSection: (sectionId: string, courseId: string, reqBody: ISection) =>
    axios.put(
      `/api/course/content-section/update/${sectionId}?courseId=${courseId}`,
      reqBody
    ),
  getSectionList: (courseId: string) =>
    axios.get(`/api/course/content-section/${courseId}/findAll`),
  deleteSection: (sectionId: string, courseId: string) =>
    axios.delete(
      `/api/course/content-section/${sectionId}?courseId=${courseId}`
    ),
  // Theme services
  createTheme: (reqBody: ITheme, courseId: string) =>
    axios.post(`/api/course/theme/create?courseId=${courseId}`, reqBody),
  updateTheme: (themeId: string, courseId: string, reqBody: ITheme) =>
    axios.put(
      `/api/course/theme/update/${themeId}?courseId=${courseId}`,
      reqBody
    ),
  getThemeInfo: (themeId: string) => axios.get(`/api/course/theme/${themeId}`),
  getThemeMaterials: (themeId: string) =>
    axios.get(`/api/course/theme/material/${themeId}/findAll`),
  getOrderedThemeList: (courseId: string | undefined) =>
    axios.get(`/api/course/theme/ordered/${courseId}/findAll`),
  getOrderedThemeAllowedList: (classId: string | undefined) =>
    axios.get(`/api/course/theme/ordered/${classId}/find-allowed`),
  deleteTheme: (themeId: string, courseId: string) =>
    axios.delete(`/api/course/theme/${themeId}?courseId=${courseId}`),
  getThemeList: (courseId: string | undefined) =>
    axios.get(`/api/course/theme/${courseId}/findAll`),
  putThemeMaterial: (reqBody: IMaterial, courseId: string) =>
    axios.put(`/api/course/theme/material?courseId=${courseId}`, reqBody),
  deleteThemeMaterial: (materialId: string | number, courseId: string) =>
    axios.delete(
      `/api/course/theme/material/${materialId}?courseId=${courseId}`
    ),
  changeThemeStatus: (themeId: string, courseId: string) =>
    axios.put(
      `/api/course/theme/${themeId}/change-status?courseId=${courseId}`
    ),
  getSelfTestQuiz: (themeId: string) =>
    axios.get(`/api/course/task/theme-quiz/${themeId}/findOne`),
  getAllTasksInTheme: (themeId: string) =>
    axios.get(`/api/course/student/theme/ordered/${themeId}/findAll`),
  getTasksSummary: (courseId: string) =>
    axios.get(`/api/course/info/tasks-and-groups-summary?courseId=${courseId}`),
  getThemeRecipientsParameters: (classId: string, themeId: string) =>
    axios.get(
      `/api/course/theme-recipient/find?classId=${classId}&themeId=${themeId}`
    ),
  changeThemeRecipientsParameters: (reqBody: any) =>
    axios.post(`/api/course/theme-recipient/save`, reqBody),
  // Additional resource services
  getResourceList: (courseId: string) =>
    axios.get(`/api/course/additional-resource/${courseId}/findAll`),
  createResource: (reqBody: CourseAdditionalResource, courseId: string) =>
    axios.post(
      `/api/course/additional-resource/create?courseId=${courseId}`,
      reqBody
    ),
  updateResource: (reqBody: CourseAdditionalResource, courseId: string) =>
    axios.put(
      `/api/course/additional-resource/update/${reqBody.id}?courseId=${courseId}`,
      reqBody
    ),
  deleteResource: (resourceId: string | number, courseId: string) =>
    axios.delete(
      `/api/course/additional-resource/${resourceId}?courseId=${courseId}`
    ),
  changeResourceStatus: (resourceId: string | number, courseId: string) =>
    axios.put(
      `/api/course/additional-resource/change-status?id=${resourceId}&courseId=${courseId}`
    ),
  //Listener current
  getListenerCurrent: (courseId: string) =>
    axios.get(`/api/course/result/study-class/${courseId}`),
  //Important events
  getImportantEvents: (courseId: string) =>
    axios.get(`/api/course/important-events/${courseId}/findAll`),
  //Conference
  getMeetingAll: (courseId: string, status: any) =>
    axios.get(`/api/course/meeting/${courseId}/findAll?status=${status}`),
  //Note services
  createNote: (reqBody: any) =>
    axios.post("/api/course/student/note/create", reqBody),
  updateNote: (reqBody: any) =>
    axios.put("/api/course/student/note/update", reqBody),
  getNoteList: (classId: string) =>
    axios.get(`/api/course/student/note/${classId}/findAll`),
  deleteNote: (noteId: string) =>
    axios.delete(`/api/course/student/note/${noteId}`),
  getCourseMe: (courseId: string) =>
    axios.get(`/api/course/member/short/me?classId=${courseId}`),
  //attendance
  getAttendanceList: (reqBody: any) =>
    axios.post(`/api/course/attendance/listener/findAll`, reqBody),
  crateAttendance: (reqBody: any) =>
    axios.post(`/api/course/attendance/create`, reqBody),
  getAttendanceDateList: (
    courseId: string,
    startDate: string,
    endDate: string
  ) =>
    axios.get(
      `/api/course/attendance/get-dates/${courseId}?startDate=${startDate}&endDate=${endDate}`
    ),
  getAttendanceByDate: (reqBody: any) =>
    axios.post(`/api/course/attendance/find-records`, reqBody),
  getSubjectList: (courseId: string, date: string) =>
    axios.get(`/api/course/attendance/find-by-date/${courseId}?date=${date}`),
  deleteSubjectById: (id: string) =>
    axios.delete(`/api/course/attendance/${id}`),
  deleteAttendanceList: (reqBody: any) =>
    axios.delete(`/api/course/attendance/delete/list`, { data: reqBody }),
  updateDayAttendance: (date: string, reqBody: any) =>
    axios.put(`/api/course/attendance/update/date?date=${date}`, reqBody),
  updateSubjectAttendance: (reqBody: any) =>
    axios.put(`/api/course/attendance/update/list`, reqBody),
  updateStatusAttendance: (reqBody: any) =>
    axios.put(`/api/course/attendance/short-update-record`, reqBody),
  updateTitleAttendance: (reqBody: any) =>
    axios.put(`/api/course/attendance/short-update`, reqBody),
  updateOtherMarkAttendance: (id: string, status: any) =>
    axios.put(`/api/course/attendance/update-records/${id}?status=${status}`),
  updateMarkAttendance: (reqBody: any, status: string) =>
    axios.put(
      `/api/course/attendance/update-records-status?attendanceStatusENUM=${status}`,
      reqBody
    ),
  exportAttendanceToExcel: (courseId: string) =>
    axios.get(`/api/course/attendance/export/${courseId}`, {
      responseType: "blob",
    }),
  //restricted course
  checkStudentHasRestrictedCourses: () =>
    axios.get("/api/course/student/my-course/has-limited"),
  getRestrictedCourses: (reqBody: any) =>
    axios.post("/api/course/student/my-course/limited/findAll", reqBody),
  //meeting
  getMeetingPlatform: () => axios.get("/api/course/meeting/platform/findAll"),
  createMeeting: (reqBody: any) =>
    axios.post("/api/course/meeting/create", reqBody),
  getMeetingByStatus: (courseId: string, status: string) =>
    axios.get(`/api/course/meeting/${courseId}/findAll?status=${status}`),
  getMyMeetingByStatus: (courseId: string, status: string) =>
    axios.get(`/api/course/meeting/${courseId}/find-all-my?status=${status}`),
  deleteMeeting: (id: string) => axios.delete(`/api/course/meeting/${id}`),
  getMeetingById: (id: string) => axios.get(`/api/course/meeting/${id}`),
  updateMeeting: (id: string, reqBody: any) =>
    axios.put(`/api/course/meeting/update/${id}`, reqBody),
  getMeetingTasks: (id: string) =>
    axios.get(`api/course/meeting/tasks?classId=${id}`),
  //journal
  getJournalTasks: (courseId: string) =>
    axios.get(`/api/course/journal/tasks/${courseId}`),
  getJournalLists: (reqBody: any) =>
    axios.post("/api/course/journal/find-all", reqBody),
  putTaskPoint: (progressId: string, point: number) =>
    axios.put(
      `/api/course/task-progress/update/point?progressId=${progressId}&point=${point}`
    ),
  exportJournalToExcel: (courseId: string) =>
    axios.get(`/api/course/journal/export/${courseId}`, {
      responseType: "blob",
    }),
  // Study Class
  getAllStudyClassesByCourseId: (courseId: string) =>
    axios.get(`/api/course/study-class/my-course/${courseId}`),
  getStudyClassById: (flowId: string) =>
    axios.get(`/api/course/study-class/${flowId}`),
  createStudyClass: (reqBody: any) =>
    axios.post("/api/course/study-class/create", reqBody),
  updateStudyClass: (reqBody: any) =>
    axios.post("/api/course/study-class/update", reqBody),
  deleteStudyClass: (flowId: string) =>
    axios.delete(`/api/course/study-class/${flowId}`),
  updateStudyClassStatus: (flowId: string, status: string) =>
    axios.post(
      `/api/course/study-class/update-status/?classId=${flowId}&status=${status}`
    ),
  completeStudyClassRegistration: (flowId: string, regEndDate: any) =>
    axios.put(
      `/api/course/study-class/complete-registration?classId=${flowId}&&regEndDate=${regEndDate}`
    ),
  completeCourseByListener: (classId: string) =>
    axios.post(`/api/course/student/my-course/complete/study-class/${classId}`),
  // Task services
  getTaskRecipientsParameters: (classId: string, taskId: string) =>
    axios.get(
      `/api/course/class-task-info/find?classId=${classId}&taskId=${taskId}`
    ),
  changeTaskRecipientsParameters: (reqBody: any) =>
    axios.post(`/api/course/class-task-info/save`, reqBody),
  // Course author
  getCourseAuthors: (courseId: string) =>
    axios.get(`/api/course/author/find/${courseId}`),
  updateStatusAuthor: (id: string, confirm: boolean) =>
    axios.post(`/api/course/author/${id}/update-status?confirm=${confirm}`),
  creatAuthor: (reqBody: any) =>
    axios.post("/api/course/author/create", reqBody),
  updateAuthorInfo: (reqBody: any) =>
    axios.post("/api/course/author/update", reqBody),
  createCoAuthors: (courseId: string, reqBody: any) =>
    axios.post(
      `/api/course/author/add-coauthors?courseId=${courseId}`,
      reqBody
    ),
  //Confirmation
  getConfirmationAuthor: () =>
    axios.get("/api/course/info/on-author-confirmation"),
  getConfirmationMember: () =>
    axios.get("/api/course/info/on-member-confirmation"),
  updateConfirmationAuthor: (id: string, confirm: boolean) =>
    axios.post(`/api/course/author/${id}/update-status?confirm=${confirm}`),
  updateConfirmationMember: (classId: string, confirmed: boolean) =>
    axios.post(
      `/api/course/member/invitation/${classId}?confirmed=${confirmed}`
    ),
  getPopularCourseForAchievements: () =>
    axios.get("/api/course/student/my-course/popular"),
  getCurrentCourseForAchievements: () =>
    axios.get("/api/course/student/my-course/current"),
  // Main Page
  findPopularTopics: () =>
    axios.get(`/api/course/public/main-page/find-popular-topics`),
  findNewCourses: (reqBody: any) =>
    axios.post(`/api/course/public/main-page/new-courses`, reqBody),
  findAllFreeCourses: (reqBody: any) =>
    axios.post(`/api/course/public/main-page/courses`, reqBody),
  getStudyClassByIdPublic: (flowId: string) =>
    axios.get(`/api/course/public/main-page/find-by-study-class/${flowId}`),
  getContentStructurePublic: (courseId: string) =>
    axios.get(
      `/api/course/public/main-page/course/${courseId}/content-structure`
    ),
  getContentSummaryPublic: (courseId: string) =>
    axios.get(`/api/course/public/main-page/course-info/${courseId}`),
  getSectionSummaryPublic: (sectionId: string) =>
    axios.get(`/api/course/public/main-page/section-info/${sectionId}`),
  getCourseAuthorsPublic: (courseId: string) =>
    axios.get(`/api/course/public/main-page/course-authors/${courseId}`),
  findCoursesByAuthorPublic: (reqBody: any) =>
    axios.post(`/api/course/public/main-page/author/courses`, reqBody),
  checkCourseRegAvailable: (classId: string) =>
    axios.get(
      `/api/course/public/main-page/allow-registration/class/${classId}`
    ),
  // Limited Courses
  getHasLimitedCourse: () => axios.get("/api/course/info/has-limited"),
  // Bank of tests(quizzes)
  getQuizBankList: async (reqBody: any) => {
    const response = await axios.post(
      `/api/course/quiz/draft/findAll`,
      reqBody
    );
    return response.data;
  },
  getQuizBankListForDropdown: async (reqBody: any) =>
    axios.post(`/api/course/quiz/draft/findAll`, reqBody),
  createQuizDraft: async (reqBody: QuizDraft) => {
    const response = await axios.post("/api/course/quiz/draft/create", reqBody);
    return response.data;
  },
  updateQuizDraft: async (reqBody: QuizDraft) => {
    const response = await axios.put("/api/course/quiz/draft/update", reqBody);
    return response.data;
  },
  getQuizDraftById: (draftId: string) =>
    axios.get(`/api/course/quiz/draft/${draftId}`),
  deleteQuizDraft: (id: string) => axios.delete(`/api/course/quiz/draft/${id}`),
  getUserCertificatesList: (userId: any) =>
    axios.get(`/api/course/certificate/listener/${userId}`),
  getUserCertificate: (certId: any) =>
    axios.get(`/api/course/certificate/${certId}`),
  getOrgCertificate: (orgId: any) =>
    axios.get(`/api/course/certificate/template/${orgId}`),
  createOrgCertificate: (reqBody: any, config = {}) =>
    axios.post(`/api/course/certificate/template`, reqBody, config),
  updateOrgCertificate: (reqBody: any, config = {}) =>
    axios.put(`/api/course/certificate/template/update`, reqBody, config),
  deleteOrgCertificate: (orgId: any) =>
    axios.delete(`/api/course/certificate/template/${orgId}`),
  downloadCertificate: (certId: any) =>
    axios.get(`/api/course/certificate/download/${certId}`, {
      responseType: "arraybuffer",
    }),
  getPublicUserCertificate: (certId: any) =>
    axios.get(`/api/course/public/cert/${certId}`),
  getExternalUsersList: (reqBody: any) =>
    axios.post(`/api/course/member/external-listeners`, reqBody),
  getShortInfoCourse: (id) => axios.get(`/api/course/info/${id}/shortInfo`),
  getCurrentStudyClass: (id) =>
    axios.get(`/api/course/study-class/current?courseId=${id}`),
  // StudyPlan
  getCoursePlanInfo: (id: string) =>
    axios.get(`/api/course/study-plan/find-all?courseId=${id}`),
  saveCourseDefaultPlan: (id: string) =>
    axios.post(`/api/course/study-plan/default?courseId=${id}`),
  saveCourseStudyPlan: (reqBody: any) =>
    axios.post(`/api/course/study-plan`, reqBody),
  deleteCourseStudyPlan: (id: string) =>
    axios.delete(`/api/course/study-plan?courseId=${id}`),
  createUpdateSectionStudyPlan: (reqBody: any) =>
    axios.post(`/api/course/study-plan/content`, reqBody),
  getThemeVideoInfo: (reqBody: any) => 
    axios.post(`/api/course/theme/getVideoInfo`, reqBody),
  updateThemeVideoInfo: (reqBody: any) => 
    axios.put(`/api/course/theme/saveVideoInfo`, reqBody),
  getContentListStudyPlanListener: (reqBody: {
    name: string;
    courseId: string;
    classId: string;
  }) => axios.post(`/api/course/student/my-course/study-plan/findAll`, reqBody),
};

export const serviceCourseApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getCourseInfo: build.query<ICourse, string>({
      query: (id) => ({
        url: `/course/info/${id}`,
      }),
    }),
    getAllStudyClassesByCourseId: build.query<IStudyClass[], string>({
      query: (id) => ({
        url: `course/study-class/my-course/${id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getMyMeetingByStatus: build.query<
      Array<conferenceForm>,
      { courseId: string; status: string }
    >({
      query: ({ courseId, status }) => ({
        url: `course/meeting/${courseId}/find-all-my?status=${status}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Meeting" as const, id })),
              { type: "Meeting", id: "LIST" },
            ]
          : [{ type: "Meeting", id: "LIST" }],
    }),
    getMeetingTasks: build.query<Array<any>, string>({
      query: (id) => ({
        url: `course/meeting/tasks?classId=${id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getOrderedThemeList: build.query<Array<any>, string>({
      query: (courseId) => ({
        url: `course/theme/ordered/${courseId}/findAll`,
      }),
    }),
    deleteMeeting: build.mutation<void, string>({
      query: (id) => ({
        url: `course/meeting/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Meeting", id }],
    }),
  }),
});

export const {
  useGetCourseInfoQuery,
  useGetAllStudyClassesByCourseIdQuery,
  useGetMyMeetingByStatusQuery,
  useGetMeetingTasksQuery,
  useGetOrderedThemeListQuery,
  useDeleteMeetingMutation,
} = serviceCourseApi;
