import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { serviceCourse } from '@services/course';
import { HintComponent } from '@components/common';
import { IThemeVideoInfo } from '@interfaces/index';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const target = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_PRODUCTION_TARGET
  : process.env.REACT_APP_DEVELOPMENT_TARGET;

const SequentialVideo = ({ videoFileId, isPublic = false, height = 400, width = '100%' }) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course"])
  const { themeId } = useParams();
  const profile = useSelector((store: any) => store.account.profileInfo);
  const videoRef = useRef<any>(null);
  const [lastAllowedTime, setLastAllowedTime] = useState(1);
  const [videoInfo, setVideoInfo] = useState<IThemeVideoInfo | null>(null);
  const [isPlay, setIsPlay] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messagePosition, setMessagePosition] = useState(0);

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (video.currentTime && video.currentTime > lastAllowedTime) {
      const seekingTime = video.currentTime;
      const positionPercent = (seekingTime / video.duration) * 100;

      setMessagePosition(positionPercent);
      setShowMessage(true);
      video.currentTime = lastAllowedTime; // блокируем перемотку вперед
      setTimeout(() => setShowMessage(false), 3000);
    } else {
      const maxTime = Math.max(videoInfo?.watchedTime ?? 0, video.currentTime)
      setLastAllowedTime(maxTime + 1); // обновляем разрешённую позицию
    }
  };

  const updateVideoInfoHandler = useCallback((watchedTime, duration) => {
    const data = {
      themeId,
      videoId: videoFileId,
      userId: profile?.pltaId,
      watchedTime,
      duration,
      date: new Date()
    }
    serviceCourse.updateThemeVideoInfo(data).then(res => {
      if (res.status === 200) setVideoInfo(res.data) 
    })
  }, [videoFileId, profile?.pltaId, themeId])

  useEffect(() => {
    let videoInterval;
    const video = videoRef.current
    if (!videoInfo?.watched && isPlay) {
      videoInterval = setInterval(() => {
        updateVideoInfoHandler(video?.currentTime, video?.duration)
      }, 10000)
    }

    return () => {
      if(videoInterval) clearInterval(videoInterval)
    }
  }, [updateVideoInfoHandler, videoInfo?.watched, isPlay])

  useEffect(() => {
    if (videoFileId) {
      const data = {
        themeId,
        videoId: videoFileId,
        userId: profile?.pltaId,
      }
      serviceCourse.getThemeVideoInfo(data).then(res => {
        if (res.status === 200) {
          setVideoInfo(res.data)
          if (!res.data.watched && res.data.watchedTime) {
            videoRef.current.currentTime = res.data.watchedTime
          }
          res.data.watchedTime && setLastAllowedTime(res.data?.watchedTime)
        }
      })
    }
    
  }, [videoFileId, themeId, profile?.pltaId])

  return (
    <>
      {
        videoInfo?.watched ?
        <HintComponent 
          text={t('course:YOU_HAVE_SUCCESSFULLY_STUDIEND_VIDEO')}
          textColor='#00B998'
          leftBorder
          backgroundBorder='#00B998'
          svg={
            <Box
              width={"32px"}
              height={"32px"}
              sx={{ borderRadius: "50%", background: "#ccf1ea" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CheckCircleIcon
                sx={{ color: "#00B998", width: "20px", height: "20px" }}
              />
            </Box>
          }
        />
        : 
        <HintComponent 
          text={t('course:TOPIC_WILL_BE_COMPLETED_AFTER_WATCHING_VIDEO')}
          textColor='#9D3FE7'
          leftBorder
          backgroundBorder='#9D3FE7'
          svg={
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                backgroundColor: '#ebd9fa',
                padding: "10px",
              }}
            >
              <ErrorIcon sx={{ color: "#9D3FE7", width: "20px" }}/>
            </Box>
          }
        />
      }
      <div style={{position: 'relative'}}>
        <video
          id={videoFileId}
          width={width}
          height={height}
          controls
          preload="metadata"
          controlsList="nodownload" // This will prevent the download option
          style={{
            border: `1px solid ${common.strokePrimary}`,
            borderRadius: '6px'
          }}
          onTimeUpdate={handleTimeUpdate}
          onPlay={() => setIsPlay(true)}
          onPause={() => setIsPlay(false)}
          onEnded={() => updateVideoInfoHandler(videoRef.current.currentTime, videoRef.current.duration)}
          ref={videoRef}
        >
          <source src={`${target}/video-streaming${isPublic ? '/public' : ''}/video/${videoFileId}`} />
        </video>
        {showMessage && (
          <div
            style={{
              position: 'absolute',
              width: '25%',
              bottom: '10%', // Расположение сообщения над видео
              left: `${messagePosition}%`, // Позиция сообщения
              transform: 'translateX(-50%)',
              backgroundColor: '#1A1F36',
              color: '#F9F9F9',
              padding: '5px 10px',
              borderRadius: '8px',
              fontSize: '12px'
            }}
          >
            {t('course:REWINDING_IS_DISABLED')}
          </div>
        )}
      </div>
    </>
  );
};

export default SequentialVideo;