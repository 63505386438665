export const settings = [
  {
    value: 'general',
    step: 1,
    text: 'GENERAL',
  },
  {
    value: 'trainingCourse',
    step: 2,
    text: 'TRAINING_COURSE',
  },
  {
    value: 'freeCourse',
    step: 3,
    text: 'FREE_COURSE',
  }
]
// export const directory = [
//   {
//     value: 'general',
//     step: 1,
//     text: 'GENERAL'
//   },
//   {
//     value: 'additionalSettings',
//     step: 2,
//     text: 'ADDITIONAL_SETTINGS'
//   }
// ]
export const courseTypes = [
  {
    id: 1,
    text: "EDU",
  },
  {
    id: 2,
    text: "FREE",
  },
];

export const itemStatusTypes = [
  {
    id: false,
    text: "IS_USED",
  },
  {
    id: true,
    text: "NOT_USED",
  },
];

export const courseFormats = [
  {
    id: 'SYNC',
    text: 'SYNC',
  },
  {
    id: 'ASYNC',
    text: 'ASYNC',
  }
];

export const courseDurations = [
  {
    id: 'INDEFINITE',
    text: 'COURSE_DURATION_INDEFINITE',
  },
  {
    id: 'PERIOD',
    text: 'COURSE_DURATION_PERIOD',
  }
];

export const contentAccessExpirationTypes = [
  {
    id: "IMMEDIATELY_AFTER_COMPLETION",
    text: "IMMEDIATELY_AFTER_COMPLETION"
  },
  {
    id: "ONE_WEEK_AFTER_COMPLETION",
    text: "ONE_WEEK_AFTER_COMPLETION"
  },
  {
    id: "ONE_MONTH_AFTER_COMPLETION",
    text: "ONE_MONTH_AFTER_COMPLETION"
  },
  {
    id: "THREE_MONTHS_AFTER_COMPLETION",
    text: "THREE_MONTHS_AFTER_COMPLETION"
  }
]

export const gradeDisplayMethods = [
  {
    id: "AUTOMATIC",
    text: "SHOW_FINAL_GRADES"
  },
  {
    id: "MANUAL",
    text: "HIDE_FINAL_GRADES"
  }
];

export const dictionaryList = [
  {
    dictCode: "BRANCH",
    url: "/dict/organizations",
    text: "ORGANIZATIONS"
  },
  {
    dictCode: "CATEGORY",
    url: "/dict/categories",
    text: "CATEGORIES"
  },
  {
    dictCode: "COURSE_DIFFICULTY_LEVEL",
    url: '/dict/list/COURSE_DIFFICULTY_LEVEL',
    text: "COURSE_DIFFICULTY_LEVEL"
  },
  {
    dictCode: "LANGUAGE",
    url: '/dict/list/LANGUAGE',
    text: "LANGUAGE"
  },
  {
    dictCode: "FILE_TYPE",
    url: '/dict/list/FILE_TYPE',
    text: "FILE_TYPE"
  }
];


export const recipientTypes = [
  {
    id: "ALL",
    text: "FOR_ALL_LISTENERS"
  },
  {
    id: "LISTENER",
    text: "LISTENER",
  },
  {
    id: "GROUP",
    text: "GROUP_OF_LISTENERS"
  },
];

export const participantRoles = [
  {
    id: "LISTENER",
    text: "LISTENER"
  },
  {
    id: "TUTOR",
    text: "TUTOR"
  },
  {
    id: "CURATOR",
    text: "CURATOR"
  },
  {
    id: "ASSISTANT",
    text: "ASSISTANT"
  },
  {
    id: "EXAMINER",
    text: "EXAMINER"
  }
];

export const participantStatus = [
  {
    id: "INVITATION_SENT",
    text: "INVITATION_SENT"
  },
  {
    id: "ACTIVE",
    text: "ACTIVE"
  },
  {
    id: "BLOCKED",
    text: "BLOCKED"
  },
  {
    id: "INVITATION_REJECTED",
    text: "INVITATION_REJECTED"
  },
  {
    id: "INVITATION_WITHDRAWN",
    text: "INVITATION_WITHDRAWN"
  }
]
export const participantStatusObject = {
  INVITATION_SENT: "INVITATION_SENT",
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED",
  INVITATION_REJECTED: "INVITATION_REJECTED",
  INVITATION_WITHDRAWN: "INVITATION_WITHDRAWN"
}

export const resourceTypes = [
  {
    id: "FILE",
    text: "FILE"
  },
  {
    id: "LINK",
    text: "LINK"
  },
  {
    id: "TEXT",
    text: "TEXT"
  }
];

export const taskTypes = [
  // {
  //   id: "CONTROL",
  //   text: "CONTROL"
  // },
  {
    id: "TASK",
    text: "TASK"
  },
  {
    id: "FINAL_EXAM",
    text: "FINAL_EXAM"
  },
  {
    id: "MIDTERM_1",
    text: "MIDTERM_1"
  },
  {
    id: "MIDTERM_2",
    text: "MIDTERM_2"
  }
];

export const taskFormats = [
  {
    id: "ORAL",
    text: "ORAL"
  },
  {
    id: "WRITTEN",
    text: "WRITTEN"
  },
  {
    id: "QUIZ",
    text: "QUIZ"
  }
];

export const taskParents = [
  // {
  //   id: "COURSE",
  //   text: "BELONGS_TO_COURSE"
  // },
  {
    id: "SECTION",
    text: "BELONGS_TO_SECTION"
  },
  {
    id: "THEME",
    text: "BELONGS_TO_THEME"
  }
];

export const considerationResults = [
  {
    id: "LAST",
    text: "LAST"
  },
  {
    id: "HIGHEST",
    text: "HIGHEST"
  },
  {
    id: "LOWEST",
    text: "LOWEST"
  },
  {
    id: "AVERAGE",
    text: "AVERAGE"
  }
];

export const writtenAnswerTypes = [
  {
    id: "TEXT",
    text: "TEXT"
  },
  {
    id: "FILE",
    text: "FILE"
  },
];
export const oralAnswerTypes = [
  {
    id: "ONLINE_CONFERENCE",
    text: "ONLINE_CONFERENCE"
  },
  {
    id: "VIDEO",
    text: "VIDEO"
  },
  {
    id: "OFFLINE",
    text: "OFFLINE"
  }
];

export const contentStatuses = [
  {
    id: "SAVED",
    text: "SAVED"
  },
  {
    id: "PUBLISHED",
    text: "PUBLISHED"
  }
];

export const quizQuestionTypes = [
  "ONE_CORRECT_ANSWER",
  "MULTIPLE_CORRECT_ANSWERS",
  "MAPPING",
  "TRUE_OR_FALSE",
  "SEQUENCE"
];

export const taskStatusBgColor = {
  NOT_ASSIGNED: "",
  ASSIGNED: "#2074D6",
  STARTED: "#9469E3",
  UNDER_REVIEW: "#9469E3",
  ANTI_PLAGIARISM_CHECK: "#7427CD",
  ON_CORRECTION: "#EAC600",
  NOT_COMPLETED_ON_TIME: "#D62B20",
  NOT_ALLOWED: "#D62B20",
  COMPLETED: "#50BE1C",
};

export const statusOptions = [
  {
    id: '',
    text: "ALL"
  },
  {
    id: 'UNREAD',
    text: "UNREAD"
  },
  {
    id: 'READ',
    text: "READ"
  }
]

export const dateOptions = [
  {
    id: 'WEEK',
    text: "WEEK"
  },
  {
    id: 'MONTH',
    text: "MONTH"
  },
  {
    id: 'LAST_3_MONTH',
    text: "LAST_3_MONTH"
  },
  {
    id: 'SELECTED_PERIOD',
    text: "SELECTED_PERIOD"
  },
  {
    id: '',
    text: 'ENTIRE_PERIOD'
  },
]

export const videoTypeOptions = [
  {
    id: 'FILE',
    text: "SELECT_FILE"
  },
  {
    id: 'LINK',
    text: "PROVIDE_LINK"
  },
]

export const severityViolations = [
  { id: "EASY", text: "VIOLATIONS_EASY" },
  { id: "MEDIUM", text: "VIOLATIONS_MEDIUM" },
  { id: "HEAVY", text: "VIOLATIONS_HEAVY" }
]