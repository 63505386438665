import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material'
import East from '@mui/icons-material/East';

import { serviceCourse } from '@services/course';
import CustomAutocomplete from '@ui/Autocomplete';
import { ITheme } from '@interfaces/index';
import { CourseContext } from '@context/CourseContext';

export const SelectTheme = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["course"]) 
  const { common } = useTheme().palette;
  const { classId, themeId } = useParams();
  const { courseData } = useContext(CourseContext);
  const [themeList, setThemeList] = useState<Array<any>>([]);
  const [currentTheme, setCurrentTheme] = useState<ITheme | null>(null);

  useEffect(() => {
    if (classId) {
      serviceCourse.getOrderedThemeAllowedList(classId).then((res) => {
        if (res.status === 200) {
          setThemeList(res.data);
          setCurrentTheme(res.data.find((theme: ITheme) => theme.id === themeId));
        }
      })
    }
  }, [classId, themeId]);

  const isThemeOpen = (themeId) => {
    if (!courseData?.sequentialLearning) {
      return true
    }
    
    return themeList.find(theme => theme.id === themeId)?.open 
  }

  const disabledOptionsTheme = themeList.filter((theme) => theme.disabled || !theme.open).map((theme) => theme.id)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "10px" }}>
      <Tooltip
        title={t('course:YOU_MUST_COMPLETE_CURRENT_TOPIC')}
        disableHoverListener={isThemeOpen(currentTheme?.prevId)}
        componentsProps={{
          tooltip: { sx: { background: "#1A1F36", color: '#F9F9F9', p: '10px', fontSize: '12px' } }
        }}
        placement="bottom"
      >
        <span>
          <IconButton sx={{ backgroundColor: 'white' }} disabled={!currentTheme?.prevId || !isThemeOpen(currentTheme?.prevId)} onClick={() => navigate(`/myCourses/${classId}/theme/${currentTheme?.prevId}`)}>
            <East sx={{ color: (!currentTheme?.prevId || !isThemeOpen(currentTheme?.prevId)) ? "#C1C1C1" : common.primaryColor, fontSize: '28px', rotate: "180deg" }} />
          </IconButton>
        </span>
      </Tooltip>
      <CustomAutocomplete
        name="themeId"
        valueAsObject
        value={currentTheme}
        options={themeList}
        clearable={false}
        customIconDisabled={false}
        disabledOptions={disabledOptionsTheme}
        onChange={(_: any, theme) => navigate(`/myCourses/${classId}/theme/${theme.id}`)}
      />
      <Tooltip
        title={t('course:YOU_MUST_COMPLETE_CURRENT_TOPIC')}
        disableHoverListener={isThemeOpen(currentTheme?.nextId)}
        componentsProps={{
          tooltip: { sx: { background: "#1A1F36", color: '#F9F9F9', p: '10px', fontSize: '12px' } }
        }}
        placement="bottom"
      >
        <span>
          <IconButton sx={{ backgroundColor: 'white' }} disabled={!currentTheme?.nextId || !isThemeOpen(currentTheme?.nextId)} onClick={() => navigate(`/myCourses/${classId}/theme/${currentTheme?.nextId}`)}>
            <East sx={{ color: (!currentTheme?.nextId || !isThemeOpen(currentTheme?.nextId)) ? "#C1C1C1" : common.primaryColor, fontSize: '28px' }} />
          </IconButton>
        </span>
      </Tooltip>
      
    </Box>
  )
}