const StyledText = ({ text }) => {
  // Split the text into lines
  const lines = text.split('\n');

  return (
    <>
      {lines.map((line: string, index: number) => (
        <span key={index}>
          {line} <br />
        </span>
      ))}
    </>
  );
};

export default StyledText;