import { useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Formik, Form as FormikForm, Field } from "formik";
import { Box, Typography, FormControlLabel, Checkbox, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isEqual } from 'lodash';

import { serviceUser } from '@services/user';
import Message from '@components/Message';
import SaveChangesModal from '@components/modals/SaveChangesModal';
import FormikInput from '@ui/formik/Input';
import CustomButton from '@ui/Button';
import MaskedInput from '@ui/formik/MaskedInput';
import { regWithEmailValidationSchema, regWithPhoneNumberValidationSchema } from './validation';
import { DTO, initialDto } from '.';

const roleList = [
  { id: 1, code: 'LISTENER', text: 'LISTENER_REGO_TEXT' },
  { id: 2, code: 'EDUCATOR', text: 'EDUCATOR_REGO_TEXT' }
];

const RegistrationForm = ({ setIsRegState, initialData, setInitialData, regWithEmail, setRegWithEmail }) => {
  const { t, i18n } = useTranslation(["profile", "registration", "header"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { common } = useTheme().palette;
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const roleCode = searchParams.get("role");
  const modal = searchParams.get("modal");

  const [passwordVisibility, setPasswordVisibility] = useState<Record<string, boolean>>({
    newPass: false,
    confirmPass: false,
  });

  const togglePasswordVisibility = (fieldName: string) => {
    setPasswordVisibility((prevVisibility) => ({
      ...prevVisibility,
      [fieldName]: !prevVisibility[fieldName],
    }));
  };

  const login = () => {
    window.location.href = '/oauth2/authorization/gateway';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '550px',
        height: 'auto',
        p: '40px 60px',
        borderRadius: '24px',
        backgroundColor: "rgba(255, 255, 255, 1);",
        position: 'relative'
      }}
    >
      {modal === "rego-form" ?
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={regWithEmail ? regWithEmailValidationSchema(t) : regWithPhoneNumberValidationSchema(t)}
          onSubmit={(values: DTO) => {
            const bodyData = { ...values, roleCode };
            serviceUser.registerUser('EMAIL', bodyData).then((res) => {
              if (res.status === 200) {
                setInitialData(bodyData)
                setIsRegState(false)
              }
            }).catch((err) => {
              setMessageOpen(true);
              setMessage(t(`registration:messages.${err.response.data.code}`));
              setTimeout(() => {
                setMessageOpen(false);
              }, 5000);
            })
          }}
        >
          {({ dirty, isValid, submitForm, values }) => (
            <FormikForm>
              {isEqual(initialDto, values) &&
                <Box
                  sx={{ position: 'absolute', top: 20, left: 20, cursor: 'pointer' }}
                  onClick={() => {
                    searchParams.delete("modal");
                    setSearchParams(searchParams);
                  }}
                >
                  <ArrowBackIcon sx={{ color: '#8E8E93' }} />
                </Box>
              }
              <Typography sx={{ fontWeight: 600, fontSize: '28px', textAlign: 'center', mb: '24px' }}>
                {t(`registration:REGISTRATION_${roleCode}`)}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                {messageOpen &&
                  <Box sx={{ mb: '24px', mt: '-12px' }}>
                    <Message
                      type={'error'}
                      message={message}
                      setMessageOpen={setMessageOpen}
                    />
                  </Box>
                }
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  <FormikInput name="firstName" label={t('profile:NAME')} placeholder={t('registration:PLACEHOLDER_NAME')} borderRadius='8px' height='45px' />
                  <FormikInput name="lastName" label={t('profile:SURNAME')} placeholder={t('registration:PLACEHOLDER_SURNAME')} borderRadius='8px' height='45px' />
                </Box>
                <Box sx={{ position: 'relative' }}>
                  {regWithEmail
                    ? <FormikInput
                      name="email"
                      type='text'
                      label="E-mail"
                      placeholder={t('registration:PLACEHOLDER_EMAIL')}
                      adornmentIcon={<EmailOutlinedIcon sx={{ color: '#8E8E93', fontSize: '20px' }} />}
                      borderRadius='8px'
                      height='45px' />
                    : <MaskedInput
                      name='phone'
                      type='cellphone'
                      label={t('profile:PHONE')}
                      placeholder='+7 (_ _ _) _ _ _-_ _-_ _'
                      adornmentIcon={<PhoneIphoneIcon sx={{ color: '#8E8E93', fontSize: '20px' }} />}
                      borderRadius='8px'
                      height='45px'
                    />
                  }
                  {/* <Box sx={{ position: 'absolute', top: 0, right: 0, color: '#8E8E93', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => {
                      setRegWithEmail(!regWithEmail)
                      if (regWithEmail) {
                        setFieldValue('email', null)
                      } else {
                        setFieldValue('phone', null)
                      }
                    }}>
                    {regWithEmail ? <PhoneIphoneIcon sx={{ fontSize: '18px' }} /> : <EmailOutlinedIcon sx={{ fontSize: '18px' }} />}
                    <Typography sx={{ fontSize: '14px', ml: '4px' }}>{regWithEmail ? t('registration:VIA_PHONE_NUMBER') : t('registration:VIA_EMAIL')}</Typography>
                  </Box> */}
                </Box>
                <FormikInput
                  name='password'
                  label={t('profile:PASS')}
                  placeholder={t('profile:PLACEHOLDER_PASS')}
                  hintText={
                    `Пароль должен содержать хотя бы:\n
                    Одну заглавную букву (A–Z).\n
                    Одну строчную букву (a–z).\n
                    Одну цифру (0–9).`
                  }
                  adornmentIcon={!passwordVisibility.newPass ?
                    <VisibilityOutlinedIcon sx={{ color: '#8E8E93', fontSize: '20px' }} /> :
                    <VisibilityOffOutlinedIcon sx={{ color: '#8E8E93', fontSize: '20px' }} />
                  }
                  type={passwordVisibility.newPass ? 'text' : 'password'}
                  onAdornmentClick={() => togglePasswordVisibility('newPass')}
                  borderRadius='8px'
                  height='45px'
                />
                <FormikInput
                  name='repeatPassword'
                  label={t('registration:PASSWORD_CONFIRMATION')}
                  placeholder={t('profile:PLACEHOLDER_PASS')}
                  adornmentIcon={!passwordVisibility.confirmPass ?
                    <VisibilityOutlinedIcon sx={{ color: '#8E8E93', fontSize: '20px' }} /> :
                    <VisibilityOffOutlinedIcon sx={{ color: '#8E8E93', fontSize: '20px' }} />
                  }
                  type={passwordVisibility.confirmPass ? 'text' : 'password'}
                  onAdornmentClick={() => togglePasswordVisibility('confirmPass')}
                  borderRadius='8px'
                  height='45px'
                />
                <Field
                  name="privacyPolicy"
                  type="checkbox">
                  {({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '24px',
                          color: common.primaryColor,
                          borderRadius: '32px'
                        }
                      }} />}
                      label={
                        <Typography sx={{ color: '#8E8E93', fontSize: '14px' }}>
                          {i18n.language === 'ru' ?
                            <>
                              Подтверждаю согласие с{' '}
                              <RouterLink to="/terms-of-use" target="_blank" style={{ color: common.primaryColor, textDecoration: 'underline' }}>
                                условиями использования и политикой конфиденциальности
                              </RouterLink>
                            </> : i18n.language === 'kz' ? <>
                              Мен <RouterLink to="/terms-of-use" target="_blank" style={{ color: common.primaryColor, textDecoration: 'underline' }}>
                                пайдалану шарттарымен және құпиялылық саясатымен
                              </RouterLink>{' '}келісемін
                            </> : <>I agree to the{' '}
                              <RouterLink to="/terms-of-use" target="_blank" style={{ color: common.primaryColor, textDecoration: 'underline' }}>
                                terms of use and privacy policy
                              </RouterLink>
                            </>
                          }

                        </Typography>
                      }
                    />
                  )}
                </Field>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  fontColor={common.fontWhite}
                  borderRadius="6px"
                  height="55px"
                  disabled={!isValid}
                  onClick={submitForm}
                >
                  {t("registration:REGISTER")}
                </CustomButton>
              </Box>
              <SaveChangesModal dirty={dirty} />
            </FormikForm>
          )}
        </Formik> :
        <Box>
          <Typography sx={{ fontWeight: 600, fontSize: '28px', textAlign: 'center', mb: '24px' }}>
            {t("profile:CHOOSE_SYSTEM_ROLE")}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {roleList.map(role => {
              return (
                <Box
                  key={role.id}
                  sx={{
                    display: 'flex',
                    borderRadius: '8px',
                    backgroundColor: roleCode === role.code ? "#FBF9FF" : "#FFFFFF",
                    border: `1px solid ${roleCode === role.code ? common.primaryColor : common.strokeSecondary}`,
                    p: 2,
                    cursor: 'pointer',
                    "&:hover": {
                      backgroundColor: "#FBF9FF",
                      border: `1px solid ${common.primaryColor}`
                    }
                  }}
                  onClick={() => {
                    searchParams.set("role", role.code);
                    setSearchParams(searchParams);
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
                      {t(`profile:roles.${role.code}`)}
                    </Typography>
                    <Typography>
                      {t(`registration:${role.text}`)}
                    </Typography>
                  </Box>
                  <Avatar
                    alt={`Rego ${role.code}`}
                    src={require(`@assets/images/rego_${role.code.toLowerCase()}.png`)}
                    variant="square"
                    sx={{
                      height: "80px",
                      width: "80px"
                    }}
                  />
                </Box>
              )
            })}
            <CustomButton
              backgroundColor={common.primaryColor}
              fontColor={common.fontWhite}
              borderRadius="6px"
              height="55px"
              disabled={!roleCode}
              onClick={() => {
                searchParams.set("modal", "rego-form");
                setSearchParams(searchParams);
              }}
            >
              {t("registration:CONTINUE")}
            </CustomButton>
          </Box>
        </Box>
      }
      <Typography sx={{ textAlign: 'center', mt: '24px' }}>
        {t('registration:ALREADY_HAVE_AN_ACCOUNT')}
        <span onClick={() => login()} style={{ color: common.primaryColor, textDecoration: 'underline', cursor: 'pointer', marginLeft: '4px' }}>{t('header:LOGIN')}</span>
      </Typography>
    </Box>
  )
}

export default RegistrationForm;
