import { useState, useEffect, useContext, ChangeEvent } from 'react'
import { useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme, Checkbox, TextField, useMediaQuery } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import TuneIcon from '@mui/icons-material/Tune';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { serviceTask } from '@services/task';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import { IBreadcrumb, ITasksList, Role, CourseStatus, TypeCode } from '@interfaces/index';
import { formatDateWithHourMinute } from '@utils/functions';
import CustomAutocomplete from '@ui/Autocomplete';
import { IFilter, initialFilter } from './models';
import { taskTypes, taskFormats } from '@utils/enums';
import { CourseContext } from "@context/CourseContext";

const taskStatusOptions = [
  {
    id: "ASSIGNED",
    text: "ASSIGNED"
  },
  {
    id: "ANTI_PLAGIARISM_CHECK",
    text: "ANTI_PLAGIARISM_CHECK"
  },
  {
    id: "UNDER_REVIEW",
    text: "UNDER_REVIEW"
  },
  {
    id: "ON_CORRECTION",
    text: "ON_CORRECTION"
  },
  {
    id: "COMPLETED",
    text: "COMPLETED"
  },
  {
    id: "NOT_COMPLETED_ON_TIME",
    text: "NOT_COMPLETED_ON_TIME"
  }
];

const formatText = {
  ORAL: "Устно",
  WRITTEN: "Письменно",
  QUIZ: "Тест",
};

export const statusBgColor = {
  ASSIGNED: "#2074D6",
  ANTI_PLAGIARISM_CHECK: "#7427CD",
  UNDER_REVIEW: "#9469E3",
  ON_CORRECTION: "#2074D6",
  COMPLETED: "#88E85B",
  PASSED: "#88E85B",
  NOT_COMPLETED_ON_TIME: "#D62B20",
  NOT_PASSED: "#D62B20",
  STARTED: "#9469E3"
};

export const TaskCurrentCourses = () => {
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const { classId, courseId } = useParams<{ classId: string, courseId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation(["user", "common", "enum", "course", "dictionary", "antiPlagiarism"]);
  const { common } = useTheme().palette;
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [taskList, setTaskList] = useState<ITasksList[]>();
  const [filter, setFilter] = useState<IFilter>(initialFilter);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { courseData } = useContext(CourseContext);
  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, name: searchValue };
    setFilter(new_filter);
  };

  const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    const new_filter = {
      ...filter,
      isNoMark: checked && name === "isNoMark",
      isExcludeTotalMark: checked && name === "isExcludeTotalMark",
      enabledAntiPlagiarism: checked && name === 'enabledAntiPlagiarism',
    };
    setFilter(new_filter);
  };

  const searchByParams = (paramName: string, value: any) => {
    if (paramName === 'format') setFilter({ ...filter, format: value });
    if (paramName === 'status') setFilter({ ...filter, status: value });
    if (paramName === 'type') setFilter({ ...filter, type: value });
  };

  useEffect(() => {
    if (role === Role.LISTENER) {
      serviceTask.getTaskListForStudent({
        classId,
        name: filter.name,
        isNoMark: filter.isNoMark,
        isExcludeTotalMark: filter.isExcludeTotalMark,
        enabledAntiPlagiarism: filter.enabledAntiPlagiarism,
        format: filter.format,
        status: filter.status,
        type: filter.type
      }).then(res => {
        setTaskList(res.data);
      })
    } else {
      serviceTask.getTaskList(courseId, { name: '', format: null, status: '' }).then((res) => {
        if (res.status === 200) {
          setTaskList(res.data.dtoList.filter(task => task.status === CourseStatus.PUBLISHED))
        }
      })
    }
  }, [filter, classId, role, courseId]);

  const isTaskOpen = (task) => {
    if (!courseData?.sequentialLearning) {
      return true
    }
    return task.open
  }

  const onClickTaskCard = (taskName: string | null, taskId: string | null) => {
    const crumb: IBreadcrumb = {
      level: 3,
      page: 'task',
      label: `${taskName}`,
      path: `/myCourses/current/${classId}`,
      tabNum: null
    }
    if (courseId) setBreadcrumbs(courseId, [...getBreadcrumbs(courseId), crumb]);
    if (classId) setBreadcrumbs(classId, [...getBreadcrumbs(classId), crumb]);
    navigate(`/myCourses/current/${classId}/tasks/${taskId}`)
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, background: "#FFFFFF", padding: "15px", borderRadius: "6px", border: `1px solid ${common.borderSecondary}` }}>
      <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
        <TextField
          label={t("common:placeholders.SEARCH_BY_NAME")}
          color="primary"
          size="small"
          name="searchValue"
          onChange={(e) => searchByName(e.target.value)}
          sx={{ width: '100%', '& label.Mui-focused': { color: common.primaryColor }, '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor }, '& .MuiOutlinedInput-root': { '&:hover fieldset': { borderColor: common.primaryColor }, '&.Mui-focused fieldset': { borderColor: common.primaryColor } } }} />
        <Box sx={{ border: `1px solid ${common.borderSecondary}`, height: '40px', width: '124px', borderRadius: '9px', display: 'flex', alignItems: 'center', px: '4px', cursor: 'pointer' }}
          onClick={() => setOpenFilter(!openFilter)}>
          <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "20px", color: "black", mr: '8px', ml: '4px' }}>{t('common:FILTER')}</Typography>
          <TuneIcon />
          {openFilter ? <ArrowDropUpIcon sx={{ width: '30px', height: '30px' }} /> : <ArrowDropDownIcon sx={{ width: '30px', height: '30px' }} />}
        </Box>
      </Box>
      {openFilter &&
        <Box>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={3}>
            <CustomAutocomplete
              name='format'
              placeholder={t('dictionary:FORMAT')}
              options={taskFormats}
              onChange={(name: string, value: any) => searchByParams(name, value)}
            />
            <CustomAutocomplete
              name='status'
              placeholder={t('user:STATUS')}
              options={taskStatusOptions}
              onChange={(name: string, value: any) => searchByParams(name, value)}
            />
            <CustomAutocomplete
              name='type'
              placeholder={t('dictionary:TYPE')}
              options={taskTypes}
              onChange={(name: string, value: any) => searchByParams(name, value)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' }, alignItems: { sm: "center", xs: 'flex-start' }, gap: 2 }}>
            <Box display='flex' alignItems='center'>
              <Checkbox
                name="isNoMark"
                checked={filter.isNoMark}
                onChange={(e) => onChangeCheckbox(e)}
                sx={{ color: common.primaryColor, "&, &.Mui-checked": { color: common.primaryColor } }}
              />
              <Typography>{t('course:theme.WITHOUT_EVALUATION')}</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Checkbox
                name="isExcludeTotalMark"
                checked={filter.isExcludeTotalMark}
                onChange={(e) => onChangeCheckbox(e)}
                sx={{ color: common.primaryColor, "&, &.Mui-checked": { color: common.primaryColor } }}
              />
              <Typography>{t("course:test.NOT_CONSIDER_WHEN_CALCULATING_FINAL_GRADE")}</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Checkbox
                name="enabledAntiPlagiarism"
                checked={filter.enabledAntiPlagiarism}
                onChange={(e) => onChangeCheckbox(e)}
                sx={{ color: common.primaryColor, "&, &.Mui-checked": { color: common.primaryColor } }}
              />
              <Typography>{t("antiPlagiarism:PLAGIARISM_CHECK_PROVIDED")}</Typography>
            </Box>
          </Box>
        </Box>
      }
      {taskList && taskList.map((taskInfo: ITasksList) => {
        const { id, name, isExcludeTotalMark, isNoMark, theme, section, format, type, progress, point } = 'task' in taskInfo ? taskInfo?.task : taskInfo
        const dateOfAccess = 'dateOfAccess' in taskInfo ? taskInfo.dateOfAccess : '';
        return (
          <Box
            key={taskInfo.id}
            sx={{
              display: "flex",
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: "space-between",
              alignItems: { xs: 'flex-start', sm: "center" },
              borderRadius: "8px",
              border: "1px solid #C1C1C1",
              p: 2.5,
              cursor: ((role === Role.EDUCATOR && dateOfAccess && new Date(dateOfAccess) <= new Date())
                || (role === Role.EDUCATOR && !dateOfAccess)
                || (role === Role.LISTENER && taskInfo.allowed)
              ) && isTaskOpen(taskInfo) ? "pointer" : "not-allowed",
              ":hover": { background: "#CACACA" }
            }}
            //TODO: http://10.25.72.19:8080/browse/PLTA-1184
            onClick={() => {
              if(!isTaskOpen(taskInfo)) return

              if (
                // the theacher doesn`t have studyClassId
                // (role === Role.EDUCATOR && dateOfAccess && new Date(dateOfAccess) <= new Date())
                // || (role === Role.EDUCATOR && !dateOfAccess) || 
                (role === Role.LISTENER && taskInfo.allowed)
              ) {
                onClickTaskCard(name, id)
              }
            }}
          >
            <Box>
              <Box display="flex" alignItems="center" gap={1}>
                <NoteAltOutlinedIcon />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '24px'
                  }}
                >
                  {name}
                </Typography>
              </Box>
              {(isNoMark || isExcludeTotalMark) &&
                <Box display="flex" alignItems="center" gap={1} sx={{ mt: '4px' }}>
                  <Box sx={{ width: '20px', height: '20px', borderRadius: '20px', backgroundColor: '#D5BEFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <InfoOutlinedIcon sx={{ width: '15.5px', height: '15.5px', color: common.primaryColor }} />
                  </Box>
                  <Typography alignItems="center">{isNoMark ? t("course:theme.WITHOUT_EVALUATION") : t("course:theme.NOT_TAKEN_INTO_ACCOUNT_IN_FINAL_GRADE")}</Typography>
                </Box>
              }
              <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'flex-start', sm: "center" }} gap={1} sx={{ mt: '4px' }}>
                <Typography alignItems="center">{t('dictionary:TYPE')}: {type === 'TASK' ? t('enum:TASK') : t('enum:CONTROL')}</Typography>
                {!matchesDownSm && <Box sx={{ width: '6px', height: '6px', borderRadius: '6px', backgroundColor: '#D9D9D9' }} />}
                <Typography alignItems="center">
                  {theme !== null && t('course:task.REFERS_TO_THEME', { themeName: theme?.name })}
                  {section !== null && t('course:task.REFERS_TO_SECTION', { sectionName: section?.name })}
                  {(theme === null && section === null) && t('course:task.REFERS_TO_COURSE')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', mt: '4px' }}>
                <Typography alignItems="center">{t('dictionary:FORMAT')}: {formatText[format || ""]}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography alignItems="center">{t('course:theme.TASK_STATUS')}:</Typography>
                <Box padding="5px 15px" sx={{ color: "#fff", background: statusBgColor[role === Role.LISTENER ? taskInfo?.status || '' : "ASSIGNED"], borderRadius: "15px" }}>
                  {role === Role.EDUCATOR ? t(`enum:ASSIGNED`) : t(`enum:${taskInfo?.status}`)}
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                {dateOfAccess &&
                  <Typography alignItems="center">
                    {t('course:theme.ACCESS_OPENING_DATE')}: {formatDateWithHourMinute(dateOfAccess)}
                  </Typography>
                }
                {taskInfo?.hasDeadlineDate &&
                  <Typography alignItems="center">
                    {t('course:task.COMPLETE_UNTIL')}: {formatDateWithHourMinute(taskInfo.dateOfDeadline)}
                  </Typography>
                }
              </Box>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              {!isNoMark &&
                <>
                  <Box sx={{ display: 'grid', placeItems: 'center', background: common.secondaryColor, padding: "5px 12px", borderRadius: "6px" }}><AssignmentTurnedInOutlinedIcon /></Box>
                  <Box>
                    {courseData?.typeCode === TypeCode.FREE
                      ? <>{taskInfo?.point || '—'}</>
                      : <>
                        <Typography>{t('course:GRADE')}</Typography>
                        {/*Временное округление, поменяется после анализа*/}
                        {`${taskInfo?.point ? Math.round(Number(taskInfo?.point)) : '-'} / ${point ? Number(point) : '100'}`}
                      </>}
                  </Box>
                </>
              }
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
