import { Fragment, useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";

import { serviceCourse } from "@services/course";
import { StateSchema } from "@store/StateSchema";
import Message from "@components/Message";
import CustomAutocomplete from "@ui/Autocomplete";
import LanguageCell from "@components/LanguageCell";
import SubdivisionCell from "@components/SubdivisionCell";
import CustomButton from "@ui/Button";
import CustomSwitch from "@ui/Switch";
import { DictionaryCode, Role } from "@interfaces/index";
import { QuizDraft, QuizDraftFilter } from "./models";
import { downloadFile, formatDateWithHourMinute } from "@utils/functions";
import { serviceDict } from "@services/dictionary";
import ReactTable from "@ui/ReactTable";
import useConfirmModal from "@hooks/useConfirmModal";
import { AlertColor } from "@mui/material";
import { itemStatusTypes } from "@utils/enums";
import AddQuizModal from "./AddQuizModal";

const List = () => {
  const { t } = useTranslation(["course", "dictionary", "common", "enum"]);
  const { common } = useTheme().palette;
  const profile = useSelector(
    (store: StateSchema) => store.account.profileInfo
  );
  const { role } = profile.authorityInfo;
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [subdivisionList, setSubdivisionList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [filter, setFilter] = useState<QuizDraftFilter>({});
  // const [filter, setFilter] = useState<MRT_ColumnFiltersState>([]);
  // const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [filterIsShown, setFilterIsShown] = useState<boolean>(false);
  const [currentItemId, setCurrentItemId] = useState<string | null>(null);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const [addQuizModalOpen, setAddQuizModalOpen] = useState(false);
  const queryClient = useQueryClient();
  // Queries
  const { data: { dtoList = [], totalElements } = {} } = useQuery({
    queryKey: ["bankOfTests", filter, pagination],
    queryFn: () =>
      serviceCourse.getQuizBankList({
        pageNumber: pagination.pageIndex,
        size: pagination.pageSize,
        filter, //: filter.length > 0 ? JSON.stringify(filter) : {},
        sorting: { created_at: "desc" }, //sorting.length > 0 ? JSON.stringify(sorting) : {}
      }),
    retry: 0,
    placeholderData: keepPreviousData,
  });

  const { mutate: handleDelete } = useMutation({
    mutationFn: async () => {
      //send api update request here
      const res = currentItemId
        ? serviceCourse.deleteQuizDraft(currentItemId)
        : null;
      return res;
    },
    onSuccess: () => {
      handleOpenModal();
      return queryClient.invalidateQueries({ queryKey: ["bankOfTests"] });
    },
    onError: (error: any) => {
      handleOpenModal();
      setMessage(t(`common:messages.${error?.response?.data?.code}`));
      setMessageOpen(true);
      setMessageType("error");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    },
  });


  const { mutate: handleUpdate } = useMutation({
    mutationFn: (reqBody: QuizDraft) => {
      return serviceCourse.updateQuizDraft(reqBody);
    },
    onSuccess: () => {
      setMessage(t("common:messages.CHANGES_SAVED"));
      setMessageOpen(true);
      setMessageType("success");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
      return queryClient.invalidateQueries({ queryKey: ["bankOfTests"] });
    },
    onError: () => {
      setMessage(
        "Вы не можете сменить статус, т.к. данный тест используется в курсе"
      );
      setMessageOpen(true);
      setMessageType("error");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    },
  });

  useEffect(() => {
    serviceDict
      .getDictListForDropdown(DictionaryCode.SUBDIVISION)
      .then((res) => {
        if (res.status === 200) setSubdivisionList(res.data); //.map((x: IDictionary) => {return x[`name${capitalize(lang)}`]}));
      });
    serviceDict.getDictListForDropdown(DictionaryCode.LANGUAGE).then((res) => {
      if (res.status === 200) setLanguageList(res.data); //.map((x: IDictionary) => {return x[`name${capitalize(lang)}`]}));
    });
  }, []);

  const handleToggleQuizModal = (modalState: boolean, message?: string) => {
    setAddQuizModalOpen(modalState);
    if (message) {
      setMessage(t(message));
      setMessageOpen(true);
      setMessageType("success");
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    }
  };

  const columns = useMemo<MRT_ColumnDef<QuizDraft>[]>(() => {
    return [
      {
        accessorKey: "name",
        header: t("enum:TEST_NAME"),
        size: 370,
        minSize: 190,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) =>
          role !== Role.LOCAL_ADMIN ? (
            <RouterLink
              style={{ textDecoration: "none" }}
              to={`/bankOfTests/${row.original.id}`}
            >
              <Typography
                sx={{
                  color: common.primaryColor,
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                {renderedCellValue}
              </Typography>
            </RouterLink>
          ) : (
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              {renderedCellValue}
            </Typography>
          ),
      },
      {
        accessorKey: "langId",
        header: t("dictionary:QUIZ_LANGUAGE"),
        minSize: 150,
        enableResizing: false,
        enableSorting: false,
        // filterVariant: 'select',
        // filterSelectOptions: languageList,
        Cell: ({ row }) => {
          const { langId } = row.original;
          return <LanguageCell id={langId} />;
        },
      },
      {
        accessorKey: "createdAt",
        id: "createdAt",
        header: t("enum:IMPORT_DATE"),
        size: 170,
        // filterVariant: 'date-range',
        enableSorting: false,
        Cell: ({ row }) => {
          const { createdAt } = row.original;
          return <span>{formatDateWithHourMinute(createdAt?.toString())}</span>;
        },
      },
      {
        accessorKey: "updatedAt",
        id: "updatedAt",
        header: t("enum:REVISION_DATE"),
        size: 200,
        // filterVariant: 'date-range',
        enableSorting: false,
        Cell: ({ row }) => {
          const { updatedAt } = row.original;
          return <span>{formatDateWithHourMinute(updatedAt?.toString())}</span>;
        },
      },
      {
        accessorKey: "subdivisionId",
        id: "subdivisionIds",
        header: t("enum:SUBDIVISION"),
        size: 300,
        enableSorting: false,
        // filterVariant: 'multi-select',
        // filterSelectOptions: subdivisionList,
        Cell: ({ row }) =>
          row.original.subdivisionIds?.length > 0 ? (
            row.original.subdivisionIds.map((sId: number, index: number) => {
              if (index !== 0)
                return (
                  <Fragment key={sId}>
                    , <SubdivisionCell id={sId} />
                  </Fragment>
                );
              return <SubdivisionCell key={sId} id={sId} />;
            })
          ) : (
            <Typography></Typography>
          ),
      },
      {
        accessorKey: "isDisabled",
        id: "isDisabled",
        header: role !== Role.LOCAL_ADMIN ? t("enum:IS_USED") : t("enum:STATUS"),
        size: 140,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
        enableSorting: false,
        Cell: ({ row }) => {
          const { isDisabled } = row.original;
          const reqBody = row.original;
          return (
            <CustomSwitch
              checked={!isDisabled}
              disabled={role === Role.LOCAL_ADMIN}
              onChange={(value: boolean) =>
                handleUpdate({ ...reqBody, isDisabled: !value })
              }
            />
          );
        },
      },
    ];
  }, [common, t, role, handleUpdate]);

  const searchByName = (searchValue: string) => {
    setFilter({ ...filter, name: searchValue });
  };

  const searchByParams = (paramName: string, value: any) => {
    const newFilter = { ...filter };
    if (paramName === "langId") {
      if (value) setFilter({ ...filter, langId: value });
      else {
        delete newFilter.langId;
        setFilter(newFilter);
      }
    }
    if (paramName === "subdivisionIds") {
      if (value?.length > 0) setFilter({ ...filter, subdivisionIds: value });
      else {
        delete newFilter.subdivisionIds;
        setFilter(newFilter);
      }
    }
    if (paramName === "isUsed") {
      if (typeof value === "boolean")
        setFilter({ ...filter, isDisabled: value });
      else {
        delete newFilter.isDisabled;
        setFilter(newFilter);
      }
    }
  };

  const { ConfirmModal, handleOpenModal } = useConfirmModal({
    onConfirm: handleDelete,
    modalTitle: t("course:messages.ARE_YOU_SURE_TO_DELETE_QUIZ"),
  });

  return (
    <>
      <Box display="flex" gap={1} alignItems="center">
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          color="primary"
          sx={{
            width: "30%",
            height: "40px",
            display: "flex",
            backgroundColor: "#FFFFFF",
            "& label.Mui-focused": { color: common.primaryColor },
            "& .MuiInput-underline:after": {
              borderBottomColor: common.primaryColor,
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": { borderColor: common.primaryColor },
              "&.Mui-focused fieldset": {
                borderColor: common.primaryColor,
              },
            },
          }}
          placeholder={t("common:placeholders.SEARCH_BY_QUIZ_NAME")}
          name="searchValue"
          value={filter.name || ""}
          onChange={(e) => searchByName(e.target.value)}
          InputProps={{
            sx: {
              height: "40px",
              pr: 0.5,
            },
            endAdornment: (
              <InputAdornment position="end">
                <Box
                  sx={{
                    backgroundColor: common.primaryColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "45px",
                    height: "32px",
                    borderRadius: "6px",
                  }}
                >
                  <SearchIcon
                    sx={{
                      color: "#FFFFFF",
                      width: "26px",
                      height: "26px",
                    }}
                  />
                </Box>
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            height: "40px",
            backgroundColor: common.primaryColor,
            borderRadius: "9px",
            display: "flex",
            alignItems: "center",
            px: "4px",
            cursor: "pointer",
          }}
          onClick={() => setFilterIsShown(!filterIsShown)}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: common.fontWhite,
              mr: "8px",
              ml: "4px",
            }}
          >
            {t("common:FILTER")}
          </Typography>
          <TuneIcon sx={{ color: common.white }} />
          {filterIsShown ? (
            <ArrowDropUpIcon
              sx={{ width: "30px", height: "30px", color: common.white }}
            />
          ) : (
            <ArrowDropDownIcon
              sx={{ width: "30px", height: "30px", color: common.white }}
            />
          )}
        </Box>
        {role !== Role.LOCAL_ADMIN && (
          <Box sx={{ display: "flex", ml: "auto" }}>
            <CustomButton
              backgroundColor={common.primaryColor}
              fontColor={common.fontWhite}
              borderRadius="6px"
              width="fit-content"
              height="40px"
              onClick={() => handleToggleQuizModal(true)}
              sx={{ display: "flex", ml: "auto" }}
            >
              {t('course:IMPORT_TESTS')}
            </CustomButton>
          </Box>
        )}
      </Box>
      {filterIsShown && (
        <Box display="flex" gap={1} alignItems="center">
          <CustomAutocomplete
            name="langId"
            value={filter.langId}
            placeholder={t("dictionary:QUIZ_LANGUAGE")}
            options={languageList}
            onChange={(name: string, value: any) => searchByParams(name, value)}
            // sx={{ width: "265px" }}
            customIconDisabled={false}
          />
          <CustomAutocomplete
            name="subdivisionIds"
            value={filter.subdivisionIds}
            placeholder={t("enum:SUBDIVISION")}
            options={subdivisionList}
            multiple
            limitTags={1}
            onChange={(name: string, value: any) => searchByParams(name, value)}
            // sx={{ width: "300px" }}
            customIconDisabled={false}
          />
          <CustomAutocomplete
            name="isUsed"
            value={filter.isDisabled}
            placeholder={t("common:label.STATUS")}
            options={itemStatusTypes}
            onChange={(name: string, value: any) => searchByParams(name, value)}
            // sx={{ width: "300px" }}
            customIconDisabled={false}
          />
        </Box>
      )}
      {messageOpen && (
        <Message
          type={messageType}
          message={message}
          setMessageOpen={messageOpen}
        />
      )}
      <ReactTable<QuizDraft>
        columns={columns}
        data={dtoList}
        rowCount={totalElements}
        renderRowActions={({ row }) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            {role !== Role.LOCAL_ADMIN && (
              <>
                <FileDownloadOutlinedIcon
                  onClick={() => {
                    const { fileId } = row.original;
                    downloadFile(fileId);
                  }}
                  sx={{
                    color: common.strokePrimary,
                    "&:hover": {
                      cursor: "pointer",
                      color: common.primaryColor,
                    },
                  }}
                />

                <DeleteOutlinedIcon
                  onClick={() => {
                    const { id } = row.original;
                    if (id) {
                      setCurrentItemId(id);
                      handleOpenModal();
                    }
                  }}
                  sx={{
                    color: common.strokePrimary,
                    "&:hover": { cursor: "pointer", color: common.errorColor },
                  }}
                />
              </>
            )}
          </Box>
        )}
        enableRowActions={role !== Role.LOCAL_ADMIN}
        onPaginationChange={setPagination}
        // onColumnFiltersChange={setFilter}
        // onSortingChange={setSorting}
        state={{
          // columnFilters: filter,
          pagination, //pass the pagination state to the table
          // sorting
        }}
      />
      {ConfirmModal}
      {addQuizModalOpen && (
        <AddQuizModal
          languageList={languageList}
          subdivisionList={subdivisionList}
          modalOpen={addQuizModalOpen}
          handleToggleModal={handleToggleQuizModal}
        />
      )}
    </>
  );
};

export default List;
