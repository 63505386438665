import { FC } from "react";
// pages
import Home from "@pages/home/index";
import Profile from "@pages/profile";
import Dictionary from "@pages/dictionary";
import RolesAndRights from "@pages/roles&permissions";
import NotificationPage from "@pages/notification/List";

import OrganizationList from "@pages/dictionary/Organization/List";
import OrganizationForm from "@pages/dictionary/Organization/Form";
import AdditionalSettings from "@pages/dictionary/Organization/AdditionalSettings";
import CategoryList from "@pages/dictionary/Category/List";
import DictionaryList from "@pages/dictionary/List";
import AssessmentSystem from "@pages/dictionary/Organization/AdditionalSettings/AssessmentSystem";
import Integrations from "@pages/dictionary/Organization/AdditionalSettings/Integrations";
import AddIntegration from "@pages/dictionary/Organization/AdditionalSettings/AddIntegration";

import UsersList from "@pages/users/Index";
import UsersForm from "@pages/users/Form";
import NewPasswordList from "@pages/users/NewPasswordList";

import MyCourseList from "@pages/myCourses";
import CourseInfo from "@pages/myCourses/Educator/CourseInfo";
import Theme from "@pages/myCourses/Educator/TabList/Content/Theme";
import TaskFormat from "@pages/myCourses/Educator/TabList/Content/Task/TaskFormat";
import { ConferenceForm } from "@pages/myCourses/Educator/TabList/Conferences/ConferenceForm";
import TaskRecipients from "@pages/myCourses/Educator/TabList/Assessment/TaskRecipients";
import TaskAssessment from "@pages/myCourses/Educator/TabList/Assessment/TaskAssessment";

import StudentCourseInfo from "@pages/myCourses/Student/CourseInfo";
import ThemeListener from "@pages/myCourses/Student/CurrentCourses/Content/Theme";
import QuizTask from "@pages/myCourses/Student/CurrentCourses/Task/Quiz";
import TaskInfo from "@pages/myCourses/Student/CurrentCourses/Task";
import TaskReplyHistory from "@pages/myCourses/Student/CurrentCourses/Task/ReplyHistory";
import { RestrictedCourse } from "@pages/myCourses/Student/RestrictedCourse";

import EventCalendar from "@pages/eventCalendar";

import CourseList from "@pages/courses";

import Assessments from "@pages/evaluationSystem/Assessments";
import AssessmentsWithFX from "@pages/evaluationSystem/AssessmentsWithFX";
import CumulativeAssessmentSystem from "@pages/evaluationSystem/CumulativeAssessmentSystem";
import PassedNotPassedAssessmentSystem from "@pages/evaluationSystem/PassedNotPassedAssessmentSystem";

import ActionsJournal from "@pages/actionsJournal";
import CourseSettings from "@pages/generalSettings/CourseSettings";
import LocalSettings from "@pages/generalSettings/Settings";
import Charts from "@pages/generalSettings/Charts";
import SettingsHandbook from "@pages/generalSettings/SettingsHandbook";
import HandbookTypes from "@pages/generalSettings/HandbookTypes";
import Roles from "@pages/generalSettings/Roles";
import TaskParameters from "@pages/myCourses/Educator/TabList/Assessment/TaskParameters";
import { AeroCourseList } from "@pages/generalSettings/AeroReport/AeroCourseList";
import { AeroTaskList } from "@pages/generalSettings/AeroReport/AeroTaskList";
import { AeroStudentList } from "@pages/generalSettings/AeroReport/AeroStudentList";
import RegistrationPage from "@pages/registration";
import TermsOfUse from "@pages/termsOfUse";
import { CatalogCourse } from "@pages/freeCourse/catalogCourse";
import EducatorRestrictedCourse from "@pages/myCourses/Educator/RestrictedCourse";
import BankOfTests from "@pages/bankOfTests";
import QuizQuestionList from "@pages/bankOfTests/QuizQuestionList";
import { AboutOrganization } from "@pages/generalSettings/AboutOrganiztion";
import { CertificatePage } from "@pages/certificate";
import { AchievementsPage } from "@pages/achievements";
import CertificateSetting from "@pages/generalSettings/CertificateSetting/CertificateSetting";
import { TestReports } from "@pages/generalSettings/AeroReport/TestReports";
import Reports from "@pages/reports";
import { AllStudentList } from "@pages/reports/AllStudentList";
import { AllCourseList } from "@pages/reports/AllCourseList";
import { AllEducatorList } from "@pages/reports/AllEducatorList";
import { AntiPlagiarismPage } from "@pages/dictionary/Organization/AntiPlagiarism";
import LicensePage from "@pages/license";
import { DirectiryLicenseDetails } from "@pages/license/DirectiryLicenseDetails";
import { StudentList } from "@pages/dictionary/Organization/AntiPlagiarism/StudentList";
import { IntegrationLogs } from "@pages/dictionary/Organization/AdditionalSettings/IntegrationLogs";
import { CoAuthorsPage } from "@pages/myCourses/Educator/TabList/Participants/CoAuthors";

// interface
interface Route {
  key: string;
  path: string;
  element: FC<{}>;
}

export const privateRoutes: Array<Route> = [
  {
    key: "settingCertificate",
    path: "/settings/certificate",
    element: CertificateSetting,
  },
  {
    key: "achievements",
    path: "/achievements",
    element: AchievementsPage,
  },
  {
    key: "profile-route",
    path: "/profile",
    element: Profile,
  },
  {
    key: "dictionary-route",
    path: "/dict",
    element: Dictionary,
  },
  {
    key: "rolesAndRights-route",
    path: "/rolesAndRights",
    element: RolesAndRights,
  },
  {
    key: "generalSettings-route",
    path: "/generalSettings",
    element: CourseSettings,
  },
  {
    key: "organization-course-settings",
    path: "/dict/organization/:id/course-settings",
    element: CourseSettings,
  },
  {
    key: "assessmentSystem-route",
    path: "/assessmentSystem",
    element: AssessmentSystem,
  },
  {
    key: "actionsJournal-route",
    path: "/actionsJournal",
    element: ActionsJournal,
  },
  {
    key: "assessmentsWithFX-route",
    path: "/assessmentSystem/assessmentsWithFX",
    element: AssessmentsWithFX,
  },
  {
    key: "assessments-route",
    path: "/assessmentSystem/assessments",
    element: Assessments,
  },
  {
    key: "CumulativeAssessmentSystem-route",
    path: "/assessmentSystem/CumulativeAssessmentSystem",
    element: CumulativeAssessmentSystem,
  },
  {
    key: "PassedNotPassedAssessmentSystem-route",
    path: "/assessmentSystem/PassedNotPassedAssessmentSystem",
    element: PassedNotPassedAssessmentSystem,
  },
  {
    key: "organizations-route",
    path: "/dict/organizations",
    element: OrganizationList,
  },
  {
    key: "organization-form-route",
    path: "/dict/organization/:id",
    element: OrganizationForm,
  },
  {
    key: "organization-course-additional-settings",
    path: "/dict/organization/:id?tab=additionalSettings",
    element: AdditionalSettings,
  },
  {
    key: "local-admin-settings-list",
    path: "/settings",
    element: LocalSettings,
  },
  {
    key: "local-admin-about-organization",
    path: "/aboutOrg",
    element: AboutOrganization,
  },
  {
    key: "local-admin-reports-organization",
    path: "/reports",
    element: Reports,
  },
  {
    key: "local-admin-reports-organization",
    path: "/reports/student",
    element: AllStudentList,
  },
  {
    key: "local-admin-reports-organization",
    path: "/reports/course",
    element: AllCourseList,
  },
  {
    key: "local-admin-reports-organization",
    path: "/reports/educator",
    element: AllEducatorList,
  },
  {
    key: "local-admin-course-settings",
    path: "/settings/course-settings/:id",
    element: CourseSettings,
  },
  {
    key: "local-admin-roles",
    path: "/settings/roles/:id",
    element: Roles,
  },
  {
    key: "local-admin-assessment-system",
    path: "/settings/assessment-system/:id",
    element: AssessmentSystem,
  },
  {
    key: "local-admin-integrations",
    path: "/settings/integrations/:id",
    element: Integrations,
  },
  {
    key: "local-admin-integrations-antiPlagiarism",
    path: "/settings/integrations/:id/anti-plagiarism",
    element: AntiPlagiarismPage,
  },
  {
    key: "local-admin-integrations-antiPlagiarism",
    path: "/settings/integrations/:id/anti-plagiarism/:courseId",
    element: StudentList,
  },
  {
    key: "local-admin-charts",
    path: "/settings/charts/:id",
    element: Charts,
  },
  {
    key: "local-admin-aero-course",
    path: "/settings/aero",
    element: AeroCourseList,
  },
  {
    key: "local-admin-aero-course-task",
    path: "/settings/aero/course/:courseId",
    element: AeroTaskList,
  },
  {
    key: "local-admin-aero-course-task",
    path: "/settings/aero/course/:courseId/task/:taskId",
    element: AeroStudentList,
  },
  {
    key: "local-admin-reports",
    path: "/settings/aero/course/:courseId/task/:taskId/progress/:progressId/user/:userId",
    element: TestReports,
  },
  {
    key: "local-admin-handbook",
    path: "/settings/handbook/:id",
    element: SettingsHandbook,
  },
  {
    key: "local-admin-handbook-type",
    path: "/settings/handbook/:id/:handbookType",
    element: HandbookTypes,
  },
  {
    key: "organization-roles",
    path: "/dict/organization/:id/roles",
    element: Roles,
  },
  {
    key: "organization-assessment-system",
    path: "/dict/organization/:id/assessment-system",
    element: AssessmentSystem,
  },
  {
    key: "organization-integrations",
    path: "/dict/organization/:id/integrations",
    element: Integrations,
  },
  {
    key: "integrations",
    path: "/integrations",
    element: Integrations,
  },
  {
    key: "integrationsLogs",
    path: "/integrations/logs",
    element: IntegrationLogs,
  },
  {
    key: "organization-add-integrations",
    path: "/dict/organization/:id/add-integrations",
    element: AddIntegration,
  },
  {
    key: "local-admin-add-integrations",
    path: `/settings/integrations/:id/add-integrations`,
    element: AddIntegration,
  },
  {
    key: "categories-route",
    path: "/dict/categories",
    element: CategoryList,
  },
  {
    key: "dictionary-route",
    path: "/dict/list/:dictCode",
    element: DictionaryList,
  },
  {
    key: "notification-form-route",
    path: "/notifications",
    element: NotificationPage,
  },
  {
    key: "users-route",
    path: "/users",
    element: UsersList,
  },
  {
    key: "users-form-route",
    path: "/users/:id",
    element: UsersForm,
  },
  {
    key: "newpasswordlist-route",
    path: "/newpasswordlist",
    element: NewPasswordList,
  },
  {
    key: "myCourses-route",
    path: "/myCourses",
    element: MyCourseList,
  },
  {
    key: "courses-route",
    path: "/courses",
    element: CourseList,
  },
  {
    key: "course-info-route",
    path: "/myCourses/:courseId",
    element: CourseInfo,
  },
  {
    key: "task-recipient-list",
    path: "/myCourses/:courseId/task-recipients/:taskId/flows/:flowId",
    element: TaskRecipients,
  },
  {
    key: "task-assessment",
    path: "/myCourses/:courseId/task-assessment/:pathType/:recipientId/:progressId/flows/:flowId",
    element: TaskAssessment,
  },
  {
    key: "conference-course-route",
    path: "/myCourses/:courseId/conference",
    element: ConferenceForm,
  },
  {
    key: "edit-conference-course-route",
    path: "/myCourses/:courseId/conference/:conferenceId",
    element: ConferenceForm,
  },
  {
    key: "myCoursesTheme-route",
    path: "/myCourses/:courseId/:sectionId/theme/:themeId",
    element: Theme,
  },
  {
    key: "myCoursesTaskType-route",
    path: "/myCourses/:courseId/tasks/:taskId/:taskFormat/:sectionId/:themeId",
    element: TaskFormat,
  },
  {
    key: "myCoursesTaskSettingReadOnly-route",
    path: "/myCourses/:courseId/task-assessment/:taskId/:taskFormat/:sectionId/:themeId",
    element: TaskParameters,
  },
  {
    key: "currentCourse-route",
    path: "/myCourses/:status/:classId/tasks/:taskId",
    element: TaskInfo,
  },
  {
    key: "currentCourseTask-reply-history-route",
    path: "/myCourses/:status/:courseId/tasks/:taskId/replyHistory/:progressId",
    element: TaskReplyHistory,
  },
  {
    key: "themeSelfTestTask-route",
    path: "/myCourses/:courseId/tasks/:taskId/:type/:quizProgressId",
    element: QuizTask,
  },
  {
    key: "Co-Authors-course",
    path: "/myCourses/:courseId/co-authors",
    element: CoAuthorsPage,
  },
  {
    key: "theme-route",
    path: "/myCourses/:classId/theme/:themeId",
    element: ThemeListener,
  },
  {
    key: "restricted-course-route",
    path: "/myCourses/restricted",
    element: RestrictedCourse,
  },
  {
    key: "educator-restricted-course-route",
    path: "/myCourses/educator/restricted",
    element: EducatorRestrictedCourse,
  },
  {
    key: "courses-catalogue-route",
    path: "/courseCatalogue",
    element: MyCourseList,
  },
  {
    key: "courses-catalogue-course-page-route",
    path: "/courseCatalogue/course/:courseId",
    element: StudentCourseInfo,
  },
  {
    key: "theme-route",
    path: "/courseCatalogue/:courseId/theme/:themeId",
    element: ThemeListener,
  },
  {
    key: "calendar-of-events",
    path: "/eventCalendar",
    element: EventCalendar,
  },
  {
    key: "bank-of-tests-route",
    path: "/bankOfTests",
    element: BankOfTests,
  },
  {
    key: "quiz-questions-route",
    path: "/bankOfTests/:draftId",
    element: QuizQuestionList,
  },
  {
    key: "license-route",
    path: "/license",
    element: LicensePage,
  },
  {
    key: "license-route-id",
    path: "/license/:id",
    element: DirectiryLicenseDetails,
  },
];

export const publicRoutes: Array<Route> = [
  {
    key: "registration-route",
    path: "/registration",
    element: RegistrationPage,
  },
  {
    key: "terms-of-route",
    path: "/terms-of-use",
    element: TermsOfUse,
  },
];

export const commonRoutes: Array<Route> = [
  {
    key: "home-route",
    path: "/",
    element: Home,
  },
  {
    key: "student-course-route",
    path: "/myCourses/:status/:classId",
    element: StudentCourseInfo,
  },
  {
    key: "certificate",
    path: "/certificate/:id",
    element: CertificatePage,
  },
  {
    key: "freeCourseCatalogue-route",
    path: "/freeCourseCatalogue",
    element: CatalogCourse,
  },
];
