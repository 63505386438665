import { Avatar, Box, Divider, Grid, IconButton, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik, Form as FormikForm, Field } from "formik";
import { AlertColor } from '@mui/material/Alert';

import FormikInput from '@ui/formik/Input';
import MaskedInput from '@ui/formik/MaskedInput';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import { IOrgType, IOrganization, initialDto } from '@pages/dictionary/Organization/models';
import { serviceDict } from '@services/dictionary';
import { TextEditor } from '@ui/formik/TextEditor';
import CustomButton from '@ui/Button';
import Message from "@components/Message";
import UploadOrganizationLogo from './UploadOrganizationLogo';
import { AboutOrganizationValidationSchema } from '../validation';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import SaveChangesModal from '@components/modals/SaveChangesModal';

export const AboutOrganization = () => {
  const { t } = useTranslation(["sideMenu", "common", "dictionary", "profile"]);
  const { common } = useTheme().palette;
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [enableText, setEnableText] = useState<boolean>(true);
  const [enableImage, setEnableImage] = useState<boolean>(true);
  const profile = useSelector((store: any) => store.account.profileInfo);
  const [profilePhotoId, setProfilePhotoId] = useState<string | null>(null)
  const { orgId } = profile.authorityInfo;

  const queryClient = useQueryClient();
  const { data: orgTypeList } =
    useQuery({
      queryKey: ['orgTypeList'],
      queryFn: () => serviceDict.getDictListForDropdown("BRANCH_TYPE"),
      retry: 0,
    });

  const { data: initialData } =
    useQuery({
      queryKey: ['InitialDataAboutOrg', orgId],
      queryFn: () => serviceDict.getDictionary("BRANCH", orgId || ""),
      retry: 0,
    });

  const handleVideoFileAfterUpload = (photoId: string) => {
    setEnableImage(false)
    setProfilePhotoId(photoId)
  }

  return (
    <Box>
      <Typography sx={{ fontWeight: "500", fontSize: "36px", lineHeight: "44px", mb: "27px", mt: '20px' }}>
        {t('sideMenu:ABOUT_ORGANIZATION')}
      </Typography>
      {messageOpen &&
        <Box sx={{ mb: '24px', mt: '-12px' }}>
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
          />
        </Box>
      }
      <Grid container gap={1.5}>
        <Grid item xs={2.5} sx={{ height: "300px", paddingTop: "44px", textAlign: "center", border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite, borderRadius: '24px' }}>
          <UploadOrganizationLogo initialData={initialData?.data || initialDto} attachAndSaveFile={handleVideoFileAfterUpload} typeCode='002' />
        </Grid>
        <Grid item xs={9} sx={{ border: `1px solid ${common.borderSecondary}`, backgroundColor: common.fontWhite, borderRadius: '24px', padding: "24px" }}>
          <Formik
            initialValues={initialData?.data || initialDto}
            enableReinitialize
            validationSchema={AboutOrganizationValidationSchema(t)}
            onSubmit={(values) => {
              serviceDict.createDictionary("BRANCH", {
                ...values,
                profilePhotoId: profilePhotoId ? profilePhotoId : initialData?.data?.profilePhotoId
              }).then((res) => {
                if (res.status === 200) {
                  queryClient.invalidateQueries({ queryKey: ['InitialDataAboutOrg'] })
                  setMessageOpen(true);
                  setEnableImage(true);
                  setMessage(`${t("common:messages.CHANGES_SAVED")}`);
                  setMessageType("success");
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 3000);
                }
              }).catch((err) => {
                if (err.response.status === 400) {
                  setMessageOpen(true);
                  setMessageType("error");
                  err.response.data.message === "ITEM_IS_EXISTS" ?
                    setMessage(t("ITEM_IS_EXISTS")) : setMessage(t("common:messages.ERROR"));
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 10000)
                }
              }).finally(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              });
            }}>
            {({ submitForm, dirty, isValid }) => (
              <FormikForm>
                <Typography fontSize="18px" fontWeight={600}>{t("profile:PERSONAL_INFO")}</Typography>
                <Divider sx={{ margin: "16px 0" }} />
                <FormikInput label={t("dictionary:NAME_IN_KAZAKH")} name="nameKz" isRequired />
                <Box sx={{ height: "24px" }} />
                <FormikInput label={t("dictionary:NAME_IN_RUSSIAN")} name="nameRu" isRequired />
                <Box sx={{ height: "24px" }} />
                <FormikInput label={t("dictionary:NAME_IN_ENGLISH")} name="nameEn" isRequired />
                <Box sx={{ height: "24px" }} />
                <FormikInput label={t("dictionary:FULL_NAME_OF_THE_HEAD")} name="directorFullName" isRequired />
                <Box sx={{ height: "24px" }} />
                <Box display="flex" gap={1.5}>
                  <MaskedInput label={t("dictionary:BIN")} name="bin" type="iin" isRequired />
                  <FormikAutocomplete label={t("dictionary:TYPE_OF_ORGANIZATION")} name="branchTypeId" options={orgTypeList?.data} isRequired />
                </Box>
                <Box sx={{ height: "24px" }} />
                <Typography fontSize="18px" fontWeight={600}>{t("profile:PUBLIC_INFO")}</Typography>
                <Divider sx={{ margin: "16px 0" }} />
                <TextEditor
                  name="about"
                  isRequired
                  label={t("sideMenu:ABOUT_ORGANIZATION")}
                  showScrollEditor
                  showSymbolCount
                  minChar={100}
                  maxChar={1000}
                  hideToolbar
                  setIsValidSubmitBtn={setEnableText}
                />
                <Box
                  display="flex"
                  sx={{ gap: 2 }}
                >
                  <FormikInput
                    name="facebook"
                    label="Facebook"
                    placeholder="http://www.facebook.com/"
                  />
                  <FormikInput
                    name="youtube"
                    label="YouTube"
                    placeholder="http://www.youtube.com/"
                  />
                  <FormikInput
                    name="instagram"
                    label="Instagram"
                    placeholder="http://www.instagram.com/"
                  />
                </Box>
                <Divider sx={{ margin: "16px 0" }} />
                <Box sx={{
                  mt: '20px', display: 'flex', justifyContent: 'flex-end'
                }}>
                  <CustomButton
                    width="98px"
                    height="40px"
                    backgroundColor={common.primaryColor}
                    borderRadius="8px"
                    fontColor={common.fontWhite}
                    onClick={submitForm}
                    disabled={!(dirty && isValid && enableText) && enableImage}
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      alignItems: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    {t('common:actions.SAVE')}
                  </CustomButton>
                </Box>
                <SaveChangesModal dirty={dirty} />
              </FormikForm>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  )
}