import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { Box, IconButton, Typography, useTheme, Avatar, useMediaQuery, Popover, Divider, Checkbox, FormControlLabel } from '@mui/material';
import StickyNote2Outlined from '@mui/icons-material/StickyNote2Outlined';

import { serviceCourse } from '@services/course';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';
import { FloatingNote } from '@components/FloatingNote';
import VideoPlayer from '@components/VideoPlayer';
import TextReader from '@ui/TextReader';
import { SelectTheme } from './SelectTheme';
import { TaskList } from './TaskList';
import { SelfTestQuiz } from './SelfTestQuiz';
import { DurationType, IBreadcrumb, ITheme, Role, CourseStatus } from '@interfaces/index';
import { initialDto } from '@pages/myCourses/Educator/TabList/Content/Theme/models';
import "./styles.scss";
import PreviewLogout from '@components/PreviewLogout';
import { CourseContext } from "@context/CourseContext";
import { MaterialList } from './MaterialList';
import SequentialVideo from './SequentialVideo';

const Theme = () => {
  const { themeId, classId } = useParams();
  const { t } = useTranslation(['common', 'course']);
  const { common } = useTheme().palette;
  const [open, setOpen] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<ITheme>(initialDto);
  const theme = useTheme();
  const matchesDown1000px = useMediaQuery(theme.breakpoints.down(1000));
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const currentStep = getBreadcrumbs(classId || "").find((x: IBreadcrumb) => x.level === 2)?.tabNum || 1;
  const [hint, setHint] = useState<HTMLElement | null>(null);
  const { courseData } = useContext(CourseContext);
  const durationType = courseData?.durationType;
  const courseStatus = courseData?.status;

  const hintPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };

  const hintPopoverClose = () => {
    setHint(null);
  };
  const openHint = Boolean(hint);

  useEffect(() => {
    if (themeId) {
      serviceCourse.getThemeInfo(themeId).then((res) => {
        if (res.status === 200) setInitialData(res.data);
      })
    }
  }, [themeId]);

  useEffect(() => {
    const updatedCrumbs = [...getBreadcrumbs(classId || "")].filter((x: IBreadcrumb) => x.level !== 4 && x.level !== 3);
    updatedCrumbs.push({
      level: 3,
      page: 'theme',
      label: themeId === "new" ? t('course:theme.ADDING_THEME') : initialData.name,
      path: window.location.pathname,
      tabNum: null
    });
    // Check if the breadcrumbs have actually changed before updating
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs(classId || ""))) {
      if (classId) setBreadcrumbs(classId, updatedCrumbs);
    }
  }, [getBreadcrumbs, setBreadcrumbs, classId, themeId, initialData.name, t]);

  return (
    <Box sx={{ display: 'flex', flexDirection: matchesDown1000px ? 'column' : 'row' }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', mb: 1 }}>
          <Breadcrumbs id={classId} />
        </Box>
        {role === Role.LISTENER ? <SelectTheme /> : null}
        <Box sx={{ backgroundColor: 'white', border: `1px solid ${common.borderSecondary}`, borderRadius: '12px', mt: 2, p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', backgroundColor: '#F1EEF6', borderRadius: '10px' }}>
            <Box sx={{ width: { sm: '50%', xs: '100%' }, display: 'block', ml: '17px', mt: '52px', mb: '33px' }}>
              <Typography sx={{ fontWeight: 500, fontSize: '28px', lineHeight: '36px', mb: '16px' }}>{initialData.name}</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px', color: '#708099', mb: '16px' }}>{initialData.shortDescription}</Typography>
              {durationType === DurationType.INDEFINITE &&
                <Box
                  onMouseEnter={hintPopoverOpen} onMouseLeave={hintPopoverClose}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, width: '120px', backgroundColor: 'rgba(228, 212, 255, 1)', borderRadius: '120px', p: '8px 12px' }}>
                  <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', background: 'black' }} />{initialData.estimatedTime} мин
                </Box>
              }
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none'
                }}
                open={openHint}
                anchorEl={hint}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={hintPopoverClose}
                disableRestoreFocus
                disableScrollLock
              >
                <Box sx={{
                  p: 1,
                  color: common.fontWhite,
                  backgroundColor: '#1A1F36',
                  width: '170px',
                  height: '32px',
                  fontSize: '12px',
                  fontFamily: 'Golos',
                  borderRadius: '8px'
                }}>
                  {t('course:theme.THEME_TESTING_TIME')}
                </Box>
              </Popover>
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '35%' }, display: 'flex', justifyContent: { sm: 'flex-end', xs: 'flex-start' }, mr: '7px', alignItems: 'center' }}>
              <Avatar
                alt="Image"
                src={require("@assets/images/RightImage.png")}
                variant="square"
                sx={{
                  width: { sm: "254px", xs: '200px' },
                  height: { sm: "237px", xs: '180px' },
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </Box>
          </Box>
          {initialData.description &&
            <TextReader htmlContent={initialData.description} />
          }
          {(initialData.videoId && !courseData?.sequentialLearning) &&
            <VideoPlayer videoFileId={initialData.videoId} />
          }
          {(initialData.videoId && courseData?.sequentialLearning) &&
            <SequentialVideo videoFileId={initialData.videoId} />
          }
          {initialData.url &&
            <iframe
              width="100%"
              height="550"
              src={initialData.url}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={initialData.url || 'Theme video title'}
            />
          }
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {durationType === DurationType.INDEFINITE &&
          <Box
            sx={{
              width: matchesDown1000px ? '100%' : '256px',
              height: 'auto',
              borderRadius: '12px',
              backgroundColor: '#fff',
              ml: matchesDown1000px ? 0 : '26px',
              mt: matchesDown1000px ? '20px' : '50px',
              p: '16px 10px',
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <Typography sx={{ textAlign: 'center', fontFamily: 'Golos', fontWeight: 500, fontSize: '24px' }}>Действия по теме</Typography>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: `1px solid ${common.primaryColor}`, borderRadius: '8px', p: '12px', fontSize: '10px', color: common.primaryColor }}>
              <FormControlLabel
                control={<Checkbox sx={{
                  color: common.primaryColor, "&, &.Mui-checked": {
                    color: common.primaryColor,
                  },
                }} />}
                label='Пометить как освоенный'
                labelPlacement="end"
                style={{ fontSize: '4px' }}
              />
            </Box>
          </Box>
        }
        {/* Дополнительные материалы */}
        <MaterialList />

        {/* Задания по теме */}
        <TaskList />
        {/* Проверь свои знания */}
        <SelfTestQuiz />
        {/* Zametka */}
        {role === Role.LISTENER && <FloatingNote open={open} setOpen={setOpen} />}
      </Box>
      {(courseStatus === CourseStatus.SAVED ? currentStep === 5 : Number(currentStep) >= 9) && <PreviewLogout courseId={initialData.courseId} />}
      {role === Role.LISTENER &&
        <Box sx={{ display: 'flex', position: "fixed", bottom: "100px", right: "50px", zIndex: 100000 }}
          onClick={() => setOpen(!open)}>
          <IconButton sx={{ background: "#F3F56D", padding: "15px", color: "#000", ":hover": { background: "#F3F56D" } }}>
            <StickyNote2Outlined fontSize='small' />
          </IconButton>
        </Box>}
    </Box >
  )
}

export default Theme;