import { QuizQuestion } from '@models/quiz-question';
import { Box, Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';

interface IMultipleCorrectAnswersViewProps {
  data: QuizQuestion;
  index: number
}

const MultipleCorrectAnswersView = ({ data, index }: IMultipleCorrectAnswersViewProps) => {
  return (
    <FormControl sx={{ width: "100%" }}>
      {
        data.answers?.map((el, i) => (
          <Box key={i} sx={{ px: '48px', display: 'flex', alignItems: 'center', width: "100%" }}>
            <Grid sx={{ width: "100%" }} alignItems="center" container spacing={2}>
              <Grid item xs={.5}>
                <FormControlLabel
                  control={<Checkbox disableRipple checked={el.correct} sx={{ cursor: 'default' }} />}
                  label=""
                  name={`answers.${index}.correct`}
                />
              </Grid>
              <Grid item xs={10.75}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: el.answer || "",
                  }}
                ></div>
              </Grid>
            </Grid>
          </Box>
        ))
      }
    </FormControl >
  )
}

export default MultipleCorrectAnswersView;