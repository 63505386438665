import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { serviceFile } from "@services/file";

interface ITextEditorProps {
  name: string;
  label?: string;
  shortToolbar?: boolean;
  hideToolbar?: boolean;
  showInline?: boolean;
  minChar?: number;
  maxChar?: number;
  isRequired?: boolean;
  showSymbolCount?: boolean;
  showScrollEditor?: boolean;
  setIsValidSubmitBtn?: (val: boolean) => void;
  readOnly?: boolean;
  bucketName?: string;
}

export const TextEditor = ({
  name,
  label,
  shortToolbar = false,
  hideToolbar = false,
  showInline = false,
  minChar = 100,
  maxChar = 700,
  isRequired = false,
  showSymbolCount = false,
  showScrollEditor = false,
  setIsValidSubmitBtn,
  readOnly = false,
  bucketName = 'id-of-task-or-theme'
}: ITextEditorProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(['common']);
  const formikProps = useFormikContext();
  const [field, meta] = useField(name);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const hasText = editorState.getCurrentContent().hasText();
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);

  useEffect(() => {
    if (toHtml(editorState) === field.value) return;

    if (field.value) {
      setEditorState(
        EditorState.push(
          editorState,
          ContentState.createFromBlockArray(
            htmlToDraft(field.value).contentBlocks
          ),
          "adjust-depth"
        )
      );
    }
  }, [field.value, editorState]);

  function onEditorStateChange(es: EditorState) {
    setEditorState(es);
    formikProps.setFieldValue(name, toHtml(es));
  }

  function toHtml(es: EditorState) {
    return draftToHtml(convertToRaw(es.getCurrentContent()));
  }

  useEffect(() => {
    if (((!isRequired && (editorState.getCurrentContent().getPlainText().length > 0 &&
      editorState.getCurrentContent().getPlainText().length < minChar)) ||
      (isRequired && (editorState.getCurrentContent().getPlainText().length < minChar)) ||
      editorState.getCurrentContent().getPlainText().length > maxChar)) {
      setIsValidSubmitBtn && setIsValidSubmitBtn(false)
    } else {
      setIsValidSubmitBtn && setIsValidSubmitBtn(true)
    }

  }, [editorState, setIsValidSubmitBtn, minChar, maxChar, isRequired]);

  async function _uploadImageCallBack(file: any) {
    const imageList = [...uploadedImages];

    // API call to upload image and get its URL
    const response = await uploadImageToServer(file);
    const { url } = response;

    const imageObject = { file, localSrc: url.data };
    imageList.push(imageObject);
    setUploadedImages(imageList);

    return new Promise((resolve) => {
      resolve({ data: { link: url.data } });
    });
  }

  async function uploadImageToServer(file: File) {
    // Simulated API call, replace with your actual implementation
    const formData = new FormData();
    formData.append("file", file);

    const response: any = await serviceFile.uploadFileMinio(formData, {
      params: { bucketName }
    });
    return { url: response };
  }

  const baseToolbarOptions = {
    options: [
      "inline",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "history",
    ],
    inline: { inDropdown: !showInline },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
  };

  const fullToolbarOptions = {
    ...baseToolbarOptions,
    options: [...baseToolbarOptions.options, "image"],
    image: {
      uploadCallback: _uploadImageCallBack,
      urlEnabled: false,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "150",
        width: "300",
      },
    },
  };

  const toolbarConfig = shortToolbar ? baseToolbarOptions : fullToolbarOptions;

  return (
    <Box>
      {label && (
        <Typography sx={{ mb: "8px" }}>
          {label}
          {isRequired && <span style={{ color: common.errorColor }}>*</span>}
        </Typography>
      )}
      <div style={{ border: "1px solid #E9ECEF", borderRadius: "6px"}}>
        <Editor
          editorState={editorState}
          readOnly={readOnly}
          wrapperClassName={readOnly ? "custom-wrapper-diasbled" : "custom-wrapper"}
          editorClassName={showScrollEditor ? "custom-scroll-editor" : "custom-editor"}
          onEditorStateChange={onEditorStateChange}
          toolbarHidden={hideToolbar || readOnly}
          toolbar={toolbarConfig}
          localization={{
            translations: {
              "generic.add": t('common:actions.ADD'),
              "generic.cancel": t('common:actions.CANCEL'),
              "components.controls.image.image": "Картинка",
              "components.controls.image.fileUpload": t('common:actions.UPLOAD'),
              "components.controls.image.dropFileText": "Перетащите изображение сюда или нажмите здесь",
            },
          }}
        />
      </div>
      {((meta.touched && Boolean(meta.error)) || (isRequired && meta.touched && !hasText)) &&
        <FormHelperText error={Boolean(meta.error) || (isRequired && !hasText)} sx={{ px: 1.75 }}>
          {meta.error || t('common:validation.MANDATORY_FIELD')}
        </FormHelperText>
      }
      {showSymbolCount && (
        <Box display="flex" justifyContent="space-between">
          {((editorState.getCurrentContent().getPlainText().length > 0 &&
            editorState.getCurrentContent().getPlainText().length < minChar) ||
            editorState.getCurrentContent().getPlainText().length > maxChar) && (
              <Typography fontSize={"11px"} color={common.errorColor}>
                {`${t('common:validation.MIN_VALUE_DESCRIPTION')} - ${minChar}, ${t('common:validation.MAX_VALUE_DESCRIPTION')} - ${maxChar}`}
              </Typography>
            )
          }
          <Box
            sx={{
              display: 'flex',
              marginLeft: 'auto'
            }}
          >
            <Typography
              fontSize={"14px"}
              color={
                editorState.getCurrentContent().getPlainText().length < minChar ||
                  editorState.getCurrentContent().getPlainText().length > maxChar
                  ? common.errorColor
                  : "#000000"
              }
            >
              {editorState.getCurrentContent().getPlainText().length || 0}
            </Typography>
            <Typography fontSize={"14px"}>/{maxChar}</Typography>
          </Box>
        </Box>
      )}
    </Box >
  );
};
