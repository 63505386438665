import { useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  AlertColor,
  MenuItem,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from "@mui/material/styles";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { isEmpty } from "lodash";

import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import { serviceCourse } from "@services/course";
import { ICourse, CourseStatus, TypeCode } from "@interfaces/index";
import AvatarPicture from "@components/AvatarPicture";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import CustomAutocomplete from "@ui/Autocomplete";
import { courseDurations, courseFormats } from "@utils/enums";
import UserFullName from "@components/UserFullName";
import LanguageCell from "@components/LanguageCell";
import Message from "@components/Message";
import StudyClassList from "./StudyClassList";
import { CT_ValueState } from ".";
import LoadingSpinner from "@components/LoadingSpinner";

interface Props {
  type: string;
  courseStatus: CT_ValueState;
  courseName: CT_ValueState;
  limitedAccess: boolean
}

interface IFilter {
  status?: string | null;
  name?: string | null;
  author?: boolean;
  formatType?: string | null;
  durationType?: string | null;
  limitedAccess?: boolean;
}

const initialFilter: IFilter = {
  status: null,
  name: null,
  author: false,
  formatType: null,
  durationType: null,
  limitedAccess: false
};

const List = (props: Props) => {
  const { t } = useTranslation([
    "course",
    "user",
    "dictionary",
    "common",
    "enum",
  ]);
  const { common } = useTheme().palette;
  const { type, courseStatus, courseName, limitedAccess } = props;
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [courseList, setCourseList] = useState<Array<ICourse>>([]);
  const [count, setCount] = useState<number>(0);
  const [filter, setFilter] = useState<IFilter>(() => {
    if (
      type === TypeCode.EDU &&
      (!isEmpty(courseStatus.edu) || !isEmpty(courseName.edu))
    )
      return {
        ...initialFilter,
        status: courseStatus.edu,
        name: courseName.edu,
      };
    else if (
      type === TypeCode.FREE &&
      (!isEmpty(courseStatus.free) || !isEmpty(courseName.free))
    )
      return {
        ...initialFilter,
        status: courseStatus.free,
        name: courseName.free,
      };
    return { ...initialFilter, limitedAccess: limitedAccess };
  });
  const { setBreadcrumbs } = useBreadcrumbs();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentCourseId, setCurrentCourseId] = useState<string | null>(null);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const [anchorEls, setAnchorEls] = useState({});

  const handleMenuOpen = (event, rowId) => {
    setAnchorEls((prev) => ({ ...prev, [rowId]: event.currentTarget }));
  };

  const handleMenuClose = (rowId) => {
    setAnchorEls((prev) => ({ ...prev, [rowId]: null }));
  };


  const columns = useMemo<MRT_ColumnDef<ICourse>[]>(() => {
    const updateBreadcrumbs = (cName: string | null, courseId: string | null) => {
      const crumb = {
        level: 1,
        page: "course",
        label: `${cName}`,
        path: `/myCourses/${courseId}`,
        tabNum: null,
      };
      if (courseId) setBreadcrumbs(courseId, [crumb]);
    };

    return [
      {
        accessorKey: "name",
        header: t("course:COURSE_NAME"),
        size: 370,
        minSize: 190,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: common.tableHeaderColor,
            boxShadow: "5px 0 7px -2px rgba(91, 90, 48, 0.1)",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#FFFFFF",
            boxShadow: "12px 0 8px -1px rgba(91, 90, 48, 0.1)",
          },
        },
        Cell: ({ renderedCellValue, row }) => (
          <RouterLink
            style={{ textDecoration: "none" }}
            to={!limitedAccess ? `/myCourses/${row.original.id}` : ""}
            onClick={() => !limitedAccess && updateBreadcrumbs(row.original.name, row.original.id)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {row.original.coverFileId ? (
                <AvatarPicture
                  id={row.original.coverFileId}
                  sxProps={{
                    width: "40px",
                    height: "30px",
                    borderRadius: "2px",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    backgroundColor: row.original.coverColor,
                    width: "40px",
                    height: "30px",
                    borderRadius: "2px",
                    flexShrink: 0,
                  }}
                />
              )}
              <Typography
                sx={{
                  color: common.primaryColor,
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                {renderedCellValue}
              </Typography>
            </Box>
          </RouterLink>
        ),
      },
      {
        accessorKey: "status",
        header: t("user:STATUS"),
        size: 160,
        enableResizing: false,
        Cell: ({ row }) => {
          const course = row.original;
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                background:
                  course.status === CourseStatus.SAVED
                    ? "#E8E8E8"
                    : course.status === CourseStatus.PUBLISHED
                      ? "#EBF6EB"
                      : course.status === CourseStatus.WITHDRAWN
                        ? "#FFF4E4"
                        : "#FFC5D8",
                color:
                  course.status === CourseStatus.SAVED
                    ? "#6B6B6B"
                    : course.status === CourseStatus.PUBLISHED
                      ? "#31AA27"
                      : course.status === CourseStatus.WITHDRAWN
                        ? "#D9B201"
                        : common.errorColor,
                borderRadius: "10px",
                height: "28px",
                width: "fit-content",
                px: 1,
              }}
            >
              {t(`${course.status}`)}
            </Box>
          );
        },
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "authors",
        header: t("common:AUTHOR"),
        minSize: 255,
        enableResizing: false,
        Cell: ({ row }) => {
          const course = row.original;
          return (
            <UserFullName
              id={course?.authors ? course?.authors[0]?.userId : ""}
            />
          );
        },
      },
      {
        accessorKey: "langId",
        header: t("user:LANGUAGE"),
        minSize: 255,
        enableResizing: false,
        Cell: ({ row }) => {
          const { courseSetting } = row.original;
          return <LanguageCell id={courseSetting.langId} />;
        },
      },
      {
        accessorKey: "formatType",
        header: t("course:COURSE_FORMAT"),
        size: 220,
        enableResizing: false,
        Cell: ({ row }) => {
          const { courseSetting } = row.original;
          return <span>{t(`enum:${courseSetting.formatType}`)}</span>;
        },
      },
      {
        accessorKey: "durationType",
        header: t("course:COURSE_DURATION"),
        size: 220,
        enableResizing: false,
        Cell: ({ row }) => {
          const { courseSetting } = row.original;
          return (
            <span>
              {t(`enum:COURSE_DURATION_${courseSetting.durationType}`)}
            </span>
          );
        },
      },
    ];
  }, [t, common, setBreadcrumbs, limitedAccess]);

  useEffect(() => {
    setIsLoading(true);
    if (type) {
      serviceCourse
        .getCourseList(
          {
            pageNumber: pagination.pageIndex,
            size: pagination.pageSize,
            filter,
          },
          type
        )
        .then((res) => {
          if (res.status === 200) {
            setCourseList(res.data.dtoList);
            setCount(res.data.totalElements);
            setIsLoading(false);
          }
        });
    }
  }, [type, filter, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (
      type === TypeCode.EDU &&
      (!isEmpty(courseStatus.edu) || !isEmpty(courseName.edu))
    ) {
      setFilter((prev) => ({
        ...prev,
        status: courseStatus.edu,
        name: courseName.edu,
      }));
    } else if (
      type === TypeCode.FREE &&
      (!isEmpty(courseStatus.free) || !isEmpty(courseName.free))
    ) {
      setFilter((prev) => ({
        ...prev,
        status: courseStatus.free,
        name: courseName.free,
      }));
    } else {
      setFilter((prev) => ({ ...prev, status: null, name: null }));
    }
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [courseStatus, courseName, type]);

  const searchByParams = (paramName: string, value: any) => {
    if (paramName === "author") setFilter({ ...filter, author: value });
    if (paramName === "format") setFilter({ ...filter, formatType: value });
    if (paramName === "duration") setFilter({ ...filter, durationType: value });
  };

  const changeStatus = (courseId: string | null, status: string, rowId: any) => {
    if (courseId) {
      serviceCourse
        .changeCourseStatus(courseId, status)
        .then((res) => {
          if (res.status === 200) {
            setCourseList((prev) =>
              prev.map((course) => {
                if (course.id === courseId) return { ...course, status };
                return course;
              })
            );
            setMessage(t(`course:COURSE_${status}`));
            setMessageType("success");
            setMessageOpen(true);
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            // console.log(err)
            setMessageType("error");
            setMessageOpen(true);
            if (err.response.data.code === "THERE_ARE_NO_PUBLISHED_ITEMS") {
              const params = err.response.data.params;
              const unpublishedItems = Object.values(params);
              const unpublishedItemsString = unpublishedItems.join(", ");
              setMessage(
                t(`course:messages.${err.response.data.code}`, {
                  unpublishedItems: unpublishedItemsString,
                })
              );
            } else {
              setMessage(t(`course:messages.${err.response.data.code}`));
            }
          }
        })
        .finally(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          handleMenuClose(rowId);
          setTimeout(() => {
            setMessageOpen(false);
          }, 3000);
        });
    }
  };

  const deleteCourse = async () => {
    try {
      try {
        const res = await serviceCourse.deleteCourse(currentCourseId || "");
        if (res.status === 200) {
          setCourseList((prev) =>
            prev.filter((course) => course.id !== currentCourseId)
          );
          setCount(count - 1);
          setMessage(t("course:messages.COURSE_DELETED"));
          setMessageType("success");
          setMessageOpen(true);
          setTimeout(() => {
            setMessageOpen(false);
          }, 3000);
        }
      } catch (err) {
        setMessage(t("course:messages.COURSE_DELETE_ERROR"));
        setMessageType("error");
        setMessageOpen(true);
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000);
      }
    } finally {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteCourse,
    modalTitle: t("course:messages.ARE_YOU_SURE_TO_DELETE_COURSE"),
  });

  const table = useMaterialReactTable({
    columns,
    enableSorting: false,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: true,
    enableTopToolbar: false,
    enableColumnPinning: true,
    enableRowActions: limitedAccess ? false : true,
    manualPagination: true,
    data: courseList,
    localization: {
      noRecordsToDisplay: t("dictionary:EMPTY"),
      rowsPerPage: t("enum:LINES_PER_PAGE"),
      actions: "",
      rowActions: t("common:ACTIONS"),
      expand: "",
      collapse: "",
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
      "mrt-row-actions": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
      },
    },
    //optionally override the default column widths
    // defaultColumn: {
    //   size: 30, //default size is usually 180
    // },
    layoutMode: "grid",
    columnResizeMode: "onChange", //default
    initialState: {
      columnPinning: { left: ["mrt-row-expand", "mrt-row-actions", "name"] },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: "grid",
        borderRadius: "6px",
      },
    },
    muiTableContainerProps: {
      sx: {
        "&::-webkit-scrollbar": { height: "4px", width: "7px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D9D9D9",
          borderRadius: "4px",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: { backgroundColor: common.tableHeaderColor },
    },
    muiTableBodyCellProps: {
      sx: { backgroundColor: "#FFFFFF" },
    },
    mrtTheme: (theme) => ({
      draggingBorderColor: theme.palette.common.primaryColor,
    }),
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50, 100],
      shape: "rounded",
      variant: "outlined",
      SelectProps: {
        sx: {
          backgroundColor: "#FFFFFF",
          color: common.primaryColor,
          "& .MuiInputBase-root": {
            "& .MuiSelect-icon": { color: common.primaryColor },
          },
        },
      },
      sx: {
        "& .Mui-selected": {
          background: `${common.primaryColor} !important`,
          color: "#FFFFFF",
        },
      },
    },
    renderDetailPanel: ({ row }) => (
      <StudyClassList courseId={row.original.id} />
    ),
    renderRowActionMenuItems: ({ row }) => {
      const menuItems: any = []

        if(row.original?.status === CourseStatus.SAVED ||
          row.original?.status === CourseStatus.WITHDRAWN) {
            menuItems.push(<MenuItem
              key={0}
              onClick={() => changeStatus(row.original.id, CourseStatus.PUBLISHED, row.id)}
            >
              {t("common:actions.PUBLISH")}
            </MenuItem>,
            <MenuItem
              key={1}
              onClick={() => {
                setCurrentCourseId(row.original.id);
                handleMenuClose(row.id);
                openDeleteConfirmModal(row.original.id || "");
              }}
            >
              {t("common:actions.DELETE")}
          </MenuItem>
          )
        }

        if(row.original?.status === CourseStatus.PUBLISHED) {
          menuItems.push(<MenuItem
            key={2}
            onClick={() => changeStatus(row.original.id, CourseStatus.WITHDRAWN, row.id)}
          >
            {t("common:actions.UNPUBLISH")}
          </MenuItem>)
        }

      if (menuItems.length === 0) {
        return [];
      }
      return menuItems; 
    },
    renderRowActions: ({ row }) => {
      const menuItems = table.options.renderRowActionMenuItems({ row, closeMenu: () => {} });
      return menuItems.length === 0 ? null : (
        <>
          <IconButton onClick={(e) => handleMenuOpen(e, row.id)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEls[row.id]}
            open={Boolean(anchorEls[row.id])}
            onClose={() => handleMenuClose(row.id)}
          >
            {menuItems}
          </Menu>
        </>
      );
    },
    onPaginationChange: setPagination,
    state: { pagination }, //pass the pagination state to the table
    rowCount: count,
    paginationDisplayMode: "pages",
  });

  useEffect(() => {
    table.resetExpanded();
    table.setColumnVisibility({
      authors: type === TypeCode.EDU ? false : true,
      formatType: type === TypeCode.EDU ? false : true,
      durationType: type === TypeCode.EDU ? false : true,
    });
  }, [table, type]);

  return (
    <Box
      sx={{
        display: "grid",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {DeleteModal}
      <Box display="flex" gap={1} alignItems="center">
        <Typography color="#8E8E93">{t("course:FOUND")}:</Typography>{" "}
        <Typography color={common.primaryColor}>{count}</Typography>
        {type === TypeCode.FREE && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.author || false}
                  onChange={(e) => searchByParams("author", e.target.checked)}
                  sx={{
                    color: common.primaryColor,
                    "&, &.Mui-checked": { color: common.primaryColor },
                  }}
                />
              }
              label={t("course:COURSE_AUTHOR_IS_ME")}
              sx={{ ml: "auto" }}
            />
            <CustomAutocomplete
              name="format"
              value={filter.formatType}
              placeholder={t("course:COURSE_FORMAT")}
              options={courseFormats}
              onChange={(name: string, value: any) =>
                searchByParams(name, value)
              }
              sx={{ width: "265px" }}
              customIconDisabled={false}
            />
            <CustomAutocomplete
              name="duration"
              value={filter.durationType}
              placeholder={t("course:COURSE_DURATION")}
              options={courseDurations}
              onChange={(name: string, value: any) =>
                searchByParams(name, value)
              }
              sx={{ width: "265px" }}
              customIconDisabled={false}
            />
          </>
        )}
      </Box>
      {messageOpen && (
        <Message
          type={messageType}
          message={message}
          setMessageOpen={messageOpen}
        />
      )}
      {isLoading ? <LoadingSpinner /> : <MaterialReactTable table={table} />}
    </Box>
  );
};

export default List;
