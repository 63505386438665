import { ReactElement, FC, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Formik, Form as FormikForm } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as types from '@store/account/types';
import CloseIcon from '@mui/icons-material/Close';

import { serviceUser } from "@services/user";

import Message from "@components/Message";
import CustomInput from "@ui/Input";
import CustomButton from "@ui/Button";
import FormikInput from "@ui/formik/Input";
import { DTO, initialDto } from "./models";
import { useTranslation } from "react-i18next";
import {
  emailValidationSchema,
  passwordValidationSchema,
} from "./validation";
import { AlertColor } from "@mui/material";
import CustomModal from "@components/Modal";

interface IPassword {
  oldPass: string | null;
  newPass: string | null;
  confirmPass: string | null;
}

const passwordInitialDto: IPassword = {
  oldPass: null,
  newPass: null,
  confirmPass: null
}

interface IEmailError {
  isTimeExpired: boolean;
  isOldAndNewAreSame: boolean;
  isCodeInvalid: boolean;
  isEmailExist: boolean
}

const emailErrorInitialDto: IEmailError = {
  isTimeExpired: false,
  isOldAndNewAreSame: false,
  isCodeInvalid: false,
  isEmailExist: false
}

const SettingProfile: FC<any> = ({ migrated }: { migrated: boolean }): ReactElement => {
  const { common } = useTheme().palette;
  const [message, setMessage] = useState<string>("");
  const { t } = useTranslation(["profile", "common", "user", "generalSetting"]);
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const [emailMessageOpen, setEmailMessageOpen] = useState<boolean>(false);
  const [passwordMessageOpen, setPasswordMessageOpen] = useState<boolean>(false);
  const [passwordMessageType, setPasswordMessageType] = useState<AlertColor>('success');
  const [initialData, setInitialData] = useState<DTO>(initialDto);
  const dispatch = useDispatch();
  // Email change
  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
  const [readyToConfirm, setReadyToConfirm] = useState<boolean>(false);
  const [confirmId, setConfirmId] = useState<string>("");
  const [emailSubmitErrors, setEmailSubmitErrors] = useState<IEmailError>(emailErrorInitialDto)
  // Password change
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const [passwordInitialData, setPasswordInitialData] = useState<IPassword>(passwordInitialDto);
  const [isOldPasswordInCorrect, setIsOldPasswordInCorrect] = useState<boolean>(false)
  const [passwordVisibility, setPasswordVisibility] = useState<Record<string, boolean>>({
    oldPass: false,
    newPass: false,
    confirmPass: false,
  });

  const togglePasswordVisibility = (fieldName: string) => {
    setPasswordVisibility((prevVisibility) => ({
      ...prevVisibility,
      [fieldName]: !prevVisibility[fieldName],
    }));
  };

  const toggleEmailModal = (status: boolean) => {
    if (!status) {
      setReadyToConfirm(false);
      setConfirmId("");
    }
    setEmailModalOpen(status);
    setEmailSubmitErrors(emailErrorInitialDto)
  }

  const togglePasswordModal = (status: boolean) => {
    setPasswordModalOpen(status);
    setIsOldPasswordInCorrect(false)
  }
  const updateEmail = (email: string) => {
    serviceUser.changeEmail({ email: email })
      .then(res => {
        if (res.status === 200) {
          // console.log(res);
          setEmailSubmitErrors(emailErrorInitialDto)
          setReadyToConfirm(true);
          setConfirmId(res.data.confirmId);
        }
      }).catch((err) => {
        if (err.response.data.code === 'NEW_AND_OLD_EMAILS_ARE_THE_SAME') {
          setEmailSubmitErrors({ ...emailErrorInitialDto, isOldAndNewAreSame: true })
        } else if (err.response.data.code === 'EMAIL_ALREADY_EXISTS') {
          setEmailSubmitErrors({ ...emailErrorInitialDto, isEmailExist: true })
        }
      })
  }

  useEffect(() => {
    setInitialData(profile);
  }, [profile]);

  return (
    <Box
      sx={{
        display: "block",
        width: "100%",
        background: common.fontWhite,
        border: `1px solid ${common.borderPrimary}`,
        borderRadius: 3,
        p: { xl: 3, lg: 3, md: 2.8, sm: 2.6, xs: 2.5 },
        marginTop: "24px"
      }}
    >
      <CardHeader
        title={t('user:PROFILE_SETTINGS')}
        sx={{
          pt: 0,
          pr: 0,
          pl: 0,
          width: "100%",
          borderBottom: `1px solid ${common.strokeSecondary}`,
        }}
        titleTypographyProps={{
          fontWeight: 600,
          fontSize: { xl: 22, lg: 22, md: 20, sm: 18, xs: 16 },
        }}
      />
      {emailMessageOpen && (
        <Message
          type="success"
          message={message}
          setMessageOpen={setEmailMessageOpen}
        />
      )}
      {passwordMessageOpen && (
        <Message
          type={passwordMessageType}
          message={message}
          setMessageOpen={setPasswordMessageOpen}
        />
      )}

      <Box
        sx={{
          display: "block",
          width: "100%",
        }}
      >
        {role === "SUPER_ADMIN" ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Formik
              initialValues={passwordInitialData}
              enableReinitialize
              validationSchema={passwordValidationSchema(t)}
              onSubmit={(values) => {
                const data = {
                  username: initialData.email,
                  oldPass: values.oldPass,
                  newPass: values.newPass,
                  confirmPass: values.confirmPass,
                };
                serviceUser
                  .changePassword(data)
                  .then((res) => {
                    if (res.status === 200) {
                      setPasswordInitialData(values);
                      setMessage(t('common:messages.CHANGES_SAVED'));
                      setPasswordMessageOpen(true);
                      setTimeout(() => {
                        setPasswordMessageOpen(false);
                      }, 5000);
                    }
                  })
                  .catch((error) => {
                    if (error.response.data.message === 'INCORRECT_OLD_PASSWORD') {
                      setMessage(t('common:messages.CURRENT_PASSWORD_ENTERED_INCORRECTLY'));
                      setPasswordMessageOpen(true);
                      setPasswordMessageType('error')
                    }
                    setTimeout(() => {
                      setPasswordMessageOpen(false);
                    }, 5000);
                    // here we need to specify the ERROR and show the message
                  });
              }}
            >
              {({ submitForm, dirty, isValid }) => (
                <FormikForm style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      gap: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "50%",
                        flexDirection: "column",
                        gap: 3,
                      }}
                    >
                      <CustomInput label="E-mail" value={initialData.email || ''} disabled />
                      <FormikInput
                        name="oldPass"
                        label={t('generalSetting:CURRENT_PASSWORD')}
                        type={passwordVisibility.oldPass ? 'text' : 'password'}
                        adornmentIcon={passwordVisibility.oldPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        onAdornmentClick={() => togglePasswordVisibility('oldPass')}
                      />
                      <FormikInput
                        name="newPass"
                        label={t('generalSetting:NEW_PASSWORD')}
                        type={passwordVisibility.newPass ? 'text' : 'password'}
                        adornmentIcon={passwordVisibility.newPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        onAdornmentClick={() => togglePasswordVisibility('newPass')}
                      />
                      <FormikInput
                        name="confirmPass"
                        label={t('generalSetting:NEW_PASSWORD_AGAIN')}
                        type={passwordVisibility.confirmPass ? 'text' : 'password'}
                        adornmentIcon={passwordVisibility.confirmPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        onAdornmentClick={() => togglePasswordVisibility('confirmPass')}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <CustomButton
                        backgroundColor={common.primaryColor}
                        fontColor={common.fontWhite}
                        width="209px"
                        height="40px"
                        borderRadius="8px"
                        disabled={!(isValid && dirty)}
                        onClick={submitForm}
                      >
                        {t("common:actions.SAVE")}
                      </CustomButton>
                    </Box>
                  </Box>
                </FormikForm>
              )}
            </Formik>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: { xl: "65%", lg: "85%", md: "85%", sm: "90%", xs: "95%" },
              gap: { xl: 6, lg: 6, md: 5, sm: 4, xs: 3 }
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: { xs: '100%', md: '80%', lg: '50%' },
                flexDirection: "column",
                gap: 1.25,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: 'row' },
                  width: "100%",
                  gap: { xs: 1.25, md: 2 }
                }}
              >
                <CustomInput label="E-mail" value={initialData.email || ''} disabled />
                <Box sx={{ mt: { md: 4, xs: 2 } }}>
                  <CustomButton
                    backgroundColor={common.secondaryColor}
                    fontColor={common.primaryColor}
                    borderRadius="6px"
                    height="40px"
                    onClick={() => toggleEmailModal(true)}
                    disabled={migrated}
                  >
                    {t("common:actions.CHANGE")}
                  </CustomButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: 'row' },
                  width: "100%",
                  gap: { xs: 1.25, md: 2 },
                }}
              >
                <CustomInput label={t('user:PASSWORD')} type="password" disabled />
                <Box sx={{ mt: { md: 4, xs: 2 } }}>
                  <CustomButton
                    backgroundColor={common.secondaryColor}
                    fontColor={common.primaryColor}
                    borderRadius="6px"
                    height="40px"
                    onClick={() => togglePasswordModal(true)}
                    disabled={migrated}
                  >
                    {t("common:actions.CHANGE")}
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {emailModalOpen && (
          <CustomModal
            open={emailModalOpen}
            handleToggleModal={() => toggleEmailModal(false)}
            showCloseIcon
            width={550}
          >
            <>
              <Formik
                initialValues={{ email: "", confirmCode: "" }}
                validationSchema={emailValidationSchema(t)}
                onSubmit={(values, { resetForm }) => {
                  if (!readyToConfirm) {
                    updateEmail(values.email);
                  } else {
                    serviceUser
                      .confirmEmail({
                        confirmCode: values.confirmCode,
                        confirmId,
                      })
                      .then((res) => {
                        if (res.status === 200) {
                          toggleEmailModal(false);
                          setEmailMessageOpen(true);
                          setMessage(t('common:messages.EMAIL_CONFIRMED'));
                          dispatch({ type: types.account('UPDATE_PROFILE_INFO').SUCCESS, payload: { ...profile, email: values.email } });
                          setTimeout(() => {
                            setEmailMessageOpen(false);
                          }, 5000);
                        }
                      })
                      .catch((error) => {
                        if (error.response.data.message === 'CONFIRMATION_TIME_EXPIRED') {
                          setEmailSubmitErrors(prev => ({ ...prev, isTimeExpired: true, isCodeInvalid: false }))
                        } else {
                          setEmailSubmitErrors(prev => ({ ...prev, isCodeInvalid: true, isTimeExpired: false }))
                        }
                        resetForm({ values: { ...values, confirmCode: '' } })
                      });
                  }
                }}
              >
                {({ values, submitForm, dirty, isValid, resetForm }) => (
                  <FormikForm>
                    <Typography
                      id="modal-modal-title"
                      sx={{
                        height: "36px",
                        fontWeight: "500",
                        fontSize: "28px",
                        lineHeight: "36px",
                        display: "flex",
                        alignItems: "center",
                        mb: "36px",
                      }}
                    >
                      {!readyToConfirm
                        ? t('common:actions.EMAIL_EDITING')
                        : t('common:actions.EMAIL_CONFIRMATION')}
                    </Typography>
                    <CloseIcon
                      sx={{
                        position: "absolute",
                        right: 5,
                        top: 5,
                        color: common.strokePrimary,
                        cursor: "pointer",
                        fontSize: 32
                      }}
                      onClick={() => toggleEmailModal(false)}
                    />
                    {readyToConfirm && (
                      <Box>
                        <Typography
                          id="modal-modal-description"
                          sx={{
                            fontWeight: "500",
                            color: (emailSubmitErrors.isCodeInvalid || emailSubmitErrors.isTimeExpired) ? common.errorColor : common.black
                          }}
                        >
                          {emailSubmitErrors.isCodeInvalid ? t('common:messages.ENTERED_CODE_IS_INCORRECT_CHECK_ENTERED_CODE_OR_PASS_NEW_ONE') :
                            emailSubmitErrors.isTimeExpired ? t('common:messages.TIME_IS_UP_RESEND_CODE') :
                              t('common:messages.CONFIRMATION_CODE_WAS_SENT_TO_EMAIL', {
                                email: values.email
                              })}
                        </Typography>
                      </Box>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 3,
                        mt: 3
                      }}
                    >
                      {!readyToConfirm ? (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                          {emailSubmitErrors.isOldAndNewAreSame &&
                            <Typography
                              sx={{
                                fontWeight: "500",
                                color: common.errorColor
                              }}
                            >{t('common:messages.ENTERED_EMAIL_MATCHES_CURRENT_EMAIL')}
                            </Typography>
                          }
                          {emailSubmitErrors.isEmailExist &&
                            <Typography
                              sx={{
                                fontWeight: "500",
                                color: common.errorColor
                              }}
                            >{t('common:messages.EMAIL_ALREADY_EXISTS')}
                            </Typography>
                          }
                          <FormikInput
                            name="email"
                            label={t('common:actions.ENTER_NEW_EMAIL')}
                            type="email"
                          />
                        </Box>
                      ) : (
                        <FormikInput name="confirmCode" label={t('common:label.ENTER_A_CODE')} />
                      )}

                      {!readyToConfirm ? (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                          }}
                        >
                          <CustomButton
                            backgroundColor={common.btnCancel}
                            fontColor={common.primaryColor}
                            width="209px"
                            height="40px"
                            borderRadius="8px"
                            onClick={() => toggleEmailModal(false)}
                          >
                            {t("common:actions.CANCEL")}
                          </CustomButton>
                          <CustomButton
                            backgroundColor={common.primaryColor}
                            fontColor={common.fontWhite}
                            width="209px"
                            height="40px"
                            borderRadius="8px"
                            disabled={!(isValid && dirty)}
                            onClick={submitForm}
                          >
                            {t("common:actions.SEND")}
                          </CustomButton>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                          }}
                        >
                          <CustomButton
                            backgroundColor={common.btnCancel}
                            fontColor={common.primaryColor}
                            width="209px"
                            height="40px"
                            borderRadius="8px"
                            onClick={() => { updateEmail(values.email); resetForm({ values: { ...values, confirmCode: '' } }) }}
                          >
                            {t("common:actions.SEND_CODE_REPEAT")}
                          </CustomButton>
                          <CustomButton
                            backgroundColor={common.primaryColor}
                            fontColor={common.fontWhite}
                            width="209px"
                            height="40px"
                            borderRadius="8px"
                            disabled={values.confirmCode === ''}
                            onClick={submitForm}
                          >
                            {t("common:actions.CONFIRM")}
                          </CustomButton>
                        </Box>
                      )}
                    </Box>
                  </FormikForm>
                )}
              </Formik>
            </>
          </CustomModal>
        )}
        {passwordModalOpen && (
          <CustomModal
            open={passwordModalOpen}
            handleToggleModal={() => togglePasswordModal(false)}
          >
            <>
              <Typography
                id="modal-modal-title"
                sx={{
                  height: "36px",
                  fontWeight: "500",
                  fontSize: "28px",
                  lineHeight: "36px",
                  display: "flex",
                  alignItems: "center",
                  mb: "36px",
                }}
              >
                {t('common:actions.CHANGING_PASSWORD')}
              </Typography>
              <Formik
                initialValues={passwordInitialData}
                enableReinitialize
                validationSchema={passwordValidationSchema(t)}
                onSubmit={(values) => {
                  const data = {
                    username: initialData.email,
                    oldPass: values.oldPass,
                    newPass: values.newPass,
                    confirmPass: values.confirmPass,
                  };
                  serviceUser
                    .changePassword(data)
                    .then((res) => {
                      if (res.status === 200) {
                        setMessage(t('common:messages.CHANGES_SAVED'));
                        togglePasswordModal(false);
                        setPasswordMessageOpen(true);
                        setTimeout(() => {
                          setPasswordMessageOpen(false);
                        }, 5000);
                      }
                    })
                    .catch((error) => {
                      if (error.response.data.message === 'INCORRECT_OLD_PASSWORD') {
                        setIsOldPasswordInCorrect(true)
                      }
                      // here we need to specify the ERROR and show the message
                    });
                }}
              >
                {({ submitForm, dirty, isValid }) => (
                  <FormikForm>
                    <Box>
                      {isOldPasswordInCorrect && <Typography sx={{
                        height: "48px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "24px",
                        color: common.errorColor
                      }}>{t('common:messages.CURRENT_PASSWORD_ENTERED_INCORRECTLY')}</Typography>}
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 3,
                        mb: 3
                      }}>
                        <FormikInput
                          name="oldPass"
                          label={t('generalSetting:CURRENT_PASSWORD')}
                          type={passwordVisibility.oldPass ? 'text' : 'password'}
                          adornmentIcon={passwordVisibility.oldPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          onAdornmentClick={() => togglePasswordVisibility('oldPass')}
                        />
                        <FormikInput
                          name="newPass"
                          label={t('generalSetting:NEW_PASSWORD')}
                          hintText={
                            `Пароль должен содержать хотя бы:\n
                            Одну заглавную букву (A–Z).\n
                            Одну строчную букву (a–z).\n
                            Одну цифру (0–9).`
                          }
                          type={passwordVisibility.newPass ? 'text' : 'password'}
                          adornmentIcon={passwordVisibility.newPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          onAdornmentClick={() => togglePasswordVisibility('newPass')}
                        />
                        <FormikInput
                          name="confirmPass"
                          label={t('generalSetting:NEW_PASSWORD_AGAIN')}
                          type={passwordVisibility.confirmPass ? 'text' : 'password'}
                          adornmentIcon={passwordVisibility.confirmPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          onAdornmentClick={() => togglePasswordVisibility('confirmPass')}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                        }}
                      >
                        <CustomButton
                          backgroundColor={common.btnCancel}
                          fontColor={common.primaryColor}
                          width="209px"
                          height="40px"
                          borderRadius="8px"
                          onClick={() => togglePasswordModal(false)}
                        >
                          {t("common:actions.CANCEL")}
                        </CustomButton>
                        <CustomButton
                          backgroundColor={common.primaryColor}
                          fontColor={common.fontWhite}
                          width="209px"
                          height="40px"
                          borderRadius="8px"
                          disabled={!(isValid && dirty)}
                          onClick={submitForm}
                        >
                          {t("common:actions.CONFIRM")}
                        </CustomButton>
                      </Box>
                    </Box>
                  </FormikForm>
                )}
              </Formik>
            </>
          </CustomModal>
        )}
      </Box>
    </Box>
  );
};

export default SettingProfile;
