import { useState, useEffect, MouseEvent, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Box, Fade, InputAdornment, Menu, MenuItem, TextField, Typography, useTheme, useMediaQuery } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import SearchIcon from "@mui/icons-material/Search";
import { DropResult } from 'react-beautiful-dnd';
import _, { isEmpty } from "lodash";

import { serviceCourse } from "@services/course";
import { serviceTask } from "@services/task";
import { downloadFile } from "@utils/functions";
import CustomButton from "@ui/Button";
import CreateUpdateSection from "./Section";
import DragDropContent from "./DragDropContent";
import { CourseAdditionalResource, CourseAdditionalResourceStatusEnum } from "@models/course-additional-resource";
import { AuthorRole, ContentType, CourseStatus, IContent, TaskFormat, TaskType } from "@interfaces/index";
import { contentInitialDto } from "./models";
import AddTask from "./Task/AddTask";
import AddResource from "./AdditionalResource/AddEditResource";
import List from "./AdditionalResource/List";
import { resourceInitialDto } from "./AdditionalResource/models";
import { CourseContext } from "@context/CourseContext";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import { SyllabusContent } from "./StudyPlan/SyllabusContent";

const Structure = ({ contentList, setContentList, setMessage, setMessageOpen, setMessageType }) => {
  const { courseId } = useParams();
  const { t } = useTranslation(['common', "course"]);
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const [search, setSearch] = useState<string>("");
  const [taskCreationAvailable, setTaskCreationAvailable] = useState<boolean>(false);
  const [sectionId, setSectionId] = useState<string>("new");
  const [themeId, setThemeId] = useState<string>("new");
  const { courseData } = useContext(CourseContext);
  const isAuthor = courseData?.authors?.some((author) => author.userId === courseData?.userId);
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)
  const isPublished = courseData?.status === CourseStatus.PUBLISHED;
  const isClosed = courseData?.status === CourseStatus.CLOSED;
  const [itemType, setItemType] = useState<string | undefined>(undefined);
  const [resourceList, setResourceList] = useState<Array<CourseAdditionalResource>>([]);
  const [currentContent, setCurrentContent] = useState<IContent>(contentInitialDto);
  const [currentResource, setCurrentResource] = useState<CourseAdditionalResource>(resourceInitialDto);

  const [anchorContentActionMenu, setAnchorContentActionMenu] = useState<HTMLElement | null>(null);
  const openContentActionMenu = Boolean(anchorContentActionMenu);

  const [anchorResourceActionMenu, setAnchorResourceActionMenu] = useState<HTMLElement | null>(null);
  const openResourceActionMenu = Boolean(anchorResourceActionMenu);

  const theme = useTheme();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const [openSectionModal, setOpenSectionModal] = useState<boolean>(false);
  const handleToggleSectionModal = (modalState: boolean) => {
    if (!modalState) setCurrentContent(contentInitialDto);
    setAnchorContentActionMenu(null);
    setOpenSectionModal(modalState);
  }

  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
  const handleToggleAddTaskModal = (modalState: boolean) => {
    setAnchorContentActionMenu(null);
    if (modalState) {
      if (currentContent.type === ContentType.SECTION) setSectionId(currentContent.id || "new");
      if (currentContent.type === ContentType.THEME) setThemeId(currentContent.id || "new");
    } else {
      setSectionId("new");
      setThemeId("new");
    }
    setOpenAddTaskModal(modalState);
  }

  const [openAdditionalResourcesModal, setOpenAdditioanlResourcesModal] = useState<boolean>(false);
  const handleToggleAdditionalResourcesModal = (modalState: boolean) => {
    setAnchorContentActionMenu(null);
    setAnchorResourceActionMenu(null);
    setOpenAdditioanlResourcesModal(modalState);
  }



  const { contentListWithResourсes, updatedResourсeList } = useMemo(() => {
    const contentListWithResourсes = _.cloneDeep(contentList)
    const updatedResourсeList: CourseAdditionalResource[] = []

    resourceList.forEach((resourse) => {
      if (!resourse.sectionId?.length) {
        updatedResourсeList.push(resourse)
      } else {
        const requiredSection = contentListWithResourсes.find(
          (item) => item.type === ContentType.SECTION && item.id === resourse.sectionId
        )
        if (requiredSection) {
          Array.isArray(requiredSection.additionalResources) ?
            requiredSection.additionalResources.push(resourse) :
            requiredSection.additionalResources = [resourse]
        } else {
          updatedResourсeList.push(resourse)
        }
      }
    })

    return { contentListWithResourсes, updatedResourсeList }
  }, [resourceList, contentList]);

  const updateResourceList = (resource: CourseAdditionalResource | any, updated: boolean) => {
    if (updated) {
      const index = [...resourceList].findIndex(
        (x) => x.id === resource.id
      );

      setResourceList([
        ...resourceList.slice(0, index),
        resource,
        ...resourceList.slice(index + 1),
      ]);
    } else if (!updated) {
      setResourceList([...resourceList, resource]);
    }
    setOpenAdditioanlResourcesModal(false);
    setCurrentResource(resourceInitialDto);
  }

  const handleToggleDeleteModal = () => {
    if (currentResource.fileId) setItemType("FILE");
    else {
      if (currentContent.id) setItemType(currentContent.type || "ITEM");
      else setItemType(undefined);
    }
    setAnchorContentActionMenu(null);
    setAnchorResourceActionMenu(null);
    if (currentContent.id) {
      openDeleteConfirmModal(currentContent.id)
    } else if (currentResource.id) {
      openDeleteConfirmModal(currentResource.id)
    }
  }

  const handleToggleContentActionMenu = (event: MouseEvent<HTMLElement> | null, currentContent?: IContent) => {
    if (event) event.stopPropagation();
    if (!isEmpty(currentContent)) {
      setCurrentContent(currentContent);
      setCurrentResource(resourceInitialDto);
    }
    setAnchorContentActionMenu(event ? event.currentTarget : null);
  };

  const handleToggleResourceActionMenu = (event: MouseEvent<HTMLElement> | null, resource?: CourseAdditionalResource) => {
    if (event) event.stopPropagation();
    if (!isEmpty(resource)) {
      setCurrentResource(resource);
      setCurrentContent(contentInitialDto);
    }
    setAnchorResourceActionMenu(event ? event.currentTarget : null);
  };

  const removeById = (list: Array<IContent>) => {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === currentContent.id) {
        list.splice(i, 1);
        return true; // object found and deleted
      } else if (list[i].children) {
        if (removeById(list[i].children)) {
          return true; // object found and deleted in a child node
        }
      }
    }
    return false; // object not found
  }

  const findByIdChangeStatus = (list: Array<IContent>, status: string) => (
    list.map((content: IContent) => {
      if (content.id === currentContent.id) {
        return {
          ...content,
          status
        }
      } else if (content.children) {
        return {
          ...content,
          children: findByIdChangeStatus(content.children, status)
        }
      } else {
        return content
      }
    })
  );

  const updateStatus = () => {
    if (currentContent.type === ContentType.THEME) {
      serviceCourse.changeThemeStatus(currentContent.id || '', courseId || '').then(res => {
        if (res.status === 200) {
          setContentList(findByIdChangeStatus([...contentList], res.data));
          setAnchorContentActionMenu(null);
        }
      });
    }
    else if (currentContent.type === ContentType.TASK) {
      serviceTask.changeTaskStatus(currentContent.id || "").then(res => {
        if (res.status === 200) {
          setContentList(findByIdChangeStatus([...contentList], res.data));
          setAnchorContentActionMenu(null);
        }
      }).catch(e => {
        if (e?.response?.data?.code?.includes('NUMBER_OF_QUESTIONS_MIN')) {
          setMessage(t('course:messages.TO_PUBLISH_TEST_YOU_NEED_ADD_AT_LEAST_3_QUESTIONS'))
          setMessageType('error')
          setMessageOpen(true)
          setTimeout(() => {
            setMessageOpen(false)
          }, 5000)
        }
      });
    } else {
      serviceCourse.changeResourceStatus(currentResource.id || '', courseId || '').then(res => {
        if (res.status === 200) {
          const index = [...resourceList].findIndex(
            (x) => x.id === currentResource.id
          );
          setResourceList([
            ...resourceList.slice(0, index),
            res.data,
            ...resourceList.slice(index + 1),
          ]);

          setAnchorResourceActionMenu(null);
        }
      });
    }
  }

  useEffect(() => {
    if (courseData?.enabledMidAndFinalExam && courseId) {
      serviceCourse.getCoursePlanInfo(courseId).then((res) => {
        if (res.status === 200) {
          setContentList(res.data)
        }
      })
    } else if (courseId) {
      serviceCourse.getContentList({ search }, courseId).then((res) => {
        if (res.status === 200) setContentList(res.data);
      });
    }
  }, [courseId, search, setContentList, courseData?.enabledMidAndFinalExam]);

  useEffect(() => {
    if (courseId) {
      serviceTask.getTaskCreationAvailability(courseId).then(res => {
        if (res.status === 200) {
          setTaskCreationAvailable(res.data);
        }
      });

      serviceCourse.getResourceList(courseId).then((res) => {
        if (res.status === 200) setResourceList(res.data);
      });
    }
  }, [courseId]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;

    if (destination.index !== source.index) {
      let items = Array.from(contentList);
      if (!isEmpty(currentContent.parentId)) {
        items = contentList.find((x: IContent) => x.id === currentContent.parentId)?.children || [];
      }
      const [newOrder] = items.splice(source.index, 1);
      items.splice(destination.index, 0, newOrder);
      // TODO: here we need to update list after success, maybe later we need to show a message
      if (isEmpty(currentContent.parentId)) {
        setContentList(items);
      }
      const afterIndex = destination.index === items.length - 1 ? destination.index + 1 : destination.index;
      serviceCourse.updateContentOrder({ selectedItemId: draggableId, afterIndex }).then((res) => {
        if (res.status === 200) {
        }
      })
    }
  }

  const menuItemHoverStyle = {
    backgroundColor: common.primaryColor,
    color: common.fontWhite
  };

  const deleteItem = () => {
    if (itemType === ContentType.SECTION) {
      return serviceCourse.deleteSection(currentContent.id || '', courseId || '').then(res => {
        if (res.status === 200) {
          const list = [...contentList];
          removeById(list);
          setContentList(list);
        }
      });
    }
    else if (itemType === ContentType.THEME) {
      return serviceCourse.deleteTheme(currentContent.id || '', courseId || '').then(res => {
        if (res.status === 200) {
          const list = [...contentList];
          removeById(list);
          setContentList(list);
        }
      });
    }
    else if (itemType === ContentType.TASK) {
      return serviceTask.deleteTask(currentContent.id || "").then(res => {
        if (res.status === 200) {
          const list = [...contentList];
          removeById(list);
          setContentList(list);
        }
      });
    } else {
      return serviceCourse.deleteResource(currentResource.id || '', courseId || '').then(res => {
        if (res.status === 200) {
          setResourceList(
            [...resourceList].filter(x => x.id !== currentResource.id)
          );
        }
      });
    }
  };

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteItem,
    modalTitle: itemType === ContentType.SECTION ? t('course:DELETE_SECTION_CONFIRMATION_TEXT')
      : itemType === ContentType.THEME ? t(`course:DELETE_THEME_CONFIRMATION_TEXT`)
        : itemType === ContentType.TASK ? t(`course:DELETE_TASK_CONFIRMATION_TEXT`)
          : currentResource.fileId ? t(`common:DELETE_FILE_CONFIRMATION_TEXT`)
            : t(`common:DELETE_ITEM_CONFIRMATION_TEXT`),
  })
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3, mb: '20px' }}>
      {DeleteModal}
      <Box sx={{ mb: 3, display: 'flex', flexDirection: { md: 'row', xs: 'column' }, gap: 1, justifyContent: 'space-between' }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          color="primary"
          sx={{
            width: { md: "75%", xs: '100%' }, mr: 3, justifyContent: "flex-start", '& label.Mui-focused': { color: common.primaryColor },
            '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': { borderColor: common.primaryColor },
              '&.Mui-focused fieldset': { borderColor: common.primaryColor }
            }
          }}
          placeholder={t("common:placeholders.SEARCH_BY_NAME")}
          name="search"
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{ backgroundColor: common.primaryColor, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', mr: '-14px', borderRadius: '6px' }}>
                  <SearchIcon sx={{ color: '#FFFFFF', width: '26px', height: '26px' }} />
                </Box>
              </InputAdornment>
            ),
          }}
        />
        <CustomButton
          backgroundColor={common.primaryColor}
          fontColor={common.fontWhite}
          width={`${matchesDownMd ? '100%' : '20%'}`}
          borderRadius="6px"
          disabled={isClosed || !(isAuthor || isCoAuthor)}
          onClick={(event) => handleToggleContentActionMenu(event, contentInitialDto)}
        >
          {t('common:actions.ADD')} <KeyboardArrowDownIcon />
        </CustomButton>
      </Box>
      {
        (courseData?.enabledMidAndFinalExam && contentListWithResourсes?.length > 0) ?
          <Box>
            <SyllabusContent
              contentListWithResourсes={contentListWithResourсes}
              onDragEnd={onDragEnd}
              handleToggleContentActionMenu={handleToggleContentActionMenu}
              courseStatus={courseData?.status}
              setCurrentContent={setCurrentContent}
              handleToggleResourceActionMenu={handleToggleResourceActionMenu} />
            {updatedResourсeList?.length > 0 &&
              <List
                resourceList={updatedResourсeList}
                handleToggleActionMenu={handleToggleResourceActionMenu}
              />}
          </Box>
          :
          contentListWithResourсes?.length > 0 || updatedResourсeList?.length > 0 ? (
            <>
              {contentListWithResourсes?.length > 0 &&
                <DragDropContent
                  list={contentListWithResourсes}
                  type={ContentType.COURSE}
                  onDragEnd={onDragEnd}
                  handleToggleActionMenu={handleToggleContentActionMenu}
                  setContent={setCurrentContent}
                  courseStatus={courseData?.status}
                  handleToggleResourceActionMenu={handleToggleResourceActionMenu}
                />
              }
              {updatedResourсeList?.length > 0 &&
                <List
                  resourceList={updatedResourсeList}
                  handleToggleActionMenu={handleToggleResourceActionMenu}
                />
              }
            </>
          ) : (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  alt="Content-default-bg-img"
                  src={require(`@assets/images/emptyDataBg.png`)}
                  variant="square"
                  sx={{
                    height: "auto",
                    width: "50%",
                    mb: "8px",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{
                  fontWeight: 400,
                  fontSize: { sm: '16px', xs: '14px' },
                  lineHeight: '20px',
                  mt: '32px',
                  mb: '169px'
                }}>
                  {t("course:content.YOUR_CONTENT_HERE")}
                </Typography>
              </Box>
            </Box>
          )}
      {openContentActionMenu &&
        <Menu
          MenuListProps={{ "aria-labelledby": "fade-button" }}
          TransitionComponent={Fade}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          anchorEl={anchorContentActionMenu}
          open={openContentActionMenu}
          onClose={() => handleToggleContentActionMenu(null)}
        >
          {(isEmpty(currentContent.type) || currentContent.type === ContentType.CURRENT_CONTROL_1
            || currentContent.type === ContentType.CURRENT_CONTROL_2) && !isPublished &&
            <MenuItem onClick={() => handleToggleSectionModal(true)} sx={{ "&:hover": menuItemHoverStyle }}>
              {t("course:content.ADD_SECTION")}
            </MenuItem>
          }
          {(isEmpty(currentContent.type) || currentContent.type === ContentType.SECTION
            || currentContent.type === ContentType.CURRENT_CONTROL_1
            || currentContent.type === ContentType.CURRENT_CONTROL_2) && !isPublished &&
            <MenuItem onClick={() => navigate(`/myCourses/${courseId}/${isEmpty(currentContent.type) ? 'new' : currentContent.id}/theme/new`)} sx={{ "&:hover": menuItemHoverStyle }}>
              {t("course:content.ADD_THEME")}
            </MenuItem>
          }
          {currentContent.type !== ContentType.TASK && currentContent.type !== ContentType.MIDTERM_1 && currentContent.type !== ContentType.MIDTERM_2 && !isPublished &&
            <MenuItem onClick={() => handleToggleAddTaskModal(true)} sx={{ "&:hover": menuItemHoverStyle }} disabled={!taskCreationAvailable}>
              {t("course:content.ADD_TASK")}
            </MenuItem>
          }
          {(isEmpty(currentContent.type) || currentContent.type === ContentType.SECTION
            || currentContent.type === ContentType.CURRENT_CONTROL_1
            || currentContent.type === ContentType.CURRENT_CONTROL_2) &&
            <MenuItem onClick={() => handleToggleAdditionalResourcesModal(true)} sx={{ "&:hover": menuItemHoverStyle }}>
              {t("course:content.ADD_ADDITIONAL_RESOURSES")}
            </MenuItem>
          }
          {currentContent.type === ContentType.SECTION &&
            <MenuItem
              // disabled={disabledByRole} 
              onClick={() => handleToggleSectionModal(true)}
              sx={{ "&:hover": menuItemHoverStyle }}>
              {t('common:actions.EDIT')}
            </MenuItem>
          }
          {(currentContent.type === ContentType.THEME || currentContent.type === ContentType.TASK
            || currentContent.type === ContentType.MIDTERM_1 || currentContent.type === ContentType.MIDTERM_2) &&
            <MenuItem
              sx={{ "&:hover": menuItemHoverStyle }}
              onClick={updateStatus}
            // disabled={disabledByRole}
            >
              {currentContent.status === CourseStatus.SAVED ? t('common:actions.PUBLISH') : t('common:actions.UNPUBLISH')}
            </MenuItem>
          }
          {!isEmpty(currentContent.type) && currentContent.children.length <= 0 &&
            ((currentContent.type !== ContentType.SECTION && currentContent.status === CourseStatus.SAVED) ||
              currentContent.type === ContentType.SECTION) &&
            <MenuItem
              // disabled={disabledByRole}
              onClick={() => handleToggleDeleteModal()}
              sx={{ "&:hover": menuItemHoverStyle }}>
              {t('common:actions.DELETE')}
            </MenuItem>
          }
        </Menu>
      }
      {openResourceActionMenu &&
        <Menu
          MenuListProps={{ "aria-labelledby": "fade-button" }}
          TransitionComponent={Fade}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          anchorEl={anchorResourceActionMenu}
          open={openResourceActionMenu}
          onClose={() => handleToggleResourceActionMenu(null)}
        >
          <MenuItem sx={{ "&:hover": menuItemHoverStyle }} onClick={updateStatus} > {/*disabled={disabledByRole}*/}
            {currentResource.status === CourseAdditionalResourceStatusEnum.SAVED ? t('common:actions.PUBLISH') : t('common:actions.UNPUBLISH')}
          </MenuItem>
          {currentResource?.fileId &&
            <MenuItem sx={{ "&:hover": menuItemHoverStyle }} onClick={() => downloadFile(currentResource.fileId)}>
              {t('common:actions.DOWNLOAD')}
            </MenuItem>
          }
          {
            currentResource.status === CourseAdditionalResourceStatusEnum.SAVED &&
            <MenuItem sx={{ "&:hover": menuItemHoverStyle }} onClick={() => { handleToggleAdditionalResourcesModal(true); }}>{/*disabled={disabledByRole}*/}
              {t('common:actions.EDIT')}
            </MenuItem>
          }
          <MenuItem sx={{ "&:hover": menuItemHoverStyle }} onClick={() => handleToggleDeleteModal()} >{/*disabled={disabledByRole}*/}
            {t('common:actions.DELETE')}
          </MenuItem>
        </Menu>
      }
      {openSectionModal &&
        <CreateUpdateSection
          handleToggleSectionModal={handleToggleSectionModal}
          modalOpen={openSectionModal}
          courseId={courseId || ""}
          sectionId={currentContent.type === ContentType.SECTION && currentContent.id ? currentContent.id : ""}
          contentList={contentList}
          setContentList={setContentList}
        />
      }
      {openAddTaskModal &&
        <AddTask
          openModal={openAddTaskModal}
          handleToggleModal={handleToggleAddTaskModal}
          sectionId={sectionId}
          themeId={themeId}
          courseStatus={courseData?.status}
        />
      }
      {openAdditionalResourcesModal &&
        <AddResource
          courseId={courseId || ""}
          sectionId={currentContent.id || ""}
          openModal={openAdditionalResourcesModal}
          handleToggleModal={handleToggleAdditionalResourcesModal}
          resource={currentResource}
          updateResourceList={updateResourceList}
        />
      }
    </Box>
  )
}

export default Structure
