import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StateSchema } from "@store/StateSchema";
import { serviceAntiPlagiarism } from "@services/antiPlagiarism";
import { serviceTask } from "@services/task";
import { CourseContext } from "@context/CourseContext";
import { LinearProgressWithLabel } from "@components/LinearProgressWithLabel";
import { IProfile, ITask, Role } from "@interfaces/index";
import { Box, CircularProgress } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ErrorIcon from "@mui/icons-material/Error";
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

interface IAntiPlagiarsmCheckResultsProps {
    userId: string;
    fileId?: string;
}

const AntiPlagiarsmCheckResults = ({userId, fileId = ""}: IAntiPlagiarsmCheckResultsProps) => {
    const profile: IProfile = useSelector(
        (store: StateSchema) => store.account.profileInfo
    );
    const { role } = profile.authorityInfo;
    const {taskId, progressId} = useParams()
    const { courseData } = useContext(CourseContext);
    const { common } = useTheme().palette;
    const { t } = useTranslation(["enum", "antiPlagiarism", "course"]);
    const [openResults, setOpenResult] = useState<boolean>(true)
    const [taskInfo, setTaskInfo] = useState<ITask>()
    const queryClient = useQueryClient()

    useEffect(() => {
        if(taskId) {
            serviceTask.getTaskInfo(taskId).then(res => {
                if(res.status === 200) setTaskInfo(res.data)
            })
        } else if (progressId) {
            serviceTask.getTaskProgressInfo(progressId).then(res => {
                if(res.status === 200) setTaskInfo(res.data?.task)
            })
        }
    }, [taskId, progressId])

    const {data: taskResultAntiPlagiarism, isFetching: isPlagiarismResultsFetching} = useQuery({
        queryKey: ['getTaskResultAntiPlagiarism', courseData?.courseId, userId, taskInfo?.id, fileId],
        queryFn: () => serviceAntiPlagiarism.getUserTaskResultAntiPlagiarism(courseData?.courseId, userId, taskInfo?.id, fileId),
        enabled: !!courseData?.courseId && !!userId && !!taskInfo?.id,
        retry: 3,
        select: res => res.data
    })

    const progressBarStyles = {
        background: common.tableHeaderColor,
        height: '10px',
        borderRadius: '6px',
        '& .MuiLinearProgress-bar': {
          height: '10px',
          borderRadius: '6px',
        },
    }


    if (!taskResultAntiPlagiarism?.length && role === Role.LISTENER && taskInfo?.enabledAntiPlagiarism) return (
        <Box 
            sx={{
                mt: 2, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between', 
                borderRadius: '4px', 
                border: `1px solid ${common.strokeSecondary}`, 
                padding: '10px',
                borderLeft: '6px solid #EBD9FA'
            }}
        >
            <Box 
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "28px",
                    height: "28px",
                    borderRadius: "50%",
                    backgroundColor: common.primaryColor,
                    padding: "10px",
                    mr: 2
                }}>
                    <ErrorIcon sx={{ color: "white", width: "20px" }}/>
                </Box>
                
                <Box>
                    {
                        isPlagiarismResultsFetching ? <CircularProgress size={24}/> : t('antiPlagiarism:YOUR_WORK_IS_CHECKED_FOR_PLAGIARISM')
                    }
                </Box>
            </Box>

            <SyncRoundedIcon 
                onClick={() => queryClient.invalidateQueries({queryKey: ['getTaskResultAntiPlagiarism']})} 
                sx={{color: common.primaryColor, cursor: 'pointer'}}
            />
        </Box>
    )
    
    if(!taskResultAntiPlagiarism?.length || !taskInfo?.enabledAntiPlagiarism) return null

    return (
        <Box sx={{
            mt: 2,
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2
            }}>
                <Box 
                    onClick={() => setOpenResult(prev => !prev)}
                    sx={{
                    backgroundColor: '#E9E9E9', 
                    borderRadius: '6px', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    mr: 1,
                    cursor: 'pointer'
                    }}
                >
                    <ExpandLessIcon sx={{rotate: openResults ? '0deg' : '180deg', transition: 'rotate .2s ease-in-out'}} />
                </Box>
                {t('antiPlagiarism:PLAGIARISM_CHECK_RESULTS')}: {t(`enum:${taskResultAntiPlagiarism[0]?.checkStatus}`)} 
                <a style={{marginLeft: '5px', height: '24px'}} href={taskResultAntiPlagiarism[0]?.downloadUrl}>
                    <FileDownloadOutlinedIcon sx={{ width: '24px', color: common.primaryColor }}/>
                </a>
            </Box>

            { openResults && 
                <>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Box mb={1}>{t('antiPlagiarism:CHARACTERS_IN_TEXT')}: { taskResultAntiPlagiarism[0]?.stats?.textSize }</Box>
                        <Box mb={1}>{t('antiPlagiarism:NUMBER_OF_SENTENCES')}: { taskResultAntiPlagiarism[0]?.stats?.sentenceCount }</Box>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', mt: 1}}>
                        <Box sx={{ mr: 1, width: '25%' }}>
                            <Box>{t('antiPlagiarism:CITATIONS')}</Box>
                            <LinearProgressWithLabel 
                                sx={{...progressBarStyles, '& .MuiLinearProgress-bar': {backgroundColor: '#D9B201'}}}
                                value={taskResultAntiPlagiarism[0]?.score?.citation} 
                            />
                        </Box>

                        <Box sx={{ mr: 1, width: '25%' }}>
                            <Box>{t('antiPlagiarism:ORIGINALITY')}</Box>
                            <LinearProgressWithLabel 
                                sx={{...progressBarStyles, '& .MuiLinearProgress-bar': {backgroundColor: '#31AA27'}}}
                                value={taskResultAntiPlagiarism[0]?.score?.originality}
                             />
                        </Box>

                        <Box sx={{ mr: 1, width: '25%' }}>
                            <Box>{t('antiPlagiarism:SELF_CITATIONS')}</Box>
                            <LinearProgressWithLabel 
                                sx={{...progressBarStyles, '& .MuiLinearProgress-bar': {backgroundColor: '#6B6B6B'}}}
                                value={taskResultAntiPlagiarism[0]?.score?.selfCitation} 
                            />
                        </Box>

                        <Box sx={{ width: '25%' }}>
                            <Box>{t('antiPlagiarism:COINCIDENCES')}</Box>
                            <LinearProgressWithLabel 
                                sx={{...progressBarStyles, '& .MuiLinearProgress-bar': {backgroundColor: '#FD0053'}}}
                                value={taskResultAntiPlagiarism[0]?.score?.similarity} 
                            />
                        </Box>
                    </Box>
                </>
            }
        </Box>
    )
}

export default AntiPlagiarsmCheckResults