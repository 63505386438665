import { Course } from "@interfaces/course";
import API from "./api";


export class InfoCourseControllerApi extends API{
    private readonly url ='/course/info'

    getCourseInfo(id: string):Promise<Course> {
        return this.get(`${this.url}/${id}`)
    }
}

