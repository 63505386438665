import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { SxProps, useTheme } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { get, capitalize, unionBy } from 'lodash';
import { InputAdornment } from '@mui/material';
import { formatDateWithHyphen } from '@utils/functions';

interface Props {
  label?: string;
  name: string;
  value?: any;
  options?: Array<any>;
  isRequired?: boolean;
  multiple?: boolean;
  sx?: SxProps
  valueKey?: string;
  placeholder?: string;
  valueAsObject?: boolean;
  disabled?: boolean;
  disabledOptions?: Array<number | string>;
  optionDisableKey?: string;
  clearable?: boolean;
  onChange?: any;
  onChangeParams?: any;
  selectAll?: boolean;
  inputBgColor?: string;
  defaultValue?: any;
  icon?: any;
  customIconDisabled?: boolean;
  maxSelectedItems?: number;
  isInputEnterable?: boolean;
  limitTags?: number;
}

const CustomAutocomplete: React.FC<Props> = ({
  label,
  name,
  value,
  options = [],
  isRequired = false,
  multiple = false,
  sx = {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  valueKey = "id",
  placeholder,
  valueAsObject = false,
  disabled = false,
  disabledOptions = [],
  optionDisableKey = "id",
  clearable = true,
  onChange,
  onChangeParams,
  selectAll = false,
  inputBgColor,
  defaultValue = null,
  icon,
  customIconDisabled = true,
  maxSelectedItems = 9999999999999,
  isInputEnterable = false,
  limitTags = 10,
  ...rest
}) => {
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const { t } = useTranslation(['common', 'enum']);
  const { common } = useTheme().palette;
  const [currentValue, setCurrentValue] = useState<string | null | Array<string | number>>(multiple ? [] : null);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (multiple && Array.isArray(currentValue)) {
      if (valueAsObject) {
        setSelectedOptions(currentValue);
      } else {
        let arrayOfObjects: Array<any> = [];
        currentValue?.forEach((fieldValue: string | number) => {
          if (options.some((obj: any) => obj[valueKey] === fieldValue)) {
            arrayOfObjects.push(options.find((obj: any) => obj[valueKey] === fieldValue))
          }
        });
        setSelectedOptions(arrayOfObjects);
      }
    }
  }, [multiple, valueAsObject, options, valueKey, currentValue]);

  const getOptions = () => {
    if (selectAll && options.length > 1) {
      const selectAllOption = { id: 'selectAll', text: 'ALL' };
      const mergedOptions = unionBy(selectedOptions, options, valueKey);
      return [selectAllOption, ...mergedOptions];
    } else {
      return unionBy(options, selectedOptions, valueKey);
    }
  };

  const handleSelectChange = (value: any) => {
    let currValue = currentValue;
    if (valueAsObject) {
      if (multiple) {
        if (selectAll && value.some((v: any) => v.id === 'selectAll')) {
          currValue = options.map((option: any) => option);
        } else {
          currValue = value;
        }
      } else {
        currValue = value ? value : { id: null };
      }
    } else {
      if (multiple) {
        if (selectAll && value.some((v: any) => v.id === 'selectAll')) {
          if (options.length === currentValue?.length) currValue = [];
          else currValue = options.map((option: any) => option[valueKey]);
        } else {
          currValue = value.map((option: any) => option[valueKey]);
        }
      } else {
        currValue = value ? value[valueKey] : null;
      }
    }
    setCurrentValue(currValue);
    if (onChange) onChange(name, currValue);
  };

  const getValue = () => {
    if (valueAsObject) {
      if (multiple) {
        return currentValue ? currentValue : [];
      } else {
        if (currentValue && options.length > 0) {
          return getOptions().find((option: any) => option[valueKey] === currentValue[valueKey]);
        }
        return null;
      }
    } else {
      if (multiple) {
        return currentValue
          ? getOptions().filter((option: any) => currentValue.includes(option[valueKey]))
          : [];
      } else {
        return getOptions().find((option: any) => option[valueKey] === currentValue) || null;
      }
    }
  };

  return (
    <Box sx={sx}>
      {label &&
        <Typography sx={{ display: 'flex' }}>
          {label} {isRequired && <span style={{ color: common.errorColor }}>*</span>}
        </Typography>
      }
      <Autocomplete
        size='small'
        multiple={multiple}
        options={getOptions()}
        disabled={disabled}
        disableCloseOnSelect={multiple}
        disableClearable={!clearable}
        disabledItemsFocusable
        freeSolo={!multiple && customIconDisabled}
        value={getValue()}
        limitTags={limitTags}
        onChange={(_, value) => handleSelectChange(value)}
        getOptionDisabled={(option) => {
          return (
            disabledOptions.includes(option[optionDisableKey]) ||
            (selectedOptions.length >= maxSelectedItems && !selectedOptions.includes(option)) ||
            (option?.id === 'selectAll' && maxSelectedItems < options.length)
          );
        }}
        getOptionLabel={(option) => {
          return (
            (option?.text && t(`enum:${option.text}`)) ||
            (option?.userFullName && option.userFullName) ||
            (option?.fullName && option.fullName) ||
            (option?.name && option.name) ||
            (option?.date && formatDateWithHyphen(option?.date)) ||
            get(option, `name${capitalize(lang)}`) || ''
          )
        }}
        isOptionEqualToValue={(option, value) => { return value && option[valueKey] === value[valueKey]; }}
        ListboxProps={{
          sx: {
            maxHeight: 200,
            '&::-webkit-scrollbar': { width: '7px' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: '#E5E0EB', borderRadius: '6px' }
          }
        }}
        renderOption={(props: any, option: any, { selected }: any) => {
          return (
            <li {...props} key={option.id}>
              {multiple &&
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: "8px", color: common.primaryColor }}
                  checked={selected || (options.length === currentValue?.length)}
                />
              }
              {
                ((option?.text && t(`enum:${option.text}`)) ||
                  (option?.userFullName && option.userFullName) ||
                  (option?.fullName && option.fullName) ||
                  (option?.name && option.name) ||
                  (option?.date && formatDateWithHyphen(option?.date)) ||
                  get(option, `name${capitalize(lang)}`))
              }
            </li>
          )
        }}
        renderTags={(value, getTagProps) => {
          const numTags = value.length;

          return (
            <>
              {value.slice(0, limitTags).map((option, index) => (
                <Chip
                  label={
                    (option?.text && t(`enum:${option.text}`)) ||
                    (option?.userFullName && option.userFullName) ||
                    (option?.fullName && option.fullName) ||
                    (option?.name && option.name) ||
                    get(option, `name${capitalize(lang)}`)
                  }
                  {...getTagProps({ index })}
                  disabled={disabledOptions.includes(option[optionDisableKey]) || disabled}
                  sx={{
                    m: '0 !important',
                    height: '28px'
                  }}
                />
              ))}

              {numTags > limitTags && ` +${numTags - limitTags}`}
            </>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: inputBgColor ? inputBgColor : "#FFFFFF"
              },
              '& .MuiInputBase-root.Mui-disabled': {
                backgroundColor: disabled ? common.inputDisabledBg : "transparent"
              },
              '& label.Mui-focused': { color: inputBgColor ? inputBgColor : common.primaryColor },
              '& .MuiInput-underline:after': { borderBottomColor: inputBgColor ? inputBgColor : common.primaryColor },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: inputBgColor ? inputBgColor : "#AFA3C4",
                },
                '&:hover fieldset': { borderColor: inputBgColor ? inputBgColor : common.primaryColor },
                '&.Mui-focused fieldset': { borderColor: inputBgColor ? inputBgColor : common.primaryColor }
              }
            }}
            InputProps={icon ? {
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  {icon}
                </InputAdornment>
              ),
            } : { ...params.InputProps }}
            inputProps={{ ...params.inputProps, readOnly: !isInputEnterable }}
            placeholder={placeholder ?? t('placeholders.CHOOSE_ITEM')}
          />
        )}
        {...rest}
      />
    </Box>
  );
};

export default CustomAutocomplete;