import React from 'react'
import { ContentType, Role, TaskType } from '@interfaces/index';
import { Accordion, AccordionSummary, Box, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { useTranslation } from 'react-i18next';
import { StudyPlanList } from './StudyPlanList';

export const StudyPlan = ({ contentList }) => {
  const { common } = useTheme().palette;
  const midterm2 = contentList?.find((element) => element?.type === TaskType.MIDTERM_2)?.children[0]
  const midterm1 = contentList?.find((element) => element?.type === TaskType.MIDTERM_1)?.children[0]
  const finalExam = contentList?.find((element) => element?.type === TaskType.FINALEXAM)?.children[0]
  const navigate = useNavigate();
  const { classId } = useParams();
  const { t } = useTranslation(["enum", "course", "common"]);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 2
      }}
    >
      <Accordion
        expanded={true}
        disableGutters={true}
        sx={{
          backgroundColor: '#F2F2F2',
          border: `1px solid ${common.borderSecondary}`,
          '& .MuiPaper-root': {
            borderRadius: 0,
          }
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          sx={{
            display: 'flex',
            justifyContent: "space-between"
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "18px"
                }}>{t("enum:CURRENT_CONTROL_1")}</Typography>
              </Box>

            </Box>
          </Box>
        </AccordionSummary>
        {contentList?.find((element) => element.type === ContentType.CURRENT_CONTROL_1)?.children.length > 0 &&
          <Box m="0px 32px 32px 32px">
            <StudyPlanList contentList={contentList?.find((element) => element.type === ContentType.CURRENT_CONTROL_1)?.children} />
          </Box>
        }
      </Accordion>
      {contentList?.length > 0 && contentList?.filter((element) => element?.type === TaskType.MIDTERM_1)?.length > 0 &&
        <Accordion
          disableGutters={true}
          sx={{
            backgroundColor: common.borderSecondary,
            border: `1px solid #CACACA`,
            '& .MuiPaper-root': {
              borderRadius: 0,
            }
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            sx={{
              display: 'flex',
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={1.5}>
                  <TaskOutlinedIcon />
                  <Typography sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "18px"
                  }}
                    onClick={() => navigate(
                      `/myCourses/current/${classId}/tasks/${midterm1.id}`
                    )}>
                    {midterm1?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>
      }
      <Accordion
        expanded={true}
        disableGutters={true}
        sx={{
          backgroundColor: '#F2F2F2',
          border: `1px solid ${common.borderSecondary}`,
          '& .MuiPaper-root': {
            borderRadius: 0,
          }
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          sx={{
            display: 'flex',
            justifyContent: "space-between"
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "18px"
                }}>{t("enum:CURRENT_CONTROL_2")}</Typography>
              </Box>

            </Box>
          </Box>
        </AccordionSummary>
        {contentList?.find((element) => element.type === ContentType.CURRENT_CONTROL_2)?.children.length > 0 &&
          <Box m="0px 32px 32px 32px">
            <StudyPlanList contentList={contentList?.find((element) => element.type === ContentType.CURRENT_CONTROL_2)?.children} />
          </Box>
        }
      </Accordion>
      {contentList?.length > 0 && contentList?.filter((element) => element?.type === TaskType.MIDTERM_2)?.length > 0 &&
        <Accordion
          disableGutters={true}
          sx={{
            backgroundColor: common.borderSecondary,
            border: `1px solid #CACACA`,
            '& .MuiPaper-root': {
              borderRadius: 0,
            }
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            sx={{
              display: 'flex',
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={1.5}>
                  <TaskOutlinedIcon />
                  <Typography sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "18px"
                  }}
                    onClick={() => navigate(
                      `/myCourses/current/${classId}/tasks/${midterm2.id}`
                    )}>
                    {midterm2?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>
      }
      {contentList?.length > 0 && contentList?.filter((element) => element?.type === TaskType.FINALEXAM)?.length > 0 &&
        <Accordion
          disableGutters={true}
          sx={{
            backgroundColor: common.borderSecondary,
            border: `1px solid #CACACA`,
            '& .MuiPaper-root': {
              borderRadius: 0,
            }
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            sx={{
              display: 'flex',
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={1.5}>
                  <FlagOutlinedIcon />
                  <Typography sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "18px"
                  }}
                    onClick={() => navigate(
                      `/myCourses/current/${classId}/tasks/${finalExam.id}`
                    )}>
                    {finalExam?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>
      }
    </Box>
  )
}
