import React, { MouseEvent, useContext } from 'react'
import DragDropContent from '../DragDropContent'
import { AuthorRole, ContentType, CourseStatus, TaskType } from '@interfaces/index'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Accordion, AccordionSummary, Box, Typography, useTheme } from '@mui/material'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CourseContext } from "@context/CourseContext";

interface Props {
  contentListWithResourсes: any;
  onDragEnd: any;
  courseStatus: string;
  handleToggleContentActionMenu: any;
  handleToggleResourceActionMenu: any;
  setCurrentContent?: any;
}

export const SyllabusContent = ({ contentListWithResourсes, onDragEnd, handleToggleContentActionMenu, courseStatus, handleToggleResourceActionMenu, setCurrentContent }: Props) => {
  const { common } = useTheme().palette;
  const { courseId } = useParams();
  const { t } = useTranslation(['common', "course", "enum"]);
  const navigate = useNavigate();
  const { courseData } = useContext(CourseContext);
  const midterm2 = contentListWithResourсes?.find((element) => element?.type === TaskType.MIDTERM_2)?.children[0]
  const midterm1 = contentListWithResourсes?.find((element) => element?.type === TaskType.MIDTERM_1)?.children[0]
  const finalExam = contentListWithResourсes?.find((element) => element?.type === TaskType.FINALEXAM)?.children[0]
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)
  const isActionDisabled = courseStatus === CourseStatus.PUBLISHED || courseStatus === CourseStatus.CLOSED || !(isAuthor || isCoAuthor);

  const goToTaskPage = (event: MouseEvent<HTMLElement> | null, parentType: string | null, parentId: string | null, taskId: string | null, taskFormat: string | null) => {
    if (event) event.stopPropagation();
    const sectionId = parentType === ContentType.SECTION ? parentId : "new";
    const themeId = parentType === ContentType.THEME ? parentId : "new";
    navigate(`/myCourses/${courseId}/tasks/${taskId}/${taskFormat}/${sectionId}/${themeId}`);
  }
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2
      }}
    >
      <Accordion
        expanded={true}
        disableGutters={true}
        sx={{
          backgroundColor: '#F2F2F2',
          border: `1px solid ${common.borderSecondary}`,
          '& .MuiPaper-root': {
            borderRadius: 0,
          }
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          sx={{
            display: 'flex',
            justifyContent: "space-between"
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "18px"
                }}>{t("enum:CURRENT_CONTROL_1")}</Typography>
              </Box>
              <Box
                onClick={(event) => handleToggleContentActionMenu(event, contentListWithResourсes?.find((element) => element.type === ContentType.CURRENT_CONTROL_1))}
              >
                <MoreVertIcon
                  sx={{ color: `${common.primaryColor}`, cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        {contentListWithResourсes?.find((element) => element.type === ContentType.CURRENT_CONTROL_1)?.children.length > 0 &&
          <Box m="0px 32px 32px 32px">
            <DragDropContent
              list={contentListWithResourсes?.find((element) => element.type === ContentType.CURRENT_CONTROL_1)?.children}
              type={ContentType.COURSE}
              onDragEnd={onDragEnd}
              handleToggleActionMenu={handleToggleContentActionMenu}
              setContent={setCurrentContent}
              courseStatus={courseStatus}
              handleToggleResourceActionMenu={handleToggleResourceActionMenu}
            />
          </Box>
        }
      </Accordion>
      {contentListWithResourсes?.length > 0 && contentListWithResourсes?.filter((element) => element?.type === TaskType.MIDTERM_1)?.length > 0 &&
        <Accordion
          disableGutters={true}
          sx={{
            backgroundColor: '#F2F2F2',
            border: `1px solid ${common.borderSecondary}`,
            '& .MuiPaper-root': {
              borderRadius: 0,
            }
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            sx={{
              display: 'flex',
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={1.5}>
                  <TaskOutlinedIcon />
                  <Typography sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "18px"
                  }}
                    onClick={(event) => goToTaskPage(event, midterm1?.parentType, midterm1?.parentId, midterm1?.id, midterm1?.format)}>
                    {midterm1?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{ marginLeft: 'auto', display: "flex", gap: 0.5 }}
                >
                  <Box>
                    {midterm1?.status === CourseStatus.SAVED &&
                      <RemoveCircleOutlineIcon sx={{ cursor: "pointer" }} />
                    }
                    {midterm1?.status !== CourseStatus.SAVED &&
                      <CheckCircleIcon sx={{ color: `${common.primaryColor}`, cursor: "pointer" }} />
                    }
                  </Box>
                  <Box
                    onClick={isActionDisabled ? undefined :
                      (event) => handleToggleContentActionMenu(event, midterm1)
                    }
                  >
                    <MoreVertIcon
                      sx={{ color: isActionDisabled ? '#0000001F' : `${common.primaryColor}`, cursor: 'pointer' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>
      }
      <Accordion
        expanded={true}
        disableGutters={true}
        sx={{
          backgroundColor: '#F2F2F2',
          border: `1px solid ${common.borderSecondary}`,
          '& .MuiPaper-root': {
            borderRadius: 0,
          }
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          sx={{
            display: 'flex',
            justifyContent: "space-between"
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "18px"
                }}>{t("enum:CURRENT_CONTROL_2")}</Typography>
              </Box>
              <Box
                onClick={(event) => handleToggleContentActionMenu(event, contentListWithResourсes?.find((element) => element.type === ContentType.CURRENT_CONTROL_1))}
              >
                <MoreVertIcon
                  sx={{ color: `${common.primaryColor}`, cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        {contentListWithResourсes?.find((element) => element.type === ContentType.CURRENT_CONTROL_2)?.children.length > 0 &&
          <Box m="0px 32px 32px 32px">
            <DragDropContent
              list={contentListWithResourсes?.find((element) => element.type === ContentType.CURRENT_CONTROL_2)?.children}
              type={ContentType.COURSE}
              onDragEnd={onDragEnd}
              handleToggleActionMenu={handleToggleContentActionMenu}
              setContent={setCurrentContent}
              courseStatus={courseStatus}
              handleToggleResourceActionMenu={handleToggleResourceActionMenu}
            />
          </Box>}
      </Accordion>
      {contentListWithResourсes?.length > 0 && contentListWithResourсes?.filter((element) => element?.type === TaskType.MIDTERM_2)?.length > 0 &&
        <Accordion
          disableGutters={true}
          sx={{
            backgroundColor: '#F2F2F2',
            border: `1px solid ${common.borderSecondary}`,
            '& .MuiPaper-root': {
              borderRadius: 0,
            }
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            sx={{
              display: 'flex',
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={1.5}>
                  <TaskOutlinedIcon />
                  <Typography sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "18px"
                  }} onClick={(event) => goToTaskPage(event, midterm2?.parentType, midterm2?.parentId, midterm2?.id, midterm2?.format)}>
                    {
                      midterm2?.name
                    }
                  </Typography>
                </Box>
                <Box
                  sx={{ marginLeft: 'auto', display: "flex", gap: 0.5 }}
                >
                  <Box>
                    {midterm2?.status === CourseStatus.SAVED &&
                      <RemoveCircleOutlineIcon sx={{ cursor: "pointer" }} />
                    }
                    {midterm2?.status !== CourseStatus.SAVED &&
                      <CheckCircleIcon sx={{ color: `${common.primaryColor}`, cursor: "pointer" }} />
                    }
                  </Box>
                  <Box
                    onClick={isActionDisabled ? undefined :
                      (event) => handleToggleContentActionMenu(event, midterm2)
                    }
                  >
                    <MoreVertIcon
                      sx={{ color: isActionDisabled ? '#0000001F' : `${common.primaryColor}`, cursor: 'pointer' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>
      }
      {contentListWithResourсes?.length > 0 && contentListWithResourсes?.filter((element) => element?.type === TaskType.FINALEXAM)?.length > 0 &&
        <Accordion
          disableGutters={true}
          sx={{
            backgroundColor: '#F2F2F2',
            border: `1px solid ${common.borderSecondary}`,
            '& .MuiPaper-root': {
              borderRadius: 0,
            }
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            sx={{
              display: 'flex',
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={1.5}>
                  <FlagOutlinedIcon />
                  <Typography sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "18px"
                  }}
                    onClick={(event) => goToTaskPage(event, finalExam?.parentType, finalExam?.parentId, finalExam?.id, finalExam?.format)}>
                    {finalExam?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{ marginLeft: 'auto', display: "flex", gap: 0.5 }}
                >
                  <Box>
                    {finalExam?.status === CourseStatus.SAVED &&
                      <RemoveCircleOutlineIcon sx={{ cursor: "pointer" }} />
                    }
                    {finalExam?.status !== CourseStatus.SAVED &&
                      <CheckCircleIcon sx={{ color: `${common.primaryColor}`, cursor: "pointer" }} />
                    }
                  </Box>
                  <Box
                    onClick={isActionDisabled ? undefined :
                      (event) => handleToggleContentActionMenu(event, finalExam)
                    }
                  >
                    <MoreVertIcon
                      sx={{ color: isActionDisabled ? '#0000001F' : `${common.primaryColor}`, cursor: 'pointer' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>
      }
    </Box>
  )
}
