import { ISection } from "@interfaces/index";

export const initialDto: ISection = {
  id: null,
  courseId: null,
  index: null,
  name: "",
  shortDescription: "",
  themes: [],
  curentControl: "CURRENT_CONTROL_1",
};
