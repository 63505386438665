import { Box, Typography, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import ClearIcon from "@mui/icons-material/Clear";
interface Props {
  text: string;
  clearIconVisible?: boolean;
  clickHandler?: () => void;
  helperText?: string[];
}
const UnhiddenHints = ({
  text,
  clearIconVisible = false,
  clickHandler,
  helperText,
}: Props) => {
  const { common } = useTheme().palette;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        p: "10px",
        backgroundColor: "#fff",
        borderRadius: "12px",
        alignItems: "center",
        gap: 2,
        border: `1px solid ${common.borderSecondary}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          backgroundColor: common.primaryColor,
          padding: "10px",
        }}
      >
        <ErrorIcon sx={{ color: "white", width: "20px" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {helperText &&
            helperText.length > 0 &&
            helperText.map((item, index) => (
              <Typography
                key={`${item}_${index}`}
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: common.primaryColor,
                }}
              >
                {item}
              </Typography>
            ))}
        </Box>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: common.primaryColor,
          }}
        >
          {text}
        </Typography>
        {clearIconVisible && (
          <ClearIcon
            sx={{ color: "gray", cursor: "pointer" }}
            onClick={clickHandler}
          />
        )}
      </Box>
    </Box>
  );
};

export default UnhiddenHints;
